import { PartialDeep } from 'type-fest'
import { createTheme, Theme } from '@material-ui/core/styles'
import { useMemo } from 'react'
import defaultsDeep from 'lodash/defaultsDeep'
import { red } from '@material-ui/core/colors'
import { APP_THEME_OPTIONS } from '../../../theme'
import { ContainerProps } from '@material-ui/core'

const READONLY_OVERRIDES = {
  overrides: {
    MuiTextField: { root: { pointerEvents: 'none' } },
    MuiSelect: { root: { pointerEvents: 'none' } },
    MuiButton: { root: { pointerEvents: 'none' } },
    MuiInput: { root: { pointerEvents: 'none' } },
    MuiCheckbox: { root: { pointerEvents: 'none' } },
    MuiAutocomplete: { root: { pointerEvents: 'none' } },
    MuiLink: { root: { pointerEvents: 'none' } },
    MuiFormControlLabel: { root: { pointerEvents: 'none' } },
  },
} as PartialDeep<Theme>

export interface FormTheme {
  primaryColor?: string
  secondaryColor?: string
  textColor?: string
  backgroundColor?: string
  dark?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  rtl?: boolean
  maxWidth?: ContainerProps['maxWidth']
  noPaper?: boolean
}

export const DEFAULT_FORM_THEME = (theme: Theme): FormTheme => ({
  backgroundColor: theme.palette.background.paper,
  dark: false,
  primaryColor: theme.palette.primary.main,
  secondaryColor: theme.palette.secondary.main,
  textColor: theme.palette.text.primary,
  variant: 'standard',
  rtl: false,
})

export function useAppTheme(themeProp: FormTheme | undefined, isReadOnly: boolean) {
  const themeString = JSON.stringify(themeProp)
  return useMemo(
    () => (baseTheme: Theme) => {
      const formTheme = { ...DEFAULT_FORM_THEME(baseTheme), ...themeProp }
      const readOnlyOverride = !isReadOnly ? {} : READONLY_OVERRIDES

      const direction = formTheme.rtl ? 'rtl' : 'ltr'
      const options = defaultsDeep(
        {
          direction,
          palette: {
            type: formTheme.dark ? 'dark' : 'light',
            primary: {
              main: formTheme.primaryColor,
            },
            secondary: {
              main: formTheme.secondaryColor,
            },
            text: {
              primary: formTheme.textColor,
            },
            background: {
              paper: formTheme.backgroundColor,
            },
            error: {
              main: red.A400,
            },
          },
          overrides: {
            MuiTypography: {
              h6: {
                'form &': {
                  marginTop: '28px',
                },
              },
            },
            MuiFormControl: {
              root: {},
            },
          },
        } as PartialDeep<Theme>,
        readOnlyOverride,
        APP_THEME_OPTIONS(formTheme.variant)
      )
      return createTheme(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [themeString, isReadOnly, themeProp]
  )
}
