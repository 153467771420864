import { RegisterLayout } from '../formeditor/components/RegisterLayout'
import { LoginForm } from './LoginForm'
import React from 'react'
import { useConferenceUserData } from '../formeditor/components/CurrentUserLabel'
import { Alert } from '@material-ui/lab'

export const UserAuthError = ({ initialError }: { initialError?: string }) => {
  const userData = useConferenceUserData()

  return (
    <RegisterLayout>
      {userData?.conferenceUser ? (
        <Alert severity={'error'}>{initialError || 'Error'}</Alert>
      ) : (
        <LoginForm initialError={initialError} />
      )}
    </RegisterLayout>
  )
}
