import { FormProductShowIf } from '../../../data/interfaces/FormProductShowIf'
import isObject from 'lodash/isObject'

export const checkShowIfs = (values: Record<string, any>, showIfs?: FormProductShowIf[]) => {
  return !showIfs?.length || showIfs.every((v) => checkShowIf(values, v))
}

export const checkShowIf = (values: Record<string, any>, showIf?: FormProductShowIf) => {
  if (!showIf?.values) return true
  const fieldValue = values[showIf.field]

  return isObject(fieldValue)
    ? Object.entries(fieldValue)
        .filter(([, v]) => v)
        .some(([otherV]) => showIf.values.includes(otherV))
    : showIf.values.includes(fieldValue)
}
