import { FieldProps, useField } from 'react-final-form'
import { Value } from '@material-ui/lab/useAutocomplete'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete'
import {
  AutocompleteProps as MuiAutocompleteProps,
  default as MuiAutocomplete,
} from '@material-ui/lab/Autocomplete/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Box, Chip, ChipProps } from '@material-ui/core'
import toString from 'lodash/toString'
import { useTextValidators } from '../formeditor/components/useValidate'
import { matchSorter } from 'match-sorter'
import { UseAutocompleteProps as MuiUseAutocompleteProps } from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { ShowErrorFunc, showErrorOnChange } from '../formeditor/MuiRffUtil'
import { CommonDndContext } from './CommonDndContext'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DragOverlay } from '@dnd-kit/core'
import { prefixWrap } from '../formeditor/components/prefixWrap'
import { TextFieldProps } from '../formeditor/components/form/TextField'

function isSingleLine(ref: React.MutableRefObject<HTMLDivElement | null>) {
  if (ref.current) {
    let y: number | null = null
    for (const child of ref.current.children) {
      const childY = child.getBoundingClientRect().y
      if (!y) {
        y = childY
      }
      if (childY > y) {
        return false
      }
    }
  }
  return true
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  fieldProps,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const {
    input: { name, onChange, value },
    meta,
  } = useField(props.name, {
    ...fieldProps,
    validate: useTextValidators({
      fieldProps,
      required: props.required,
      inputLanguage: props.textFieldProps?.inputLanguage
    }),
  })

  const {
    options,
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    getOptionLabel,
    showError = showErrorOnChange,
    placeholder,
    onChange: onChangeCallback,
    size,
    ...rest
  } = props

  function getValue(values: any) {
    if (!getOptionValue) {
      return values
    }

    return multiple ? (values ? values.map(getOptionValue) : null) : values ? getOptionValue(values) : null
  }

  const getLabel = getOptionLabel || ((v) => toString(v))

  const { helperText, ...lessRest } = rest
  const { variant, ...restTextFieldProps } = textFieldProps || {}

  let defaultValue: Value<T, Multiple, DisableClearable, FreeSolo> | undefined

  if (!getOptionValue) {
    defaultValue = value as Value<T, Multiple, DisableClearable, FreeSolo> | undefined
  } else if (value) {
    options.forEach((option) => {
      const optionValue = getOptionValue(option)
      if (multiple) {
        if (!defaultValue) {
          defaultValue = [] as any
        }
        ;((value as any) || []).forEach((v: any) => {
          if (v === optionValue) {
            ;(defaultValue as any).push(option)
          }
        })
      } else {
        if (value === optionValue) {
          defaultValue = option as any
        }
      }
    })
    if (!defaultValue && props.freeSolo) {
      defaultValue = value
    }
  }

  const onChangeFunc = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    const gotValue = getValue(value)
    onChange(gotValue)

    if (onChangeCallback) {
      onChangeCallback(event, gotValue, reason, details)
    }
  }

  const { error, submitError } = meta
  const isError = showError({ meta })
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      const newValue = Array.from(value || [])

      if (over && active && active.id !== over.id) {
        onChange(arrayMove(newValue, +active.id, +over.id))
      }
    },
    [onChange, value]
  )
  // const onDragEnd = useCallback(
  //   (result) => {
  //     if (!result.destination) {
  //       return
  //     }
  //
  //     const newValue = Array.from(value || [])
  //     if (!newValue[result.source.index] || !newValue[result.destination.index]) {
  //       return
  //     }
  //
  //     const [removed] = newValue.splice(result.source.index, 1)
  //     newValue.splice(result.destination.index, 0, removed)
  //     onChange(newValue)
  //   },
  //   [value, onChange]
  // )

  return (
    <MuiAutocomplete
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      getOptionLabel={getLabel}
      value={defaultValue ?? (multiple ? [] : null as any)}
      renderTags={(tags, getTagProps) => (
        <RenderTags
          tags={tags}
          getTagProps={getTagProps}
          onDragEnd={onDragEnd}
          size={size}
          getLabel={getLabel}
        />
      )}
      renderInput={(params) => (
        <TextField
          label={label}
          required={required}
          helperText={isError ? error || submitError : helperText}
          error={isError}
          name={name}
          placeholder={placeholder}
          variant={variant}
          // onChange={(e) => onChangeFunc(e, e.target.value, 'blur')}
          {...params}
          {...restTextFieldProps}
          inputProps={{
            ...params.inputProps,
            onBlur: (e) => {
              ;(params.inputProps as any).onBlur?.(e)
              // Add option on blur
              const newValue = e.target.value
              if (!newValue?.trim()) return

              if (multiple && Array.isArray(defaultValue) && !(defaultValue as any)?.includes(newValue)) {
                onChangeFunc(e, [...((defaultValue as any) || []), newValue] as any, 'blur')
              } else {
                // onChangeFunc(e, e.target.value as any, 'blur')
              }
            },
          }}
          fullWidth={true}
        />
      )}
      filterOptions={(options, { inputValue }) => {
        return matchSorter(options, inputValue, {
          keys: getOptionLabel ? [getOptionLabel as any, getOptionValue as any].filter((v) => v) : undefined,
        })
      }}
      {...lessRest}
    />
  )
}

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
      MuiUseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  name: string
  label?: ReactNode
  helperText?: ReactNode
  required?: boolean
  getOptionValue?: (option: T) => any
  options: T[]
  fieldProps?: Partial<FieldProps<any, any>>
  textFieldProps?: Partial<TextFieldProps>
  showError?: ShowErrorFunc
}

export type AutocompleteData = {
  [key: string]: any | null
}

export const SortableChip = ({ id, ...props }: { id: string | number } & ChipProps) => {
  const { attributes, listeners, setNodeRef } = useSortable({ id })
  return <Chip {...attributes} {...listeners} {...props} ref={setNodeRef} />
}

const RenderTags = <T extends any>({
  tags,
  getTagProps,
  onDragEnd,
  size,
  getLabel,
}: {
  tags: T[]
  onDragEnd: (event: DragEndEvent) => void
  getTagProps: AutocompleteGetTagProps
  size?: 'small' | 'medium'
  getLabel: (v: T) => string
}) => {
  const [singleLine, setSingleLine] = useState(true)
  const [activeId, setActiveId] = useState<null | string>(null)
  const ref = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (singleLine && !isSingleLine(ref)) {
      setSingleLine(false)
    }
  })
  const [orderable, setOrderable] = useState(tags)
  useEffect(() => setOrderable(tags), [tags])
  return (
    <Box
      {...{ ref }}
      display={'flex'}
      flexWrap={'wrap'}
      overflow={'hidden'}
      flexDirection={singleLine ? 'row' : 'column'}
      width={singleLine ? undefined : '100%'}
      alignItems={'flex-start'}
    >
      <CommonDndContext
        onDragEnd={(e) => {
          setActiveId(null)
          onDragEnd(e)
        }}
        onDragStart={(event) => setActiveId(event.active.id?.toString())}
        onDragOver={(event: DragEndEvent) => {
          const { active, over } = event

          if (over && active && active.id !== over.id) {
            setOrderable((orderable) => arrayMove(orderable, +active.id, +over.id))
          }
        }}
      >
        {/*TODO remove any*/}
        <SortableContext items={orderable as any} strategy={verticalListSortingStrategy}>
          {orderable.map((tag, index) => {
            const label = getLabel(tag)
            return (
              <SortableChip
                key={index}
                id={'' + index}
                {...getTagProps({ index })}
                label={label}
                size={size}
              />
            )
          })}
        </SortableContext>

        <DragOverlay dropAnimation={null}>
          {/* TODO this only works on string id */}
          {activeId ? <Chip label={getLabel(orderable[+activeId])} /> : null}
        </DragOverlay>
      </CommonDndContext>
    </Box>
  )
}
export const AppAutocomplete: typeof Autocomplete = prefixWrap(Autocomplete) as any
