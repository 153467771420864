import React, { ReactNode, useCallback } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import { Form as ReactFinalForm, FormSpy, FormSpyProps } from 'react-final-form'
import { FormWrapperProps, useAppForm } from './FormWrapper'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import { SubmitButton, SubmitButtonProps } from './SubmitButton'
import type { DocumentNode } from 'graphql'
import { makeStyles } from '@material-ui/core/styles'
import { VariablesOf } from '@graphql-typed-document-node/core'
import { PartialDeep } from 'type-fest'
import { FormDialogOpenProps } from './FormDialogOpenProps'
import { useCalcDecorator } from './CalcDecorator'

const focusDecorator = createDecorator()

export const FormValuesSpy = <T extends Record<string, unknown> = Record<string, any>>(
  props: FormSpyProps<T>
) => <FormSpy<T> subscription={{ values: true }} {...props} />

type FormDialogProps<S extends DocumentNode> = FormWrapperProps<S> & {
  title: ReactNode
  dialogProps?: Partial<DialogProps>
  dialogContentProps?: Partial<DialogContentProps>
  submitButtonProps?: SubmitButtonProps
  children: ReactNode
  extraActions?: ReactNode
} & FormDialogOpenProps

const useStyles = makeStyles(() => ({
  form: {
    height: '100%',
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const FormDialog = <S extends DocumentNode>({
  open,
  mutation,
  redirect,
  refetchQueries,
  onChange,
  children,
  title,
  dialogProps = {},
  dialogContentProps,
  submitButtonProps = {},
  onSubmit: inputOnSubmit,
  processData,
  mutators = {},
  onBeforeSubmit,
  successSnackbar,
  extraActions,
  ...props
}: FormDialogProps<S>) => {
  const closeDialog: () => void = useCallback(() => {
    if ('setOpen' in props) {
      props.setOpen(false)
    } else {
      props.onClose()
    }
  }, [props])

  const onSubmitted = useCallback(
    (result) => {
      if (inputOnSubmit) {
        inputOnSubmit(result)
      }
      closeDialog()
    },
    [inputOnSubmit, closeDialog]
  )

  const { onSubmit, loading } = useAppForm({
    mutation,
    onSubmit: onSubmitted,
    onBeforeSubmit,
    refetchQueries,
    onChange,
    redirect,
    processData,
    successSnackbar,
  })

  const classes = useStyles()
  const { CalculationContextProvider, decorator } = useCalcDecorator()

  return (
    <Dialog keepMounted={false} open={open} onClose={closeDialog} disableEnforceFocus={true} {...dialogProps}>
      <ReactFinalForm<VariablesOf<S>, PartialDeep<VariablesOf<S>>>
        mutators={{ ...arrayMutators, ...mutators }}
        decorators={[focusDecorator as any, decorator]}
        onSubmit={onSubmit}
        {...props}
        render={({ handleSubmit }) => (
          <CalculationContextProvider>
            <form onSubmit={handleSubmit} className={classes.form}>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent {...dialogContentProps}>{children}</DialogContent>
              <DialogActions>
                {extraActions}
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
                <SubmitButton color="primary" label={'Save'} loading={loading} {...submitButtonProps} />
              </DialogActions>
            </form>
          </CalculationContextProvider>
        )}
      />
    </Dialog>
  )
}
