import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useAppContext } from './AppContext'
import { makeStyles, Theme, ThemeProvider, useTheme, withStyles } from '@material-ui/core/styles'
import { UserAuthError } from '../common/UserAuthError'
import { useCommonTranslations } from '../common/Translation'
import useCustomSnackbar from 'src/lib/useSnackbar'
import { AppBar, Box, LinearProgress, Snackbar, Toolbar, Typography, useMediaQuery } from '@material-ui/core'
import { useCurrentConferenceNameAllowEmpty, useCurrentFormNameAllowEmpty } from '../common/useCurrent'
import Error from 'next/error'
import {
  ConferenceRegisterContext,
  FormRegisterContext,
  useConferenceRegisterContext,
  useFormRegisterContext,
} from '../formeditor/FormRegisterContext'
import { useQuery } from '@apollo/client/react/hooks'
import { UserLayoutDocument, UserLayoutQuery } from './UserLayout.generated'
import { RegistrationFormFragment } from '../register/RegisterForm.generated'
import { MaybeRtlBox } from '../common/MaybeRtlBox'
import { Language } from '../../data/interfaces/Language'
import { TranslationContext } from '../translations/Translations'
import { CurrentUserLabel } from '../formeditor/components/CurrentUserLabel'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import Link from 'next/link'
import { routes } from '../../routes'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { useAppTheme } from '../formeditor/components/UseAppTheme'
import { Banner, BannerProps } from '../formeditor/components/Banner'
import { configureScope, setUser } from '@sentry/nextjs'
import { Alert } from '@material-ui/lab'
import { FormDataFragment, ShortConferenceDataFragment } from '../../data/fragments.generated'
import { useDebugListener } from './UseDebugListener'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerLink: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    color: 'inherit',
  },
  title: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  toolbar: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  backIcon: {
    marginRight: theme.spacing(2),
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  bannerContainer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(-4),
    },
  },
}))

const ConditionalFormRegisterContext = ({
  form,
  children,
}: {
  form?: RegistrationFormFragment
  children: ReactNode
}) => {
  if (form) {
    return <FormRegisterContext.Provider initialState={{ form }}>{children}</FormRegisterContext.Provider>
  } else {
    return <>{children}</>
  }
}

export const UserLayoutBackHrefContext = createContext<{
  backHref?: string
  setBackHref: (href?: string) => void
}>({ setBackHref: () => {} })

export const useUserLayoutSetBackHref = (href: string) => {
  const { setBackHref } = useContext(UserLayoutBackHrefContext)
  useEffect(() => {
    setBackHref(href)
    return () => {
      setBackHref()
    }
  }, [href, setBackHref])
}

function UserLayoutToolbar({
  conference,
  form,
}: {
  conference: ShortConferenceDataFragment
  form?: Pick<FormDataFragment, 'settings'>
}) {
  const classes = useStyles()
  const { direction } = useTheme()
  const { asPath } = useRouter()

  const { backHref } = useContext(UserLayoutBackHrefContext)
  const userHomeRoute = backHref || routes.register.userHome(conference.shortName).href
  const showBack = asPath !== userHomeRoute
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const { settings } = useConferenceRegisterContext()
  const { formSettings } = useFormRegisterContext()
  const bannerSettings: BannerProps = useMemo(
    () => ({ ...settings, ...formSettings?.overrides }),
    [settings, formSettings]
  )

  const title = form?.settings?.title || conference?.settings.title || conference?.name

  return (
    <>
      <AppBar position={isSmall ? 'relative' : 'fixed'} elevation={2}>
        <Toolbar className={classes.toolbar}>
          <Link href={userHomeRoute} passHref>
            <a className={classes.headerLink}>
              {showBack ? (
                <div className={classes.backIcon}>
                  {direction === 'rtl' ? <ArrowForward /> : <ArrowBack />}
                </div>
              ) : null}

              <Typography variant="h6" className={classes.title}>
                <MaybeRtlBox component={'span'}>{title}</MaybeRtlBox>
              </Typography>
            </a>
          </Link>
          <Typography variant="h6">
            <CurrentUserLabel />
          </Typography>
        </Toolbar>
      </AppBar>

      {isSmall ? null : <Toolbar />}

      {bannerSettings?.banner ? (
        <div className={classes.bannerContainer}>
          <Banner {...bannerSettings} />
        </div>
      ) : null}
    </>
  )
}

export const UserLayoutContextWrapper = ({
  data,
  children,
  inDnd = false,
}: {
  data: UserLayoutQuery | undefined
  children: ReactNode
  inDnd?: boolean
}) => {
  const { asPath, query } = useRouter()

  const conference = data?.conference
  const formName = useCurrentFormNameAllowEmpty()
  const form = conference?.forms.find((v) => v.shortName?.toLowerCase() === formName?.toLowerCase())
  // (data && 'form' in data && (data as UserLayoutWithFormQuery)?.form) || undefined
  const isPreview = query.preview
  const user = isPreview ? undefined : data?.conferenceUser

  const isScience = asPath?.toLowerCase().includes(
    routes.userScience
      .index(conference?.shortName || '')
      .href?.split('?')[0]
      ?.toLowerCase()
  )
  const isForm = asPath
    ?.toLowerCase()
    .includes(routes.register.form(conference?.shortName || '', form?.shortName || '').href?.toLowerCase())

  const language =
    (isScience
      ? conference?.scienceSettings.language || Language.En
      : isForm
      ? form?.settings.language
      : user?.participantSettings?.language) ||
    conference?.settings.language ||
    Language.En
  const isRtl = language === Language.He

  const t = useCommonTranslations()
  const snackbar = useCustomSnackbar()
  const { error } = useAppContext()

  useEffect(() => {
    if (error) {
      snackbar.showError(t.ui.error)
    }
  }, [error, snackbar, t.ui.error])

  const isAuthError =
    error?.message.toLowerCase().includes('unauthenticated') || error?.stack?.includes('AuthenticationError')

  const finalChildren = isAuthError ? (
    <UserAuthError initialError={t.ui.authError} />
  ) : !data ? null : (
    children
  )

  const direction = language !== Language.He ? 'ltr' : 'rtl'
  useEffect(() => {
    const bodyEl = document.querySelector('body')
    if (bodyEl) {
      bodyEl.style.direction = direction
      return () => {
        bodyEl.style.direction = 'ltr'
      }
    }
  }, [direction])

  useEffect(() => {
    if (user) {
      setUser({ name: user.name, email: user.email, id: user.id })
    }
    return () => {
      configureScope((scope) => scope.setUser(null))
    }
  }, [user])

  const title = form?.settings?.title || conference?.settings.title || conference?.name
  const formTheme = Object.values(form?.formSchema || {}).find(
    ({ type }) => (typeof type === 'object' ? type.resolvedName : type) === 'ThemeContainer'
  )?.props?.theme
  const appTheme = useAppTheme(
    useMemo(() => ({ rtl: isRtl, ...formTheme }), [formTheme, isRtl]),
    inDnd
  )
  const [backHref, setBackHref] = useState<string | undefined>()

  return (
    <ConferenceRegisterContext.Provider initialState={{ conference, user }}>
      <Head>{title ? <title>{title}</title> : null}</Head>
      {isPreview ? (
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={true}>
          <Alert severity={'info'}>
            <Typography variant={'h4'}>Previewing</Typography>
          </Alert>
        </Snackbar>
      ) : null}

      <div dir={direction}>
        <UserLayoutBackHrefContext.Provider
          value={useMemo(() => ({ backHref, setBackHref }), [backHref, setBackHref])}
        >
          <ConditionalFormRegisterContext form={form}>
            <ThemeProvider theme={appTheme}>
              <TranslationContext.Provider initialState={language}>
                {conference && <UserLayoutToolbar conference={conference} form={form} />}
                {finalChildren}
              </TranslationContext.Provider>
            </ThemeProvider>
          </ConditionalFormRegisterContext>
        </UserLayoutBackHrefContext.Provider>
      </div>
    </ConferenceRegisterContext.Provider>
  )
}

export default function UserLayout({ children }: { noLayout?: boolean; children: React.ReactNode }) {
  const { loading } = useAppContext()
  const conferenceName = useCurrentConferenceNameAllowEmpty()
  const { data } = useQuery(UserLayoutDocument, {
    variables: { conferenceName: conferenceName || '' },
    skip: !conferenceName,
  })
  const conference = data?.conference
  const conferenceNotFound = data && !conference
  useDebugListener()
  if (!conferenceName || conferenceNotFound) {
    return <Error statusCode={404} />
  }

  return (
    <>
      <Box visibility={loading ? 'visible' : 'hidden'} position={'fixed'} top={0} width={'100%'} zIndex={2}>
        <FatProgress color={'primary'} />
      </Box>

      <UserLayoutContextWrapper data={data}>{children}</UserLayoutContextWrapper>
    </>
  )
}

const FatProgress = withStyles({ root: { height: 6 } })(LinearProgress)
