import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { TEXT_FIELD_TYPE, TextField as MuiTextField } from '../../../common/AppTextField'
import { Box } from '@material-ui/core'
import { useConnect } from '../useConnect'
import { useTextValidators } from '../useValidate'
import { prefixWrap } from '../prefixWrap'
import { AppUserComponent } from '../AppUserComponent'
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField/TextField'
import { FieldProps, useField } from 'react-final-form'
import { ShowErrorFunc } from '../../MuiRffUtil'
import { AppAutocomplete } from '../../../common/AppAutocomplete'
import { PrefixChildren } from '../addNamePrefix'
import { FormProductShowIf } from '../../../../data/interfaces/FormProductShowIf'
import { useConditionalLabel } from '../useConditionalLabel'

export const TextFieldAutocompleteContext = createContext<Record<string, Set<string> | undefined>>({})

export type ConditionalLabel = FormProductShowIf & { label: ReactNode }

export type TextFieldProps = Partial<Omit<MuiTextFieldProps, 'type' | 'onChange'>> & {
  name: string
  type?: TEXT_FIELD_TYPE
  fieldProps?: Partial<FieldProps<any, any>>
  showError?: ShowErrorFunc
  conditionalLabels?: ConditionalLabel[]
  inputLanguage?: 'EN' | 'HE'
}

export const TextField: AppUserComponent<
  TextFieldProps & {
    minChars?: number
    maxChars?: number
    maxWords?: number
    originalName?: string
  }
> = prefixWrap(
  ({
    maxWords,
    maxChars,
    minChars,
    fieldProps: inputFieldProps,
    inputLanguage,
    name,
    originalName,
    label: labelProp,
    conditionalLabels,
    ...props
  }) => {
    const label = useConditionalLabel(labelProp, conditionalLabels)
    const connect = useConnect()

    const composedValidators = useTextValidators({
      fieldProps: inputFieldProps,
      inputLanguage,
      minChars,
      maxChars,
      maxWords,
      required: props.required,
      fieldType: props.type,
    })

    const textFieldAutocompleteContext = useContext(TextFieldAutocompleteContext)
    const hasAutocomplete = !!(originalName && textFieldAutocompleteContext?.[originalName])
    const {
      input: { value: optionCurrentValue },
    } = useField(name, { subscription: { value: hasAutocomplete } })

    const autoCompleteOptions = useMemo(
      () =>
        Array.from((originalName && textFieldAutocompleteContext?.[originalName]) || []).filter(
          (v) => v !== optionCurrentValue
        ),
      [originalName, textFieldAutocompleteContext, optionCurrentValue]
    )

    const fieldProps = useMemo(
      () => ({
        ...(props.type === 'number' ? { parse: (v: any) => v && +v } : {}),
        ...inputFieldProps,
        validate: props.disabled ? undefined : composedValidators,
      }),
      [inputFieldProps, composedValidators, props.type, props.disabled]
    )

    return (
      <Box width={props.fullWidth ? '100%' : undefined} {...{ ref: connect }}>
        {autoCompleteOptions.length ? (
          <PrefixChildren prefix={''}>
            <AppAutocomplete
              textFieldProps={props}
              disableClearable
              fullWidth={false}
              options={autoCompleteOptions}
              autoSelect
              autoComplete
              freeSolo
              fieldProps={fieldProps}
              name={name}
              label={label}
            />
          </PrefixChildren>
        ) : (
          <MuiTextField fullWidth={false} {...props} fieldProps={fieldProps} name={name} label={label} />
        )}
      </Box>
    )
  }
)

export const MARGIN_OPTIONS = [
  { label: 'Normal', value: 'normal' },
  { label: 'Dense', value: 'dense' },
  { label: 'None', value: 'none' },
]

TextField.isField = true

TextField.craft = {
  displayName: 'Text Field',
  props: {
    name: 'placeholder',
    label: 'Text Field',
    type: 'text',
    fullWidth: false,
    rows: 3,
  },
}

TextField.createTemplate = (t) => (
  <TextField {...TextField.craft?.props} name={'placeholder'} label={t.ui.defaultProps.pleaseEnterText} />
)
