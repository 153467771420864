import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DndContextProps,
} from '@dnd-kit/core'
import React, { ReactNode, useMemo } from 'react'
import { sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box } from '@material-ui/core'

function useCommonSensors() {
  return useSensors(
    useSensor(PointerSensor, { activationConstraint: { delay: 100, tolerance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
}

export const CommonDndContext = ({ children, ...props }: { children: ReactNode } & DndContextProps) => {
  return (
    <DndContext sensors={useCommonSensors()} collisionDetection={closestCenter} {...props}>
      {children}
    </DndContext>
  )
}

export const useCommonSortable = (id: string) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  return useMemo(
    () => ({
      handleProps: { ...attributes, ...listeners },
      setNodeRef,
      style: {
        transform: CSS.Transform.toString(transform),
        transition,
      },
    }),
    [attributes, listeners, setNodeRef, transform, transition]
  )
}

export const CommonSortable = ({ id, children }: { id: string; children: ReactNode }) => {
  const { handleProps, style, setNodeRef } = useCommonSortable(id)
  return (
    <Box component={'span'} display={'inline-block'} {...{ ...handleProps, ref: setNodeRef }} {...style}>
      {children}
    </Box>
  )
}
