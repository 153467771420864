import { ReportType } from '../src/data/interfaces/ReportType';
import { ChargeType } from '../src/data/interfaces/ChargeType';
import { Currency } from '../src/data/interfaces/Currency';
import { ProductType } from '../src/data/interfaces/ProductType';
import { FormFieldFieldType } from '../src/data/interfaces/FormFieldFieldType';
import { PaymentState } from '../src/data/interfaces/PaymentState';
import { PaymentType } from '../src/data/interfaces/PaymentType';
import { PricingPeriodsId } from '../src/data/interfaces/PricingPeriodsId';
import { PrintTemplateType } from '../src/data/interfaces/PrintTemplateType';
import { FormProductState } from '../src/data/interfaces/FormProductState';
import { SlateInput } from '../src/data/interfaces/SlateInput';
import { AbstractData } from '../src/data/interfaces/AbstractData';
import { ConferenceSettings } from '../src/data/interfaces/ConferenceSettings';
import { ConversionRates } from '../src/data/interfaces/ConversionRates';
import { ExtraCharge } from '../src/data/interfaces/ExtraCharges';
import { FormProductData } from '../src/data/interfaces/FormProductData';
import { FormProductFields } from '../src/data/interfaces/FormProductField';
import { FormSchema } from '../src/data/interfaces/FormSchema';
import { FormSettings } from '../src/data/interfaces/FormSettings';
import { InputAbstractData } from '../src/data/interfaces/AbstractData';
import { ParticipantSettings } from '../src/data/interfaces/ParticipantSettings';
import { Prices } from '../src/data/interfaces/Prices';
import { PrintTemplateCustomData } from '../src/data/interfaces/PrintTemplateCustomData';
import { ProductData } from '../src/data/interfaces/ProductData';
import { ProductRegistrationData } from '../src/data/interfaces/ProductRegistrationData';
import { RegistrationData } from '../src/data/interfaces/RegistrationData';
import { RegularPrintFormData } from '../src/data/interfaces/PrintTemplateCustomData';
import { ReportData } from '../src/data/interfaces/ReportData';
import { ReviewData } from '../src/data/interfaces/ReviewData';
import { ScienceSettings } from '../src/data/interfaces/ScienceSettings';
import { FormProductShowIf } from '../src/data/interfaces/FormProductShowIf';
import { FormFieldSubField } from '../src/data/interfaces/FormFieldSubField';
import { SubmitterData } from '../src/data/interfaces/RegistrationData';
import { TemplateMeta } from '../src/data/interfaces/TemplateMeta';
import { UserData } from '../src/data/interfaces/UserData';
import { UserPermissions } from '../src/data/interfaces/UserPermissions';
import { VoucherData } from '../src/data/interfaces/VoucherData';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Abstract as AbstractModel, AbstractGroup as AbstractGroupModel, Charge as ChargeModel, ChargeDue as ChargeDueModel, ClientCharge as ClientChargeModel, CompanyClient as CompanyClientModel, Conference as ConferenceModel, Email as EmailModel, EmailTemplate as EmailTemplateModel, Form as FormModel, FormField as FormFieldModel, FormProduct as FormProductModel, FormProductProduct as FormProductProductModel, Hotel as HotelModel, Invoice as InvoiceModel, Lead as LeadModel, LeadPayment as LeadPaymentModel, NodeSchema as NodeSchemaModel, Participant as ParticipantModel, ParticipantCounts as ParticipantCountsModel, ParticipantDue as ParticipantDueModel, ParticipantTag as ParticipantTagModel, Payment as PaymentModel, Person as PersonModel, PrintTemplate as PrintTemplateModel, Product as ProductModel, ProductRegistration as ProductRegistrationModel, Registration as RegistrationModel, Report as ReportModel, Review as ReviewModel, Reviewer as ReviewerModel, SelectedProduct as SelectedProductModel, Tag as TagModel, Template as TemplateModel, User as UserModel, Voucher as VoucherModel } from '../generated/modelTypes';
import { Context } from '../src/apollo/context';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type EnumResolverSignature<T, AllowedValues = any> = { [key in keyof T]?: AllowedValues };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AbstractCompletePageJsonType: SlateInput;
  AbstractData1JsonType: AbstractData;
  AbstractDataJsonType: AbstractData;
  AccountBookClientJsonType: unknown;
  ConferenceSettingsJsonType: ConferenceSettings;
  ConversionRates: ConversionRates;
  /** A date string, such as 2007-12-03, compliant with the \`full-date\` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: unknown;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  ExtraChargeJsonType: ExtraCharge;
  FormProductDataJsonType: FormProductData;
  FormProductFieldsJsonType: FormProductFields;
  FormSchemaJsonType: FormSchema;
  FormSettingsJsonType: FormSettings;
  InputAbstractDataJsonType: InputAbstractData;
  InvoiceProductJsonType: unknown;
  /** The \`JSON\` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, any>|Array<any>;
  /** The \`JSONObject\` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Record<string, any>;
  MetaJsonType: unknown;
  Partial2JsonType: unknown;
  PartialRegistrationDataJsonType: unknown;
  ParticipantRolesJsonType: unknown;
  ParticipantSettings1JsonType: ParticipantSettings;
  ParticipantSettingsJsonType: ParticipantSettings;
  PaymentDataJsonType: Record<string, string>;
  PersonData1JsonType: Record<string, string>;
  PricesJsonType: Prices;
  PrintTemplateCustomDataJsonType: PrintTemplateCustomData;
  ProductDataJsonType: ProductData;
  ProductRegistrationDataJsonType: ProductRegistrationData;
  Record8JsonType: unknown;
  RecordStringUnknownJsonType: unknown;
  RegistrationData1JsonType: RegistrationData;
  RegistrationDataJsonType: RegistrationData;
  RegularPrintFormDataJsonType: RegularPrintFormData;
  ReportDataJsonType: ReportData;
  ReviewDataJsonType: ReviewData;
  ScienceSettingsJsonType: ScienceSettings;
  ShowIfJsonType: FormProductShowIf;
  SlateInput1JsonType: SlateInput;
  SlateInputJsonType: SlateInput;
  SubFieldsJsonType: FormFieldSubField;
  SubmitterDataJsonType: SubmitterData;
  TemplateMetaJsonType: TemplateMeta;
  UserDataJsonType: UserData;
  UserPermissionsJsonType: UserPermissions;
  VoucherDataJsonType: VoucherData;
};

export type Abstract = {
  __typename?: 'Abstract';
  abstractGroup?: Maybe<AbstractGroup>;
  abstractGroupId?: Maybe<Scalars['ID']>;
  acceptedFor?: Maybe<Scalars['String']>;
  body: Scalars['SlateInputJsonType'];
  category: Scalars['String'];
  conference: Conference;
  conferenceId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  data: Scalars['AbstractDataJsonType'];
  id: Scalars['ID'];
  idInConference: Scalars['Float'];
  reviewers: Array<Reviewer>;
  reviews: Array<Review>;
  state?: Maybe<AbstractState>;
  submitter: Participant;
  submitterData?: Maybe<Scalars['SubmitterDataJsonType']>;
  submitterId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AbstractAbstractGroupArgs = {
  orderBy?: Maybe<AbstractGroupPropertiesEnum>;
};


export type AbstractConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type AbstractReviewersArgs = {
  orderBy?: Maybe<ReviewerPropertiesEnum>;
};


export type AbstractReviewsArgs = {
  orderBy?: Maybe<ReviewPropertiesEnum>;
};


export type AbstractSubmitterArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type AbstractGroup = {
  __typename?: 'AbstractGroup';
  abstracts: Array<Abstract>;
  body?: Maybe<Scalars['SlateInput1JsonType']>;
  category: Scalars['String'];
  conference: Conference;
  conferenceId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['AbstractData1JsonType']>;
  id: Scalars['ID'];
  submitter: Participant;
  submitterId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AbstractGroupAbstractsArgs = {
  orderBy?: Maybe<AbstractPropertiesEnum>;
};


export type AbstractGroupConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type AbstractGroupSubmitterArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type AbstractGroupInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  body?: Maybe<Scalars['SlateInputJsonType']>;
};

export enum AbstractGroupPropertiesEnum {
  Body = 'body',
  Category = 'category',
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  SubmitterId = 'submitterId',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum AbstractPropertiesEnum {
  AbstractGroupId = 'abstractGroupId',
  AcceptedFor = 'acceptedFor',
  Body = 'body',
  Category = 'category',
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  IdInConference = 'idInConference',
  State = 'state',
  SubmitterData = 'submitterData',
  SubmitterId = 'submitterId',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum AbstractState {
  Accepted = 'ACCEPTED',
  Archive = 'ARCHIVE',
  Assigned = 'ASSIGNED',
  New = 'NEW',
  Pulled = 'PULLED',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
  Withdrawn = 'WITHDRAWN'
}

export type CategoryInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices: Scalars['JSONObject'];
  extraCharges?: Maybe<Array<Scalars['ExtraChargeJsonType']>>;
  type: ProductType;
  data?: Maybe<Scalars['FormProductDataJsonType']>;
  formProductProducts: Scalars['JSON'];
  hasPartner?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['FormProductFieldsJsonType']>;
  showIf?: Maybe<Array<Scalars['ShowIfJsonType']>>;
  state?: Maybe<FormProductState>;
};

export type Charge = {
  __typename?: 'Charge';
  amount: Scalars['Float'];
  amountCents: Scalars['Float'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['ID']>;
  creditCents?: Maybe<Scalars['Float']>;
  currency: Currency;
  due?: Maybe<Scalars['Float']>;
  formProduct?: Maybe<FormProduct>;
  formProductId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  participant: Participant;
  participantId: Scalars['ID'];
  payed?: Maybe<Scalars['Float']>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  payments: Array<Payment>;
  productRegistrations: Array<ProductRegistration>;
  productType: ProductType;
  regPeriod?: Maybe<PricingPeriodsId>;
  registrationId?: Maybe<Scalars['ID']>;
  selectedProduct: SelectedProduct;
  selectedProductId: Scalars['ID'];
  title: Scalars['String'];
  type: ChargeType;
};


export type ChargeClientArgs = {
  orderBy?: Maybe<ClientPropertiesEnum>;
};


export type ChargeFormProductArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type ChargeParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ChargePaymentsArgs = {
  orderBy?: Maybe<PaymentPropertiesEnum>;
};


export type ChargeProductRegistrationsArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};


export type ChargeSelectedProductArgs = {
  orderBy?: Maybe<SelectedProductPropertiesEnum>;
};

export type ChargeInput = {
  conferenceId: Scalars['ID'];
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  amount: Scalars['Int'];
  amountCents: Scalars['Int'];
  type: ChargeType;
  productType: ProductType;
  regPeriod?: Maybe<PricingPeriodsId>;
  selectedProductId?: Maybe<Scalars['ID']>;
  currency: Currency;
};

export enum ChargePropertiesEnum {
  Amount = 'amount',
  AmountCents = 'amountCents',
  ClientId = 'clientId',
  CreditCents = 'creditCents',
  Currency = 'currency',
  Due = 'due',
  FormProductId = 'formProductId',
  Id = 'id',
  ParticipantId = 'participantId',
  Payed = 'payed',
  PaymentChargeAmount = 'paymentChargeAmount',
  ProductType = 'productType',
  RegPeriod = 'regPeriod',
  RegistrationId = 'registrationId',
  SelectedProductId = 'selectedProductId',
  Title = 'title',
  Type = 'type'
}

export { ChargeType };

export type Client = {
  __typename?: 'Client';
  accountBookData: Scalars['AccountBookClientJsonType'];
  charges: Array<Charge>;
  clientCharges: Array<ClientCharge>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  invoices: Array<Invoice>;
  name: Scalars['String'];
  payments: Array<Payment>;
  settings?: Maybe<Scalars['ParticipantSettingsJsonType']>;
};


export type ClientChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type ClientClientChargesArgs = {
  orderBy?: Maybe<ClientChargePropertiesEnum>;
};


export type ClientInvoicesArgs = {
  orderBy?: Maybe<InvoicePropertiesEnum>;
};


export type ClientPaymentsArgs = {
  orderBy?: Maybe<PaymentPropertiesEnum>;
};

export type ClientCharge = {
  __typename?: 'ClientCharge';
  amount: Scalars['Float'];
  amountCents: Scalars['Float'];
  clientId?: Maybe<Scalars['ID']>;
  conferenceId: Scalars['ID'];
  currency: Currency;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum ClientChargePropertiesEnum {
  Amount = 'amount',
  AmountCents = 'amountCents',
  ClientId = 'clientId',
  ConferenceId = 'conferenceId',
  Currency = 'currency',
  Id = 'id',
  Title = 'title'
}

export enum ClientPropertiesEnum {
  AccountBookData = 'accountBookData',
  Email = 'email',
  Id = 'id',
  IdNumber = 'idNumber',
  Name = 'name',
  Settings = 'settings'
}

export type Conference = {
  __typename?: 'Conference';
  abstractCompletePage?: Maybe<Scalars['AbstractCompletePageJsonType']>;
  abstractGroups: Array<AbstractGroup>;
  abstractSchema?: Maybe<NodeSchema>;
  archived: Scalars['Boolean'];
  clients: Array<Client>;
  formFields: Array<FormField>;
  forms: Array<Form>;
  id: Scalars['ID'];
  name: Scalars['String'];
  participantCount?: Maybe<Scalars['Float']>;
  participants: Array<Participant>;
  products: Array<Product>;
  registered: Array<Participant>;
  registrations: Array<Registration>;
  reviewers: Array<Reviewer>;
  scienceSettings: Scalars['ScienceSettingsJsonType'];
  settings: Scalars['ConferenceSettingsJsonType'];
  shortName: Scalars['String'];
  superReviewers: Array<Participant>;
  tags: Array<Tag>;
};


export type ConferenceAbstractGroupsArgs = {
  orderBy?: Maybe<AbstractGroupPropertiesEnum>;
};


export type ConferenceAbstractSchemaArgs = {
  orderBy?: Maybe<NodeSchemaPropertiesEnum>;
};


export type ConferenceClientsArgs = {
  orderBy?: Maybe<ClientPropertiesEnum>;
};


export type ConferenceFormFieldsArgs = {
  orderBy?: Maybe<FormFieldPropertiesEnum>;
};


export type ConferenceFormsArgs = {
  orderBy?: Maybe<FormPropertiesEnum>;
};


export type ConferenceParticipantsArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ConferenceProductsArgs = {
  orderBy?: Maybe<ProductPropertiesEnum>;
};


export type ConferenceRegisteredArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ConferenceRegistrationsArgs = {
  orderBy?: Maybe<RegistrationPropertiesEnum>;
};


export type ConferenceReviewersArgs = {
  orderBy?: Maybe<ReviewerPropertiesEnum>;
};


export type ConferenceSuperReviewersArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ConferenceTagsArgs = {
  orderBy?: Maybe<TagPropertiesEnum>;
};

export enum ConferencePropertiesEnum {
  AbstractCompletePage = 'abstractCompletePage',
  Archived = 'archived',
  Id = 'id',
  Name = 'name',
  ParticipantCount = 'participantCount',
  ScienceSettings = 'scienceSettings',
  Settings = 'settings',
  ShortName = 'shortName'
}

export type ConferenceUser = {
  __typename?: 'ConferenceUser';
  id: Scalars['ID'];
  participantId?: Maybe<Scalars['ID']>;
  participantSettings?: Maybe<Scalars['ParticipantSettingsJsonType']>;
  email?: Maybe<Scalars['String']>;
  hasPassword: Scalars['Boolean'];
  reviewerId?: Maybe<Scalars['ID']>;
  registrationData?: Maybe<Scalars['JSONObject']>;
  scienceData?: Maybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  roles?: Maybe<Scalars['JSONObject']>;
  forms: Array<PartialForm>;
  hasDebt: Scalars['Boolean'];
  payments: Array<PartialPayment>;
  charges: Array<PartialCharge>;
};

export type CreateCreditGuardUrlInput = {
  currency: Scalars['String'];
  amountCents: Scalars['Int'];
  israeliCard: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  language: Scalars['String'];
};

export type CreateFormCopyAlsoOptions = {
  copyCategories?: Maybe<Scalars['Boolean']>;
};

export enum CreateFormTemplateInput {
  Hebrew = 'HEBREW',
  English = 'ENGLISH',
  Copy = 'COPY'
}

export type CreateInvoiceInput = {
  products?: Maybe<Array<InvoiceProduct>>;
  invoiceType: InvoiceType;
  conferenceId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  vatAmount: Scalars['Int'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  date: Scalars['String'];
  language: Language;
  currency: Scalars['String'];
  docDetail: Scalars['String'];
  payments?: Maybe<Array<InvoicePayment>>;
};

export { Currency };

export type Email = {
  __typename?: 'Email';
  conference?: Maybe<Participant>;
  date: Scalars['DateTime'];
  emailTemplateId?: Maybe<Scalars['ID']>;
  from: Scalars['String'];
  html: Scalars['String'];
  id: Scalars['ID'];
  participant?: Maybe<Participant>;
  participantId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  to: Scalars['String'];
};


export type EmailConferenceArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type EmailParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export enum EmailExistence {
  None = 'NONE',
  Password = 'PASSWORD',
  Secret = 'SECRET'
}

export enum EmailPropertiesEnum {
  Date = 'date',
  EmailTemplateId = 'emailTemplateId',
  From = 'from',
  Html = 'html',
  Id = 'id',
  ParticipantId = 'participantId',
  Title = 'title',
  To = 'to'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  conference?: Maybe<Conference>;
  conferenceId?: Maybe<Scalars['ID']>;
  emailType: EmailType;
  emails: Array<Email>;
  enabled: Scalars['Boolean'];
  form?: Maybe<Form>;
  formId?: Maybe<Scalars['ID']>;
  html: Scalars['SlateInputJsonType'];
  id: Scalars['ID'];
  meta?: Maybe<Scalars['TemplateMetaJsonType']>;
  name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type EmailTemplateConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type EmailTemplateEmailsArgs = {
  orderBy?: Maybe<EmailPropertiesEnum>;
};


export type EmailTemplateFormArgs = {
  orderBy?: Maybe<FormPropertiesEnum>;
};

export enum EmailTemplatePropertiesEnum {
  ConferenceId = 'conferenceId',
  EmailType = 'emailType',
  Enabled = 'enabled',
  FormId = 'formId',
  Html = 'html',
  Id = 'id',
  Meta = 'meta',
  Name = 'name',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum EmailType {
  Custom = 'CUSTOM',
  PaymentCash = 'PAYMENT_CASH',
  PaymentCheck = 'PAYMENT_CHECK',
  PaymentCreditGuard = 'PAYMENT_CREDIT_GUARD',
  PaymentInvoice = 'PAYMENT_INVOICE',
  PaymentWire = 'PAYMENT_WIRE',
  Registration = 'REGISTRATION',
  SciAcceptance = 'SCI_ACCEPTANCE',
  SciAssignment = 'SCI_ASSIGNMENT',
  SciInvited = 'SCI_INVITED',
  SciPulled = 'SCI_PULLED',
  SciRejection = 'SCI_REJECTION',
  SciScheduled = 'SCI_SCHEDULED',
  SciSubmitted = 'SCI_SUBMITTED',
  SciSubmittedGroup = 'SCI_SUBMITTED_GROUP',
  SciUpdated = 'SCI_UPDATED'
}

export type EventInput = {
  productType: ProductType;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export enum FailureType {
  Auth = 'AUTH',
  Charge = 'CHARGE',
  Validation = 'VALIDATION'
}

export enum FieldDisplayType {
  Affiliations = 'affiliations',
  Array = 'array',
  Boolean = 'boolean',
  File = 'file',
  Flights = 'flights',
  Object = 'object',
  Richtext = 'richtext',
  String = 'string'
}

export enum FieldSource {
  Custom = 'CUSTOM',
  Form = 'FORM'
}

export type Form = {
  __typename?: 'Form';
  cancelPolicy?: Maybe<Scalars['SlateInput1JsonType']>;
  completePage?: Maybe<Scalars['SlateInput1JsonType']>;
  conference: Conference;
  conferenceId: Scalars['ID'];
  emailTemplates: Array<EmailTemplate>;
  formCategories: Array<FormProduct>;
  formEvents: Array<FormProduct>;
  formHotels: Array<FormProduct>;
  formProductModifiers: Array<FormProduct>;
  formProducts: Array<FormProduct>;
  formSchema: Scalars['FormSchemaJsonType'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  payments: Array<Payment>;
  registrations: Array<Registration>;
  settings: Scalars['FormSettingsJsonType'];
  shortName: Scalars['String'];
};


export type FormConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type FormEmailTemplatesArgs = {
  orderBy?: Maybe<EmailTemplatePropertiesEnum>;
};


export type FormFormCategoriesArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormFormEventsArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormFormHotelsArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormFormProductModifiersArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormFormProductsArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormPaymentsArgs = {
  orderBy?: Maybe<PaymentPropertiesEnum>;
};


export type FormRegistrationsArgs = {
  orderBy?: Maybe<RegistrationPropertiesEnum>;
};

export type FormField = {
  __typename?: 'FormField';
  conference: Conference;
  conferenceId: Scalars['ID'];
  displayType: FieldDisplayType;
  fieldSource: FieldSource;
  fieldType: FormFieldFieldType;
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  subFields: Array<Scalars['SubFieldsJsonType']>;
};


export type FormFieldConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};

export { FormFieldFieldType };

export type FormFieldInput = {
  id?: Maybe<Scalars['ID']>;
  displayType: FieldDisplayType;
  fieldSource: FieldSource;
  fieldType: FormFieldFieldType;
  key: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  subFields?: Maybe<Array<Scalars['SubFieldsJsonType']>>;
};

export enum FormFieldPropertiesEnum {
  ConferenceId = 'conferenceId',
  DisplayType = 'displayType',
  FieldSource = 'fieldSource',
  FieldType = 'fieldType',
  Id = 'id',
  Key = 'key',
  Label = 'label',
  Order = 'order'
}

export type FormHotelInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices: Scalars['JSONObject'];
  data?: Maybe<Scalars['JSONObject']>;
  extraCharges?: Maybe<Array<Scalars['ExtraChargeJsonType']>>;
  productId: Scalars['ID'];
  state?: Maybe<FormProductState>;
};

export type FormProduct = {
  __typename?: 'FormProduct';
  data?: Maybe<Scalars['FormProductDataJsonType']>;
  description?: Maybe<Scalars['String']>;
  extraCharges?: Maybe<Array<Scalars['ExtraChargeJsonType']>>;
  fields?: Maybe<Scalars['FormProductFieldsJsonType']>;
  form: Form;
  formId: Scalars['ID'];
  formProductProducts: Array<FormProductProduct>;
  hasPartner?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  modifierOnly?: Maybe<Scalars['Boolean']>;
  ord?: Maybe<Scalars['Float']>;
  participantCount?: Maybe<Scalars['Float']>;
  participants: Array<Participant>;
  prices: Scalars['PricesJsonType'];
  showIf?: Maybe<Array<Scalars['ShowIfJsonType']>>;
  state: FormProductState;
  title: Scalars['String'];
  type: ProductType;
};


export type FormProductFormArgs = {
  orderBy?: Maybe<FormPropertiesEnum>;
};


export type FormProductFormProductProductsArgs = {
  orderBy?: Maybe<FormProductProductPropertiesEnum>;
};


export type FormProductParticipantsArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type FormProductProduct = {
  __typename?: 'FormProductProduct';
  formProduct: FormProduct;
  formProductId: Scalars['ID'];
  id: Scalars['ID'];
  product: Product;
  productId: Scalars['ID'];
  registrationData?: Maybe<Scalars['RecordStringUnknownJsonType']>;
};


export type FormProductProductFormProductArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type FormProductProductProductArgs = {
  orderBy?: Maybe<ProductPropertiesEnum>;
};

export enum FormProductProductPropertiesEnum {
  FormProductId = 'formProductId',
  Id = 'id',
  ProductId = 'productId',
  RegistrationData = 'registrationData'
}

export enum FormProductPropertiesEnum {
  Data = 'data',
  Description = 'description',
  Fields = 'fields',
  FormId = 'formId',
  HasPartner = 'hasPartner',
  Id = 'id',
  ModifierOnly = 'modifierOnly',
  Ord = 'ord',
  ParticipantCount = 'participantCount',
  Prices = 'prices',
  State = 'state',
  Title = 'title',
  Type = 'type'
}

export { FormProductState };

export enum FormPropertiesEnum {
  CancelPolicy = 'cancelPolicy',
  CompletePage = 'completePage',
  ConferenceId = 'conferenceId',
  FormSchema = 'formSchema',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  Settings = 'settings',
  ShortName = 'shortName'
}

export type GetRegistrationResult = {
  __typename?: 'GetRegistrationResult';
  id: Scalars['ID'];
  participantId: Scalars['ID'];
  conferenceId: Scalars['ID'];
  formId: Scalars['ID'];
  data: Scalars['JSONObject'];
  paymentDue: Scalars['Int'];
  formSettings: Scalars['JSONObject'];
  productRegs: Array<ProductRegistration>;
};

export type Hotel = {
  __typename?: 'Hotel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum HotelPropertiesEnum {
  Id = 'id',
  Name = 'name'
}

export type ImportParticipantInput = {
  conferenceName: Scalars['String'];
  fields: Array<Scalars['JSONObject']>;
  rows: Array<Array<Scalars['String']>>;
  regData?: Maybe<Scalars['RegistrationDataJsonType']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountCents: Scalars['Float'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['ID']>;
  conference?: Maybe<Conference>;
  conferenceId?: Maybe<Scalars['ID']>;
  currency: Scalars['String'];
  date: Scalars['String'];
  htmlUrl: Scalars['String'];
  id: Scalars['ID'];
  invoiceType: InvoiceType;
  number: Scalars['String'];
  participant?: Maybe<Participant>;
  participantId?: Maybe<Scalars['ID']>;
  pdfUrl: Scalars['String'];
  products?: Maybe<Array<Scalars['InvoiceProductJsonType']>>;
  response: Scalars['String'];
};


export type InvoiceClientArgs = {
  orderBy?: Maybe<ClientPropertiesEnum>;
};


export type InvoiceConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type InvoiceParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type InvoicePayment = {
  paymentType: PaymentType;
  date: Scalars['String'];
  amountCents: Scalars['Int'];
  issuer?: Maybe<Scalars['String']>;
  cardExp?: Maybe<Scalars['String']>;
  cardMask?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  checkNumber?: Maybe<Scalars['String']>;
  numberOfPayments?: Maybe<Scalars['Int']>;
  firstPayment?: Maybe<Scalars['Int']>;
  periodicalPayment?: Maybe<Scalars['Int']>;
};

export type InvoiceProduct = {
  description: Scalars['String'];
  quantity: Scalars['Int'];
  priceCents: Scalars['Int'];
};

export enum InvoicePropertiesEnum {
  AmountCents = 'amountCents',
  ClientId = 'clientId',
  ConferenceId = 'conferenceId',
  Currency = 'currency',
  Date = 'date',
  HtmlUrl = 'htmlUrl',
  Id = 'id',
  InvoiceType = 'invoiceType',
  Number = 'number',
  ParticipantId = 'participantId',
  PdfUrl = 'pdfUrl',
  Response = 'response'
}

export enum InvoiceType {
  Invoice = 'INVOICE',
  InvoiceReceipt = 'INVOICE_RECEIPT',
  Receipt = 'RECEIPT',
  ReverseInvoice = 'REVERSE_INVOICE'
}

export enum Language {
  En = 'EN',
  He = 'HE'
}

export type Lead = {
  __typename?: 'Lead';
  conference: Conference;
  conferenceId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['RegistrationDataJsonType']>;
  email: Scalars['String'];
  formId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  leadPayments: Array<LeadPayment>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type LeadConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type LeadLeadPaymentsArgs = {
  orderBy?: Maybe<LeadPaymentPropertiesEnum>;
};

export type LeadPayment = {
  __typename?: 'LeadPayment';
  createdAt: Scalars['DateTime'];
  data: Scalars['Record8JsonType'];
  failureType: FailureType;
  id: Scalars['ID'];
  leadId: Scalars['ID'];
};

export enum LeadPaymentPropertiesEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  FailureType = 'failureType',
  Id = 'id',
  LeadId = 'leadId'
}

export enum LeadPropertiesEnum {
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  Data = 'data',
  Email = 'email',
  FormId = 'formId',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type LegacyAbstract = {
  __typename?: 'LegacyAbstract';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type LegacyConference = {
  __typename?: 'LegacyConference';
  id: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type LegacyParticipant = {
  __typename?: 'LegacyParticipant';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type LoginParticipantResult = {
  __typename?: 'LoginParticipantResult';
  participant: Participant;
  returnTo?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addClientCreditCard?: Maybe<Scalars['Boolean']>;
  addConferenceClient?: Maybe<Scalars['Boolean']>;
  addLeadPayment?: Maybe<Scalars['Boolean']>;
  addMultipleRegistrationProduct: Scalars['Boolean'];
  addParticipantPerson: Participant;
  addRegistrationProduct: Scalars['Boolean'];
  archiveConference?: Maybe<Conference>;
  assignParticipantRole: Participant;
  assignReviewers: Array<Abstract>;
  blockUser: User;
  bulkEditParticipants: Array<Participant>;
  changePassword: Scalars['Boolean'];
  changePasswordToken: Scalars['Boolean'];
  chargeCreditGuard: Payment;
  checkParticipant: EmailExistence;
  createCategory: FormProduct;
  createCharge: Scalars['Boolean'];
  createConference: Conference;
  createEmailTemplate: EmailTemplate;
  createEvent: Product;
  createForm: Form;
  createHotel: Hotel;
  createInvoice: Invoice;
  createOrUpdateFormHotel: FormProduct;
  createParticipant: Participant;
  createPayment?: Maybe<Payment>;
  createPrintTemplate: PrintTemplate;
  createProductModifier: FormProduct;
  createRegistration?: Maybe<Participant>;
  createReviewer: Scalars['Boolean'];
  createRoomBlock: Product;
  createVoucher: Voucher;
  deleteAbstract: Scalars['Boolean'];
  deleteCharge: Scalars['Boolean'];
  deleteEvent?: Maybe<Scalars['Boolean']>;
  deleteForm?: Maybe<Scalars['Boolean']>;
  deleteFormEvent: Scalars['Boolean'];
  deleteLead?: Maybe<Scalars['Boolean']>;
  deleteMultipleParticipants: Array<Participant>;
  deleteParticipant?: Maybe<Scalars['Boolean']>;
  deletePrintTemplate: Scalars['Boolean'];
  deleteProductModifier: Scalars['Boolean'];
  deleteRegistration?: Maybe<Scalars['Boolean']>;
  deleteReport: Scalars['Boolean'];
  deleteReview: Scalars['Boolean'];
  deleteReviewer: Participant;
  deleteSelectedProduct: Participant;
  deleteTag: Scalars['Boolean'];
  editReview: Review;
  importLegacyAbstracts: Scalars['Int'];
  importLegacyParticipants: Scalars['Int'];
  importParticipants?: Maybe<Scalars['Boolean']>;
  loginAdminParticipant: Scalars['Boolean'];
  loginParticipant?: Maybe<LoginParticipantResult>;
  loginParticipantPassword?: Maybe<ConferenceUser>;
  loginRegistration: Scalars['Boolean'];
  logoutParticipant: Scalars['Boolean'];
  manualEvent: Scalars['String'];
  moveAbstract: Abstract;
  moveSelectedProduct: Participant;
  redoEvent: Scalars['Boolean'];
  refreshClientList?: Maybe<Scalars['Boolean']>;
  register: RegisterResult;
  registerPay: Scalars['Boolean'];
  removeConferenceClient?: Maybe<Scalars['Boolean']>;
  removeRegistrationProduct: Scalars['Boolean'];
  reorderFormEvents: Scalars['Boolean'];
  reorderForms: Array<Form>;
  reorderProductModifiers: Array<FormProduct>;
  requestToken: Scalars['Boolean'];
  saveCustomEmailTemplate: EmailTemplate;
  sendParticipantEmail?: Maybe<Scalars['Boolean']>;
  signup: User;
  submitAbstract?: Maybe<Scalars['String']>;
  tagParticipant: Array<Participant>;
  unarchiveConference?: Maybe<Conference>;
  unblockUser: User;
  updateAbstract: Abstract;
  updateAbstractGroup: AbstractGroup;
  updateAbstractsState: Array<Abstract>;
  updateClient?: Maybe<Client>;
  updateConference: Conference;
  updateConferenceClient?: Maybe<Client>;
  updateConferenceCompletePage: Conference;
  updateConferenceFields?: Maybe<Conference>;
  updateEmailTemplate: EmailTemplate;
  updateEvent: Product;
  updateForm: Form;
  updateFormEmailTemplate: EmailTemplate;
  updateHotel: Hotel;
  updateLead?: Maybe<Scalars['Boolean']>;
  updateParticipantContact: Participant;
  updateParticipantNotes: Participant;
  updateParticipantPeople: Participant;
  updateParticipantSettings: Participant;
  updatePayment: Payment;
  updatePermissions: User;
  updateProductRegistration: ProductRegistration;
  updateReport: Report;
  updateRoomBlock: Product;
  updateSciEmail: EmailTemplate;
  updateScienceSettings: Conference;
  updateScienceSubmitForm: Conference;
  updateTag: Tag;
  updateTemplate: Template;
  updateVoucher: Voucher;
  updatedCharge: Scalars['Boolean'];
};


export type MutationAddClientCreditCardArgs = {
  clientId: Scalars['String'];
  conferenceId: Scalars['String'];
  amountCents: Scalars['Int'];
  currency: Currency;
  data?: Maybe<Scalars['JSONObject']>;
};


export type MutationAddConferenceClientArgs = {
  conferenceName: Scalars['String'];
  clientId: Scalars['ID'];
};


export type MutationAddLeadPaymentArgs = {
  conferenceName: Scalars['String'];
  email: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
};


export type MutationAddMultipleRegistrationProductArgs = {
  participantId: Scalars['ID'];
  formProductId: Scalars['ID'];
  products: Array<RegProductInput>;
  charge?: Maybe<ChargeInput>;
};


export type MutationAddParticipantPersonArgs = {
  participantId: Scalars['ID'];
  person: PersonInput;
};


export type MutationAddRegistrationProductArgs = {
  product: RegProductInput;
  charge?: Maybe<ChargeInput>;
};


export type MutationArchiveConferenceArgs = {
  id: Scalars['ID'];
};


export type MutationAssignParticipantRoleArgs = {
  participantId: Scalars['ID'];
  role: Scalars['String'];
  remove?: Maybe<Scalars['Boolean']>;
};


export type MutationAssignReviewersArgs = {
  abstractIds: Array<Scalars['String']>;
  reviewerIds: Array<Scalars['String']>;
  reviewsPerAbstract: Scalars['Int'];
  sendEmails?: Maybe<Scalars['Boolean']>;
};


export type MutationBlockUserArgs = {
  email: Scalars['String'];
};


export type MutationBulkEditParticipantsArgs = {
  conferenceId: Scalars['ID'];
  data: Scalars['JSONObject'];
};


export type MutationChangePasswordArgs = {
  participantId: Scalars['ID'];
  currentPassword?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
};


export type MutationChangePasswordTokenArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChargeCreditGuardArgs = {
  paymentId: Scalars['ID'];
  amountCents: Scalars['Int'];
  credit?: Maybe<Scalars['Boolean']>;
  document?: Maybe<InvoiceType>;
  vatRate?: Maybe<Scalars['Int']>;
  numberOfPayments?: Maybe<Scalars['Int']>;
  docDetail?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
};


export type MutationCheckParticipantArgs = {
  email: Scalars['String'];
  conferenceName: Scalars['String'];
  returnTo?: Maybe<Scalars['String']>;
};


export type MutationCreateCategoryArgs = {
  formId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  input: CategoryInput;
};


export type MutationCreateChargeArgs = {
  input: ChargeInput;
};


export type MutationCreateConferenceArgs = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  settings: Scalars['ConferenceSettingsJsonType'];
};


export type MutationCreateEmailTemplateArgs = {
  name: Scalars['String'];
  conferenceId: Scalars['ID'];
  formId: Scalars['ID'];
  emailType: EmailType;
};


export type MutationCreateEventArgs = {
  conferenceId: Scalars['ID'];
  data: EventInput;
};


export type MutationCreateFormArgs = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  conferenceId: Scalars['ID'];
  formSchema?: Maybe<Scalars['FormSchemaJsonType']>;
  settings?: Maybe<Scalars['FormSettingsJsonType']>;
  copyFrom?: Maybe<Scalars['ID']>;
  copyAlso?: Maybe<CreateFormCopyAlsoOptions>;
  template?: Maybe<CreateFormTemplateInput>;
};


export type MutationCreateHotelArgs = {
  name: Scalars['String'];
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateOrUpdateFormHotelArgs = {
  formId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  input: FormHotelInput;
};


export type MutationCreateParticipantArgs = {
  conferenceName: Scalars['String'];
  data: Scalars['RegistrationDataJsonType'];
};


export type MutationCreatePaymentArgs = {
  conferenceId: Scalars['ID'];
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  paymentType: PaymentType;
  data?: Maybe<Scalars['JSONObject']>;
  amountCents: Scalars['Int'];
  paymentState: PaymentState;
  currency: Scalars['String'];
};


export type MutationCreatePrintTemplateArgs = {
  conferenceName: Scalars['String'];
  regularData?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  customData?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  custom: Scalars['Boolean'];
  templateType: PrintTemplateType;
};


export type MutationCreateProductModifierArgs = {
  input: ProductModifierInput;
};


export type MutationCreateRegistrationArgs = {
  participantId: Scalars['ID'];
};


export type MutationCreateReviewerArgs = {
  conferenceName: Scalars['String'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sendEmail: Scalars['Boolean'];
};


export type MutationCreateRoomBlockArgs = {
  conferenceId: Scalars['ID'];
  roomBlock: RoomBlockInput;
};


export type MutationCreateVoucherArgs = {
  conferenceName: Scalars['String'];
  data: Scalars['VoucherDataJsonType'];
};


export type MutationDeleteAbstractArgs = {
  abstractId: Scalars['ID'];
};


export type MutationDeleteChargeArgs = {
  chargeId: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFormEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLeadArgs = {
  leadId: Scalars['ID'];
};


export type MutationDeleteMultipleParticipantsArgs = {
  participantIds: Array<Scalars['ID']>;
};


export type MutationDeleteParticipantArgs = {
  participantId: Scalars['ID'];
};


export type MutationDeletePrintTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductModifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRegistrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReviewArgs = {
  reviewId: Scalars['ID'];
};


export type MutationDeleteReviewerArgs = {
  participantId: Scalars['ID'];
};


export type MutationDeleteSelectedProductArgs = {
  selectedProductId: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationEditReviewArgs = {
  reviewId: Scalars['ID'];
  data: Scalars['JSON'];
};


export type MutationImportLegacyAbstractsArgs = {
  conferenceName: Scalars['String'];
  legacyConferenceId: Scalars['String'];
  abstractIds?: Maybe<Array<Scalars['String']>>;
};


export type MutationImportLegacyParticipantsArgs = {
  conferenceName: Scalars['String'];
  legacyConferenceId: Scalars['String'];
  participantIds?: Maybe<Array<Scalars['String']>>;
};


export type MutationImportParticipantsArgs = {
  data: ImportParticipantInput;
};


export type MutationLoginAdminParticipantArgs = {
  id: Scalars['ID'];
};


export type MutationLoginParticipantArgs = {
  token: Scalars['String'];
  conferenceName: Scalars['String'];
};


export type MutationLoginParticipantPasswordArgs = {
  conferenceName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginRegistrationArgs = {
  email: Scalars['String'];
  conferenceName: Scalars['String'];
  secretCode: Scalars['String'];
};


export type MutationLogoutParticipantArgs = {
  conferenceName: Scalars['String'];
};


export type MutationManualEventArgs = {
  eventType: Scalars['String'];
  aggregateId?: Maybe<Scalars['String']>;
  payload: Scalars['JSON'];
  meta?: Maybe<Scalars['JSON']>;
};


export type MutationMoveAbstractArgs = {
  abstractId: Scalars['ID'];
  participantId: Scalars['ID'];
};


export type MutationMoveSelectedProductArgs = {
  selectedProductId: Scalars['ID'];
  participantId: Scalars['ID'];
};


export type MutationRedoEventArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterArgs = {
  formId: Scalars['ID'];
  data?: Maybe<Scalars['JSONObject']>;
  paymentDetails?: Maybe<RegisterPaymentDetails>;
};


export type MutationRegisterPayArgs = {
  participantId: Scalars['ID'];
  paymentType: PaymentType;
  amountCents: Scalars['Int'];
  currency: Currency;
  data?: Maybe<Scalars['JSONObject']>;
};


export type MutationRemoveConferenceClientArgs = {
  conferenceName: Scalars['String'];
  clientId: Scalars['ID'];
};


export type MutationRemoveRegistrationProductArgs = {
  productRegistrationId: Scalars['ID'];
};


export type MutationReorderFormEventsArgs = {
  order: Array<Scalars['ID']>;
};


export type MutationReorderFormsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationReorderProductModifiersArgs = {
  order: Array<Scalars['ID']>;
};


export type MutationRequestTokenArgs = {
  email: Scalars['String'];
  conferenceName: Scalars['String'];
  forPasswordReset?: Maybe<Scalars['Boolean']>;
  returnTo?: Maybe<Scalars['String']>;
};


export type MutationSaveCustomEmailTemplateArgs = {
  conferenceId: Scalars['ID'];
  templateId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  html: Scalars['JSON'];
  name?: Maybe<Scalars['String']>;
};


export type MutationSendParticipantEmailArgs = {
  participantIds: Array<Scalars['ID']>;
  email: SendEmailInput;
};


export type MutationSignupArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  secretCode: Scalars['String'];
};


export type MutationSubmitAbstractArgs = {
  conferenceName: Scalars['String'];
  data: Scalars['InputAbstractDataJsonType'];
  participantId?: Maybe<Scalars['ID']>;
  abstracts?: Maybe<Array<Scalars['InputAbstractDataJsonType']>>;
};


export type MutationTagParticipantArgs = {
  tagId: Scalars['ID'];
  participantIds: Array<Scalars['String']>;
  remove?: Maybe<Scalars['Boolean']>;
};


export type MutationUnarchiveConferenceArgs = {
  id: Scalars['ID'];
};


export type MutationUnblockUserArgs = {
  email: Scalars['String'];
};


export type MutationUpdateAbstractArgs = {
  abstractId: Scalars['ID'];
  data: Scalars['InputAbstractDataJsonType'];
};


export type MutationUpdateAbstractGroupArgs = {
  input: AbstractGroupInput;
};


export type MutationUpdateAbstractsStateArgs = {
  abstractIds: Array<Scalars['String']>;
  state: AbstractState;
  acceptedFor?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateClientArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId: Scalars['ID'];
  name: Scalars['String'];
  details?: Maybe<Scalars['String']>;
};


export type MutationUpdateConferenceArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['ConferenceSettingsJsonType']>;
};


export type MutationUpdateConferenceClientArgs = {
  conferenceName: Scalars['String'];
  clientId: Scalars['ID'];
  settings: Scalars['ParticipantSettingsJsonType'];
};


export type MutationUpdateConferenceCompletePageArgs = {
  id: Scalars['ID'];
  completePage?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateConferenceFieldsArgs = {
  id: Scalars['ID'];
  formFields: Array<FormFieldInput>;
};


export type MutationUpdateEmailTemplateArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  data: EventInput;
};


export type MutationUpdateFormArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  formSchema?: Maybe<Scalars['FormSchemaJsonType']>;
  settings?: Maybe<Scalars['FormSettingsJsonType']>;
  cancelPolicy?: Maybe<Scalars['JSON']>;
  completePage?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateFormEmailTemplateArgs = {
  formId: Scalars['ID'];
  emailType: EmailType;
  title: Scalars['String'];
  html: Scalars['JSON'];
  enabled: Scalars['Boolean'];
};


export type MutationUpdateHotelArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateLeadArgs = {
  formId: Scalars['ID'];
  data: Scalars['RegistrationDataJsonType'];
};


export type MutationUpdateParticipantContactArgs = {
  participantId: Scalars['ID'];
  data: Scalars['JSONObject'];
};


export type MutationUpdateParticipantNotesArgs = {
  participantId: Scalars['ID'];
  notes: Scalars['JSON'];
};


export type MutationUpdateParticipantPeopleArgs = {
  participantId: Scalars['ID'];
  people: Array<PersonInput>;
};


export type MutationUpdateParticipantSettingsArgs = {
  participantId: Scalars['ID'];
  settings: Scalars['JSONObject'];
};


export type MutationUpdatePaymentArgs = {
  paymentId: Scalars['ID'];
  data?: Maybe<Scalars['JSONObject']>;
  amountCents?: Maybe<Scalars['Int']>;
  paymentState: PaymentState;
};


export type MutationUpdatePermissionsArgs = {
  id: Scalars['ID'];
  permissions: Scalars['UserPermissionsJsonType'];
};


export type MutationUpdateProductRegistrationArgs = {
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  data: Scalars['JSONObject'];
  amount?: Maybe<Scalars['Int']>;
  peopleIds?: Maybe<Array<Scalars['ID']>>;
  formId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  shareWithProdRegIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notes?: Maybe<Scalars['String']>;
};


export type MutationUpdateReportArgs = {
  id?: Maybe<Scalars['ID']>;
  input: ReportInput;
};


export type MutationUpdateRoomBlockArgs = {
  id: Scalars['ID'];
  roomBlock: RoomBlockInput;
};


export type MutationUpdateSciEmailArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceName: Scalars['String'];
  emailType: EmailType;
  title: Scalars['String'];
  html: Scalars['JSON'];
  meta?: Maybe<Scalars['JSONObject']>;
};


export type MutationUpdateScienceSettingsArgs = {
  conferenceName: Scalars['String'];
  sciSettings: Scalars['ScienceSettingsJsonType'];
};


export type MutationUpdateScienceSubmitFormArgs = {
  conferenceName: Scalars['String'];
  schema: Scalars['JSONObject'];
};


export type MutationUpdateTagArgs = {
  tagId?: Maybe<Scalars['ID']>;
  conferenceName: Scalars['String'];
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};


export type MutationUpdateTemplateArgs = {
  conferenceName: Scalars['String'];
  templateType: TemplateType;
  data: Scalars['JSON'];
};


export type MutationUpdateVoucherArgs = {
  id: Scalars['ID'];
  data: Scalars['VoucherDataJsonType'];
};


export type MutationUpdatedChargeArgs = {
  chargeId: Scalars['ID'];
  input: ChargeInput;
};

export type NodeSchema = {
  __typename?: 'NodeSchema';
  id: Scalars['ID'];
  schema: Scalars['FormSchemaJsonType'];
};

export enum NodeSchemaPropertiesEnum {
  Id = 'id',
  Schema = 'schema'
}

export type PartialCharge = {
  __typename?: 'PartialCharge';
  id: Scalars['ID'];
  title: Scalars['String'];
  currency: Currency;
  amount: Scalars['Float'];
  amountCents: Scalars['Float'];
  creditCents?: Maybe<Scalars['Int']>;
  productType: ProductType;
  type: ChargeType;
};

export type PartialForm = {
  __typename?: 'PartialForm';
  id: Scalars['ID'];
  settings: Scalars['FormSettingsJsonType'];
};

export type PartialPayment = {
  __typename?: 'PartialPayment';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currency: Currency;
  paymentState: PaymentState;
  paymentType: PaymentType;
};

export type Participant = {
  __typename?: 'Participant';
  abstractGroups: Array<AbstractGroup>;
  charges: Array<Charge>;
  conference: Conference;
  conferenceId: Scalars['ID'];
  counts?: Maybe<ParticipantCount>;
  createdAt: Scalars['DateTime'];
  data: Scalars['RegistrationData1JsonType'];
  dataOverrides?: Maybe<Scalars['PartialRegistrationDataJsonType']>;
  due?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  emails: Array<Email>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idInConference: Scalars['Float'];
  invoices: Array<Invoice>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['SlateInput1JsonType']>;
  oneTimeToken: Scalars['String'];
  participantDue?: Maybe<ParticipantsDue>;
  passwordHash?: Maybe<Scalars['String']>;
  payments: Array<Payment>;
  people: Array<Person>;
  productRegs: Array<ProductRegistration>;
  regCats?: Maybe<Array<Scalars['String']>>;
  registrations: Array<Registration>;
  reviewer?: Maybe<Reviewer>;
  roles?: Maybe<Scalars['ParticipantRolesJsonType']>;
  selectedProducts: Array<SelectedProduct>;
  settings: Scalars['ParticipantSettings1JsonType'];
  submittedAbstracts: Array<Abstract>;
  superReviewer?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
};


export type ParticipantAbstractGroupsArgs = {
  orderBy?: Maybe<AbstractGroupPropertiesEnum>;
};


export type ParticipantChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type ParticipantConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type ParticipantCountsArgs = {
  orderBy?: Maybe<ParticipantCountPropertiesEnum>;
};


export type ParticipantEmailsArgs = {
  orderBy?: Maybe<EmailPropertiesEnum>;
};


export type ParticipantInvoicesArgs = {
  orderBy?: Maybe<InvoicePropertiesEnum>;
};


export type ParticipantParticipantDueArgs = {
  orderBy?: Maybe<ParticipantsDuePropertiesEnum>;
};


export type ParticipantPaymentsArgs = {
  orderBy?: Maybe<PaymentPropertiesEnum>;
};


export type ParticipantPeopleArgs = {
  orderBy?: Maybe<PersonPropertiesEnum>;
};


export type ParticipantProductRegsArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};


export type ParticipantRegistrationsArgs = {
  orderBy?: Maybe<RegistrationPropertiesEnum>;
};


export type ParticipantReviewerArgs = {
  orderBy?: Maybe<ReviewerPropertiesEnum>;
};


export type ParticipantSelectedProductsArgs = {
  orderBy?: Maybe<SelectedProductPropertiesEnum>;
};


export type ParticipantSubmittedAbstractsArgs = {
  orderBy?: Maybe<AbstractPropertiesEnum>;
};


export type ParticipantTagsArgs = {
  orderBy?: Maybe<TagPropertiesEnum>;
};

export type ParticipantCount = {
  __typename?: 'ParticipantCount';
  conference: Participant;
  id: Scalars['ID'];
  reviewsAssigned: Scalars['Float'];
  reviewsCompleted: Scalars['Float'];
  submittedAbstracts: Scalars['Float'];
  totalCharges: Scalars['Float'];
  totalDue: Scalars['Float'];
  totalParticipantCharges: Scalars['Float'];
  totalPayed: Scalars['Float'];
};


export type ParticipantCountConferenceArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export enum ParticipantCountPropertiesEnum {
  Id = 'id',
  ReviewsAssigned = 'reviewsAssigned',
  ReviewsCompleted = 'reviewsCompleted',
  SubmittedAbstracts = 'submittedAbstracts',
  TotalCharges = 'totalCharges',
  TotalDue = 'totalDue',
  TotalParticipantCharges = 'totalParticipantCharges',
  TotalPayed = 'totalPayed'
}

export enum ParticipantPropertiesEnum {
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  Data = 'data',
  DataOverrides = 'dataOverrides',
  Due = 'due',
  Email = 'email',
  HasPassword = 'hasPassword',
  Id = 'id',
  IdInConference = 'idInConference',
  Name = 'name',
  Notes = 'notes',
  OneTimeToken = 'oneTimeToken',
  PasswordHash = 'passwordHash',
  Roles = 'roles',
  Settings = 'settings',
  SuperReviewer = 'superReviewer'
}

export type ParticipantTag = {
  __typename?: 'ParticipantTag';
  participantId: Scalars['ID'];
  tagId: Scalars['ID'];
};

export enum ParticipantTagPropertiesEnum {
  ParticipantId = 'participantId',
  TagId = 'tagId'
}

export type ParticipantsDue = {
  __typename?: 'ParticipantsDue';
  due: Scalars['Float'];
  participantId: Scalars['ID'];
  payed: Scalars['Float'];
};

export enum ParticipantsDuePropertiesEnum {
  Due = 'due',
  ParticipantId = 'participantId',
  Payed = 'payed'
}

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  charges: Array<Charge>;
  clientId?: Maybe<Scalars['ID']>;
  conference: Conference;
  conferenceId: Scalars['ID'];
  currency: Currency;
  data: Scalars['PaymentDataJsonType'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  participant?: Maybe<Participant>;
  participantId?: Maybe<Scalars['ID']>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  paymentState: PaymentState;
  paymentType: PaymentType;
};


export type PaymentChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type PaymentConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type PaymentParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export enum PaymentPropertiesEnum {
  Amount = 'amount',
  ClientId = 'clientId',
  ConferenceId = 'conferenceId',
  Currency = 'currency',
  Data = 'data',
  Date = 'date',
  Id = 'id',
  ParticipantId = 'participantId',
  PaymentChargeAmount = 'paymentChargeAmount',
  PaymentState = 'paymentState',
  PaymentType = 'paymentType'
}

export { PaymentState };

export { PaymentType };

export type Person = {
  __typename?: 'Person';
  conferenceId: Scalars['ID'];
  data?: Maybe<Scalars['PersonData1JsonType']>;
  id: Scalars['ID'];
  participant: Participant;
  participantId: Scalars['ID'];
  personType: Scalars['String'];
};


export type PersonParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type PersonInput = {
  id: Scalars['ID'];
  personType: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
};

export enum PersonPropertiesEnum {
  ConferenceId = 'conferenceId',
  Data = 'data',
  Id = 'id',
  ParticipantId = 'participantId',
  PersonType = 'personType'
}

export { PricingPeriodsId };

export type PrintTemplate = {
  __typename?: 'PrintTemplate';
  conference: Conference;
  conferenceId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  custom: Scalars['Boolean'];
  customData?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  id: Scalars['ID'];
  regularData?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  templateType: PrintTemplateType;
};


export type PrintTemplateConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};

export enum PrintTemplatePropertiesEnum {
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  Custom = 'custom',
  CustomData = 'customData',
  Id = 'id',
  RegularData = 'regularData',
  TemplateType = 'templateType'
}

export { PrintTemplateType };

export type Product = {
  __typename?: 'Product';
  available?: Maybe<Scalars['Boolean']>;
  children: Array<Product>;
  conference: Conference;
  conferenceId: Scalars['ID'];
  count?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['ProductDataJsonType']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  formProductProducts: Array<FormProductProduct>;
  hotel?: Maybe<Hotel>;
  hotelId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['MetaJsonType']>;
  order?: Maybe<Scalars['Float']>;
  parent?: Maybe<Product>;
  parentId?: Maybe<Scalars['ID']>;
  productRegs: Array<ProductRegistration>;
  productType: ProductType;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};


export type ProductChildrenArgs = {
  orderBy?: Maybe<ProductPropertiesEnum>;
};


export type ProductConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type ProductFormProductProductsArgs = {
  orderBy?: Maybe<FormProductProductPropertiesEnum>;
};


export type ProductHotelArgs = {
  orderBy?: Maybe<HotelPropertiesEnum>;
};


export type ProductParentArgs = {
  orderBy?: Maybe<ProductPropertiesEnum>;
};


export type ProductProductRegsArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};

export type ProductModifierInput = {
  id?: Maybe<Scalars['ID']>;
  formId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices?: Maybe<Scalars['PricesJsonType']>;
  type?: Maybe<ProductType>;
  data?: Maybe<Scalars['FormProductDataJsonType']>;
};

export enum ProductPropertiesEnum {
  Available = 'available',
  ConferenceId = 'conferenceId',
  Count = 'count',
  Data = 'data',
  Description = 'description',
  EndDate = 'endDate',
  EndTime = 'endTime',
  HotelId = 'hotelId',
  Id = 'id',
  Meta = 'meta',
  Order = 'order',
  ParentId = 'parentId',
  ProductType = 'productType',
  StartDate = 'startDate',
  StartTime = 'startTime',
  Title = 'title',
  Updated = 'updated'
}

export type ProductRegistration = {
  __typename?: 'ProductRegistration';
  amount: Scalars['Float'];
  charges: Array<Charge>;
  children: Array<ProductRegistration>;
  data?: Maybe<Scalars['ProductRegistrationDataJsonType']>;
  formProduct?: Maybe<FormProduct>;
  formProductId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductRegistration>;
  parentId?: Maybe<Scalars['ID']>;
  participant: Participant;
  participantId: Scalars['ID'];
  people: Array<Person>;
  product: Product;
  productId: Scalars['ID'];
  selectedProduct: SelectedProduct;
  selectedProductId: Scalars['ID'];
  shareGroup: Array<ProductRegistration>;
  shareGroupId?: Maybe<Scalars['ID']>;
};


export type ProductRegistrationChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type ProductRegistrationChildrenArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};


export type ProductRegistrationFormProductArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type ProductRegistrationParentArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};


export type ProductRegistrationParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ProductRegistrationPeopleArgs = {
  orderBy?: Maybe<PersonPropertiesEnum>;
};


export type ProductRegistrationProductArgs = {
  orderBy?: Maybe<ProductPropertiesEnum>;
};


export type ProductRegistrationSelectedProductArgs = {
  orderBy?: Maybe<SelectedProductPropertiesEnum>;
};


export type ProductRegistrationShareGroupArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};

export enum ProductRegistrationPropertiesEnum {
  Amount = 'amount',
  Data = 'data',
  FormProductId = 'formProductId',
  Id = 'id',
  Notes = 'notes',
  ParentId = 'parentId',
  ParticipantId = 'participantId',
  ProductId = 'productId',
  SelectedProductId = 'selectedProductId',
  ShareGroupId = 'shareGroupId'
}

export { ProductType };

export type Query = {
  __typename?: 'Query';
  abstract?: Maybe<Abstract>;
  abstractGroup?: Maybe<AbstractGroup>;
  abstractGroups: Array<AbstractGroup>;
  abstracts: Array<Abstract>;
  calcRegistrationCharges: Array<PartialCharge>;
  charge?: Maybe<Charge>;
  chargeTitleOptions: Array<Scalars['String']>;
  charges: Array<Charge>;
  checkEmail: Scalars['Boolean'];
  checkToken?: Maybe<Scalars['Boolean']>;
  client?: Maybe<Client>;
  clientCharge?: Maybe<ClientCharge>;
  clientCharges: Array<ClientCharge>;
  clientCreditCardLink: Scalars['String'];
  clients: Array<Client>;
  conference?: Maybe<Conference>;
  conferenceUser?: Maybe<ConferenceUser>;
  conferences: Array<Conference>;
  createCreditGuardUrl: Scalars['String'];
  email?: Maybe<Email>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplates: Array<EmailTemplate>;
  emails: Array<Email>;
  exchangeRates: Scalars['ConversionRates'];
  form?: Maybe<Form>;
  formField?: Maybe<FormField>;
  formFields: Array<FormField>;
  formProduct?: Maybe<FormProduct>;
  formProductProduct?: Maybe<FormProductProduct>;
  formProductProducts: Array<FormProductProduct>;
  formProducts: Array<FormProduct>;
  forms: Array<Form>;
  hotel?: Maybe<Hotel>;
  hotels: Array<Hotel>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  lead?: Maybe<Lead>;
  leadPayment?: Maybe<LeadPayment>;
  leadPayments: Array<LeadPayment>;
  leads: Array<Lead>;
  legacyAbstracts: Array<LegacyAbstract>;
  legacyConferenceParticipants: Array<LegacyParticipant>;
  legacyConferences: Array<LegacyConference>;
  myParticipant?: Maybe<Participant>;
  myUser?: Maybe<User>;
  nodeSchema?: Maybe<NodeSchema>;
  nodeSchemas: Array<NodeSchema>;
  participant?: Maybe<Participant>;
  participantCount?: Maybe<ParticipantCount>;
  participantCounts: Array<ParticipantCount>;
  participantTag?: Maybe<ParticipantTag>;
  participantTags: Array<ParticipantTag>;
  participants: Array<Participant>;
  participantsDue?: Maybe<ParticipantsDue>;
  participantsDues: Array<ParticipantsDue>;
  payment?: Maybe<Payment>;
  payments: Array<Payment>;
  person?: Maybe<Person>;
  persons: Array<Person>;
  printTemplate?: Maybe<PrintTemplate>;
  printTemplates: Array<PrintTemplate>;
  product?: Maybe<Product>;
  productRegistration?: Maybe<ProductRegistration>;
  productRegistrations: Array<ProductRegistration>;
  products: Array<Product>;
  registration?: Maybe<Registration>;
  registrations: Array<Registration>;
  renderEmail: Scalars['String'];
  report?: Maybe<Report>;
  reports: Array<Report>;
  review?: Maybe<Review>;
  reviewer?: Maybe<Reviewer>;
  reviewers: Array<Reviewer>;
  reviews: Array<Review>;
  selectedProduct?: Maybe<SelectedProduct>;
  selectedProducts: Array<SelectedProduct>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  template?: Maybe<Template>;
  templates: Array<Template>;
  uploadUrl: UploadUrlResponse;
  user?: Maybe<User>;
  users: Array<User>;
  version?: Maybe<Scalars['String']>;
  voucher?: Maybe<Voucher>;
  vouchers: Array<Voucher>;
};


export type QueryAbstractArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  submitterId?: Maybe<Scalars['ID']>;
  idInConference?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  acceptedFor?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['SlateInputJsonType']>;
  title?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AbstractDataJsonType']>;
  abstractGroupId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<AbstractState>;
  submitterData?: Maybe<Scalars['SubmitterDataJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  submitterIdEq?: Maybe<Scalars['ID']>;
  idInConferenceEq?: Maybe<Scalars['Float']>;
  categoryEq?: Maybe<Scalars['String']>;
  acceptedForEq?: Maybe<Scalars['String']>;
  bodyEq?: Maybe<Scalars['SlateInputJsonType']>;
  titleEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['AbstractDataJsonType']>;
  abstractGroupIdEq?: Maybe<Scalars['ID']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  stateEq?: Maybe<AbstractState>;
  submitterDataEq?: Maybe<Scalars['SubmitterDataJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submitterIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idInConferenceIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  categoryIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  acceptedForIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['AbstractDataJsonType']>>>;
  abstractGroupIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  stateIn?: Maybe<Array<Maybe<AbstractState>>>;
  submitterDataIn?: Maybe<Array<Maybe<Scalars['SubmitterDataJsonType']>>>;
  orderBy?: Maybe<AbstractPropertiesEnum>;
  orderByDesc?: Maybe<AbstractPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
  nonArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryAbstractGroupArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['SlateInput1JsonType']>;
  conferenceId?: Maybe<Scalars['ID']>;
  submitterId?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AbstractData1JsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  bodyEq?: Maybe<Scalars['SlateInput1JsonType']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  submitterIdEq?: Maybe<Scalars['ID']>;
  categoryEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['AbstractData1JsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submitterIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categoryIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['AbstractData1JsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<AbstractGroupPropertiesEnum>;
  orderByDesc?: Maybe<AbstractGroupPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryAbstractGroupsArgs = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['SlateInput1JsonType']>;
  conferenceId?: Maybe<Scalars['ID']>;
  submitterId?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AbstractData1JsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  bodyEq?: Maybe<Scalars['SlateInput1JsonType']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  submitterIdEq?: Maybe<Scalars['ID']>;
  categoryEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['AbstractData1JsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submitterIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categoryIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['AbstractData1JsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<AbstractGroupPropertiesEnum>;
  orderByDesc?: Maybe<AbstractGroupPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryAbstractsArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  submitterId?: Maybe<Scalars['ID']>;
  idInConference?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  acceptedFor?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['SlateInputJsonType']>;
  title?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['AbstractDataJsonType']>;
  abstractGroupId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<AbstractState>;
  submitterData?: Maybe<Scalars['SubmitterDataJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  submitterIdEq?: Maybe<Scalars['ID']>;
  idInConferenceEq?: Maybe<Scalars['Float']>;
  categoryEq?: Maybe<Scalars['String']>;
  acceptedForEq?: Maybe<Scalars['String']>;
  bodyEq?: Maybe<Scalars['SlateInputJsonType']>;
  titleEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['AbstractDataJsonType']>;
  abstractGroupIdEq?: Maybe<Scalars['ID']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  stateEq?: Maybe<AbstractState>;
  submitterDataEq?: Maybe<Scalars['SubmitterDataJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submitterIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idInConferenceIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  categoryIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  acceptedForIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['AbstractDataJsonType']>>>;
  abstractGroupIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  stateIn?: Maybe<Array<Maybe<AbstractState>>>;
  submitterDataIn?: Maybe<Array<Maybe<Scalars['SubmitterDataJsonType']>>>;
  orderBy?: Maybe<AbstractPropertiesEnum>;
  orderByDesc?: Maybe<AbstractPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
  nonArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryCalcRegistrationChargesArgs = {
  formId: Scalars['ID'];
  data: Scalars['RegistrationDataJsonType'];
};


export type QueryChargeArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  registrationId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  amount?: Maybe<Scalars['Float']>;
  amountCents?: Maybe<Scalars['Float']>;
  creditCents?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['ID']>;
  type?: Maybe<ChargeType>;
  productType?: Maybe<ProductType>;
  formProductId?: Maybe<Scalars['ID']>;
  selectedProductId?: Maybe<Scalars['ID']>;
  regPeriod?: Maybe<PricingPeriodsId>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  payed?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  registrationIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  currencyEq?: Maybe<Currency>;
  amountEq?: Maybe<Scalars['Float']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  creditCentsEq?: Maybe<Scalars['Float']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  typeEq?: Maybe<ChargeType>;
  productTypeEq?: Maybe<ProductType>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  selectedProductIdEq?: Maybe<Scalars['ID']>;
  regPeriodEq?: Maybe<PricingPeriodsId>;
  paymentChargeAmountEq?: Maybe<Scalars['Float']>;
  dueEq?: Maybe<Scalars['Float']>;
  payedEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  registrationIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  creditCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  typeIn?: Maybe<Array<Maybe<ChargeType>>>;
  productTypeIn?: Maybe<Array<Maybe<ProductType>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  selectedProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  regPeriodIn?: Maybe<Array<Maybe<PricingPeriodsId>>>;
  paymentChargeAmountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  payedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ChargePropertiesEnum>;
  orderByDesc?: Maybe<ChargePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryChargeTitleOptionsArgs = {
  conferenceName: Scalars['String'];
};


export type QueryChargesArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  registrationId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  amount?: Maybe<Scalars['Float']>;
  amountCents?: Maybe<Scalars['Float']>;
  creditCents?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['ID']>;
  type?: Maybe<ChargeType>;
  productType?: Maybe<ProductType>;
  formProductId?: Maybe<Scalars['ID']>;
  selectedProductId?: Maybe<Scalars['ID']>;
  regPeriod?: Maybe<PricingPeriodsId>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  payed?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  registrationIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  currencyEq?: Maybe<Currency>;
  amountEq?: Maybe<Scalars['Float']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  creditCentsEq?: Maybe<Scalars['Float']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  typeEq?: Maybe<ChargeType>;
  productTypeEq?: Maybe<ProductType>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  selectedProductIdEq?: Maybe<Scalars['ID']>;
  regPeriodEq?: Maybe<PricingPeriodsId>;
  paymentChargeAmountEq?: Maybe<Scalars['Float']>;
  dueEq?: Maybe<Scalars['Float']>;
  payedEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  registrationIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  creditCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  typeIn?: Maybe<Array<Maybe<ChargeType>>>;
  productTypeIn?: Maybe<Array<Maybe<ProductType>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  selectedProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  regPeriodIn?: Maybe<Array<Maybe<PricingPeriodsId>>>;
  paymentChargeAmountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  payedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ChargePropertiesEnum>;
  orderByDesc?: Maybe<ChargePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
  conferenceName: Scalars['String'];
};


export type QueryCheckTokenArgs = {
  token: Scalars['String'];
};


export type QueryClientArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['ParticipantSettingsJsonType']>;
  accountBookData?: Maybe<Scalars['AccountBookClientJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  idNumberEq?: Maybe<Scalars['String']>;
  settingsEq?: Maybe<Scalars['ParticipantSettingsJsonType']>;
  accountBookDataEq?: Maybe<Scalars['AccountBookClientJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  idNumberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ParticipantSettingsJsonType']>>>;
  accountBookDataIn?: Maybe<Array<Maybe<Scalars['AccountBookClientJsonType']>>>;
  orderBy?: Maybe<ClientPropertiesEnum>;
  orderByDesc?: Maybe<ClientPropertiesEnum>;
};


export type QueryClientChargeArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  amount?: Maybe<Scalars['Float']>;
  amountCents?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  currencyEq?: Maybe<Currency>;
  amountEq?: Maybe<Scalars['Float']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ClientChargePropertiesEnum>;
  orderByDesc?: Maybe<ClientChargePropertiesEnum>;
};


export type QueryClientChargesArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  amount?: Maybe<Scalars['Float']>;
  amountCents?: Maybe<Scalars['Float']>;
  clientId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  currencyEq?: Maybe<Currency>;
  amountEq?: Maybe<Scalars['Float']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ClientChargePropertiesEnum>;
  orderByDesc?: Maybe<ClientChargePropertiesEnum>;
};


export type QueryClientCreditCardLinkArgs = {
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  conferenceId: Scalars['String'];
  amountCents: Scalars['Int'];
  currency: Currency;
};


export type QueryClientsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['ParticipantSettingsJsonType']>;
  accountBookData?: Maybe<Scalars['AccountBookClientJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  idNumberEq?: Maybe<Scalars['String']>;
  settingsEq?: Maybe<Scalars['ParticipantSettingsJsonType']>;
  accountBookDataEq?: Maybe<Scalars['AccountBookClientJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  idNumberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ParticipantSettingsJsonType']>>>;
  accountBookDataIn?: Maybe<Array<Maybe<Scalars['AccountBookClientJsonType']>>>;
  orderBy?: Maybe<ClientPropertiesEnum>;
  orderByDesc?: Maybe<ClientPropertiesEnum>;
};


export type QueryConferenceArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['ConferenceSettingsJsonType']>;
  scienceSettings?: Maybe<Scalars['ScienceSettingsJsonType']>;
  archived?: Maybe<Scalars['Boolean']>;
  abstractCompletePage?: Maybe<Scalars['AbstractCompletePageJsonType']>;
  participantCount?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  shortNameEq?: Maybe<Scalars['String']>;
  settingsEq?: Maybe<Scalars['ConferenceSettingsJsonType']>;
  scienceSettingsEq?: Maybe<Scalars['ScienceSettingsJsonType']>;
  archivedEq?: Maybe<Scalars['Boolean']>;
  abstractCompletePageEq?: Maybe<Scalars['AbstractCompletePageJsonType']>;
  participantCountEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortNameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ConferenceSettingsJsonType']>>>;
  scienceSettingsIn?: Maybe<Array<Maybe<Scalars['ScienceSettingsJsonType']>>>;
  archivedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  abstractCompletePageIn?: Maybe<Array<Maybe<Scalars['AbstractCompletePageJsonType']>>>;
  participantCountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ConferencePropertiesEnum>;
  orderByDesc?: Maybe<ConferencePropertiesEnum>;
};


export type QueryConferenceUserArgs = {
  conferenceName: Scalars['String'];
};


export type QueryConferencesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['ConferenceSettingsJsonType']>;
  scienceSettings?: Maybe<Scalars['ScienceSettingsJsonType']>;
  archived?: Maybe<Scalars['Boolean']>;
  abstractCompletePage?: Maybe<Scalars['AbstractCompletePageJsonType']>;
  participantCount?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  shortNameEq?: Maybe<Scalars['String']>;
  settingsEq?: Maybe<Scalars['ConferenceSettingsJsonType']>;
  scienceSettingsEq?: Maybe<Scalars['ScienceSettingsJsonType']>;
  archivedEq?: Maybe<Scalars['Boolean']>;
  abstractCompletePageEq?: Maybe<Scalars['AbstractCompletePageJsonType']>;
  participantCountEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortNameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ConferenceSettingsJsonType']>>>;
  scienceSettingsIn?: Maybe<Array<Maybe<Scalars['ScienceSettingsJsonType']>>>;
  archivedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  abstractCompletePageIn?: Maybe<Array<Maybe<Scalars['AbstractCompletePageJsonType']>>>;
  participantCountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ConferencePropertiesEnum>;
  orderByDesc?: Maybe<ConferencePropertiesEnum>;
};


export type QueryCreateCreditGuardUrlArgs = {
  params: CreateCreditGuardUrlInput;
  uniqueId?: Maybe<Scalars['String']>;
};


export type QueryEmailArgs = {
  id?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  emailTemplateId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  toEq?: Maybe<Scalars['String']>;
  fromEq?: Maybe<Scalars['String']>;
  titleEq?: Maybe<Scalars['String']>;
  htmlEq?: Maybe<Scalars['String']>;
  dateEq?: Maybe<Scalars['DateTime']>;
  emailTemplateIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  toIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  fromIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  emailTemplateIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<EmailPropertiesEnum>;
  orderByDesc?: Maybe<EmailPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryEmailTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['SlateInputJsonType']>;
  name?: Maybe<Scalars['String']>;
  emailType?: Maybe<EmailType>;
  meta?: Maybe<Scalars['TemplateMetaJsonType']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  enabledEq?: Maybe<Scalars['Boolean']>;
  titleEq?: Maybe<Scalars['String']>;
  htmlEq?: Maybe<Scalars['SlateInputJsonType']>;
  nameEq?: Maybe<Scalars['String']>;
  emailTypeEq?: Maybe<EmailType>;
  metaEq?: Maybe<Scalars['TemplateMetaJsonType']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  enabledIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailTypeIn?: Maybe<Array<Maybe<EmailType>>>;
  metaIn?: Maybe<Array<Maybe<Scalars['TemplateMetaJsonType']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<EmailTemplatePropertiesEnum>;
  orderByDesc?: Maybe<EmailTemplatePropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryEmailTemplatesArgs = {
  id?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['SlateInputJsonType']>;
  name?: Maybe<Scalars['String']>;
  emailType?: Maybe<EmailType>;
  meta?: Maybe<Scalars['TemplateMetaJsonType']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  enabledEq?: Maybe<Scalars['Boolean']>;
  titleEq?: Maybe<Scalars['String']>;
  htmlEq?: Maybe<Scalars['SlateInputJsonType']>;
  nameEq?: Maybe<Scalars['String']>;
  emailTypeEq?: Maybe<EmailType>;
  metaEq?: Maybe<Scalars['TemplateMetaJsonType']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  enabledIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailTypeIn?: Maybe<Array<Maybe<EmailType>>>;
  metaIn?: Maybe<Array<Maybe<Scalars['TemplateMetaJsonType']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<EmailTemplatePropertiesEnum>;
  orderByDesc?: Maybe<EmailTemplatePropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryEmailsArgs = {
  id?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  emailTemplateId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  toEq?: Maybe<Scalars['String']>;
  fromEq?: Maybe<Scalars['String']>;
  titleEq?: Maybe<Scalars['String']>;
  htmlEq?: Maybe<Scalars['String']>;
  dateEq?: Maybe<Scalars['DateTime']>;
  emailTemplateIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  toIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  fromIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  emailTemplateIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<EmailPropertiesEnum>;
  orderByDesc?: Maybe<EmailPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  formSchema?: Maybe<Scalars['FormSchemaJsonType']>;
  settings?: Maybe<Scalars['FormSettingsJsonType']>;
  cancelPolicy?: Maybe<Scalars['SlateInput1JsonType']>;
  completePage?: Maybe<Scalars['SlateInput1JsonType']>;
  order?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  shortNameEq?: Maybe<Scalars['String']>;
  formSchemaEq?: Maybe<Scalars['FormSchemaJsonType']>;
  settingsEq?: Maybe<Scalars['FormSettingsJsonType']>;
  cancelPolicyEq?: Maybe<Scalars['SlateInput1JsonType']>;
  completePageEq?: Maybe<Scalars['SlateInput1JsonType']>;
  orderEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortNameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  formSchemaIn?: Maybe<Array<Maybe<Scalars['FormSchemaJsonType']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['FormSettingsJsonType']>>>;
  cancelPolicyIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  completePageIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormPropertiesEnum>;
  orderByDesc?: Maybe<FormPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormFieldArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  displayType?: Maybe<FieldDisplayType>;
  fieldType?: Maybe<FormFieldFieldType>;
  fieldSource?: Maybe<FieldSource>;
  order?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  keyEq?: Maybe<Scalars['String']>;
  labelEq?: Maybe<Scalars['String']>;
  displayTypeEq?: Maybe<FieldDisplayType>;
  fieldTypeEq?: Maybe<FormFieldFieldType>;
  fieldSourceEq?: Maybe<FieldSource>;
  orderEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  keyIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  displayTypeIn?: Maybe<Array<Maybe<FieldDisplayType>>>;
  fieldTypeIn?: Maybe<Array<Maybe<FormFieldFieldType>>>;
  fieldSourceIn?: Maybe<Array<Maybe<FieldSource>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormFieldPropertiesEnum>;
  orderByDesc?: Maybe<FormFieldPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormFieldsArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  displayType?: Maybe<FieldDisplayType>;
  fieldType?: Maybe<FormFieldFieldType>;
  fieldSource?: Maybe<FieldSource>;
  order?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  keyEq?: Maybe<Scalars['String']>;
  labelEq?: Maybe<Scalars['String']>;
  displayTypeEq?: Maybe<FieldDisplayType>;
  fieldTypeEq?: Maybe<FormFieldFieldType>;
  fieldSourceEq?: Maybe<FieldSource>;
  orderEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  keyIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  displayTypeIn?: Maybe<Array<Maybe<FieldDisplayType>>>;
  fieldTypeIn?: Maybe<Array<Maybe<FormFieldFieldType>>>;
  fieldSourceIn?: Maybe<Array<Maybe<FieldSource>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormFieldPropertiesEnum>;
  orderByDesc?: Maybe<FormFieldPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormProductArgs = {
  id?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prices?: Maybe<Scalars['PricesJsonType']>;
  type?: Maybe<ProductType>;
  data?: Maybe<Scalars['FormProductDataJsonType']>;
  fields?: Maybe<Scalars['FormProductFieldsJsonType']>;
  hasPartner?: Maybe<Scalars['Boolean']>;
  ord?: Maybe<Scalars['Float']>;
  modifierOnly?: Maybe<Scalars['Boolean']>;
  state?: Maybe<FormProductState>;
  participantCount?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  descriptionEq?: Maybe<Scalars['String']>;
  pricesEq?: Maybe<Scalars['PricesJsonType']>;
  typeEq?: Maybe<ProductType>;
  dataEq?: Maybe<Scalars['FormProductDataJsonType']>;
  fieldsEq?: Maybe<Scalars['FormProductFieldsJsonType']>;
  hasPartnerEq?: Maybe<Scalars['Boolean']>;
  ordEq?: Maybe<Scalars['Float']>;
  modifierOnlyEq?: Maybe<Scalars['Boolean']>;
  stateEq?: Maybe<FormProductState>;
  participantCountEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricesIn?: Maybe<Array<Maybe<Scalars['PricesJsonType']>>>;
  typeIn?: Maybe<Array<Maybe<ProductType>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['FormProductDataJsonType']>>>;
  fieldsIn?: Maybe<Array<Maybe<Scalars['FormProductFieldsJsonType']>>>;
  hasPartnerIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  ordIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  modifierOnlyIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stateIn?: Maybe<Array<Maybe<FormProductState>>>;
  participantCountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormProductPropertiesEnum>;
  orderByDesc?: Maybe<FormProductPropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormProductProductArgs = {
  id?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  registrationData?: Maybe<Scalars['RecordStringUnknownJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  productIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  registrationDataEq?: Maybe<Scalars['RecordStringUnknownJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  registrationDataIn?: Maybe<Array<Maybe<Scalars['RecordStringUnknownJsonType']>>>;
  orderBy?: Maybe<FormProductProductPropertiesEnum>;
  orderByDesc?: Maybe<FormProductProductPropertiesEnum>;
};


export type QueryFormProductProductsArgs = {
  id?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  registrationData?: Maybe<Scalars['RecordStringUnknownJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  productIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  registrationDataEq?: Maybe<Scalars['RecordStringUnknownJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  registrationDataIn?: Maybe<Array<Maybe<Scalars['RecordStringUnknownJsonType']>>>;
  orderBy?: Maybe<FormProductProductPropertiesEnum>;
  orderByDesc?: Maybe<FormProductProductPropertiesEnum>;
};


export type QueryFormProductsArgs = {
  id?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prices?: Maybe<Scalars['PricesJsonType']>;
  type?: Maybe<ProductType>;
  data?: Maybe<Scalars['FormProductDataJsonType']>;
  fields?: Maybe<Scalars['FormProductFieldsJsonType']>;
  hasPartner?: Maybe<Scalars['Boolean']>;
  ord?: Maybe<Scalars['Float']>;
  modifierOnly?: Maybe<Scalars['Boolean']>;
  state?: Maybe<FormProductState>;
  participantCount?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  titleEq?: Maybe<Scalars['String']>;
  descriptionEq?: Maybe<Scalars['String']>;
  pricesEq?: Maybe<Scalars['PricesJsonType']>;
  typeEq?: Maybe<ProductType>;
  dataEq?: Maybe<Scalars['FormProductDataJsonType']>;
  fieldsEq?: Maybe<Scalars['FormProductFieldsJsonType']>;
  hasPartnerEq?: Maybe<Scalars['Boolean']>;
  ordEq?: Maybe<Scalars['Float']>;
  modifierOnlyEq?: Maybe<Scalars['Boolean']>;
  stateEq?: Maybe<FormProductState>;
  participantCountEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricesIn?: Maybe<Array<Maybe<Scalars['PricesJsonType']>>>;
  typeIn?: Maybe<Array<Maybe<ProductType>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['FormProductDataJsonType']>>>;
  fieldsIn?: Maybe<Array<Maybe<Scalars['FormProductFieldsJsonType']>>>;
  hasPartnerIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  ordIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  modifierOnlyIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  stateIn?: Maybe<Array<Maybe<FormProductState>>>;
  participantCountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormProductPropertiesEnum>;
  orderByDesc?: Maybe<FormProductPropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryFormsArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  formSchema?: Maybe<Scalars['FormSchemaJsonType']>;
  settings?: Maybe<Scalars['FormSettingsJsonType']>;
  cancelPolicy?: Maybe<Scalars['SlateInput1JsonType']>;
  completePage?: Maybe<Scalars['SlateInput1JsonType']>;
  order?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  shortNameEq?: Maybe<Scalars['String']>;
  formSchemaEq?: Maybe<Scalars['FormSchemaJsonType']>;
  settingsEq?: Maybe<Scalars['FormSettingsJsonType']>;
  cancelPolicyEq?: Maybe<Scalars['SlateInput1JsonType']>;
  completePageEq?: Maybe<Scalars['SlateInput1JsonType']>;
  orderEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortNameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  formSchemaIn?: Maybe<Array<Maybe<Scalars['FormSchemaJsonType']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['FormSettingsJsonType']>>>;
  cancelPolicyIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  completePageIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<FormPropertiesEnum>;
  orderByDesc?: Maybe<FormPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryHotelArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<HotelPropertiesEnum>;
  orderByDesc?: Maybe<HotelPropertiesEnum>;
};


export type QueryHotelsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<HotelPropertiesEnum>;
  orderByDesc?: Maybe<HotelPropertiesEnum>;
};


export type QueryInvoiceArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  invoiceType?: Maybe<InvoiceType>;
  number?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  amountCents?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  htmlUrl?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  invoiceTypeEq?: Maybe<InvoiceType>;
  numberEq?: Maybe<Scalars['String']>;
  responseEq?: Maybe<Scalars['String']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  currencyEq?: Maybe<Scalars['String']>;
  pdfUrlEq?: Maybe<Scalars['String']>;
  htmlUrlEq?: Maybe<Scalars['String']>;
  dateEq?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invoiceTypeIn?: Maybe<Array<Maybe<InvoiceType>>>;
  numberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  responseIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currencyIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfUrlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlUrlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<InvoicePropertiesEnum>;
  orderByDesc?: Maybe<InvoicePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryInvoicesArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  invoiceType?: Maybe<InvoiceType>;
  number?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  amountCents?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  htmlUrl?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  invoiceTypeEq?: Maybe<InvoiceType>;
  numberEq?: Maybe<Scalars['String']>;
  responseEq?: Maybe<Scalars['String']>;
  amountCentsEq?: Maybe<Scalars['Float']>;
  currencyEq?: Maybe<Scalars['String']>;
  pdfUrlEq?: Maybe<Scalars['String']>;
  htmlUrlEq?: Maybe<Scalars['String']>;
  dateEq?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invoiceTypeIn?: Maybe<Array<Maybe<InvoiceType>>>;
  numberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  responseIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountCentsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currencyIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfUrlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  htmlUrlIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<InvoicePropertiesEnum>;
  orderByDesc?: Maybe<InvoicePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryLeadArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['RegistrationDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conferenceId?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['RegistrationDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['RegistrationDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<LeadPropertiesEnum>;
  orderByDesc?: Maybe<LeadPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryLeadPaymentArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['Record8JsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  leadId?: Maybe<Scalars['ID']>;
  failureType?: Maybe<FailureType>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['Record8JsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  leadIdEq?: Maybe<Scalars['ID']>;
  failureTypeEq?: Maybe<FailureType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['Record8JsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  leadIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  failureTypeIn?: Maybe<Array<Maybe<FailureType>>>;
  orderBy?: Maybe<LeadPaymentPropertiesEnum>;
  orderByDesc?: Maybe<LeadPaymentPropertiesEnum>;
};


export type QueryLeadPaymentsArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['Record8JsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  leadId?: Maybe<Scalars['ID']>;
  failureType?: Maybe<FailureType>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['Record8JsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  leadIdEq?: Maybe<Scalars['ID']>;
  failureTypeEq?: Maybe<FailureType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['Record8JsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  leadIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  failureTypeIn?: Maybe<Array<Maybe<FailureType>>>;
  orderBy?: Maybe<LeadPaymentPropertiesEnum>;
  orderByDesc?: Maybe<LeadPaymentPropertiesEnum>;
};


export type QueryLeadsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['RegistrationDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  conferenceId?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['RegistrationDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  updatedAtEq?: Maybe<Scalars['DateTime']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  formIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['RegistrationDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  updatedAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<LeadPropertiesEnum>;
  orderByDesc?: Maybe<LeadPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryLegacyAbstractsArgs = {
  conferenceId: Scalars['String'];
};


export type QueryLegacyConferenceParticipantsArgs = {
  conferenceId: Scalars['String'];
};


export type QueryMyParticipantArgs = {
  conferenceName: Scalars['String'];
};


export type QueryNodeSchemaArgs = {
  id?: Maybe<Scalars['ID']>;
  schema?: Maybe<Scalars['FormSchemaJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  schemaEq?: Maybe<Scalars['FormSchemaJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  schemaIn?: Maybe<Array<Maybe<Scalars['FormSchemaJsonType']>>>;
  orderBy?: Maybe<NodeSchemaPropertiesEnum>;
  orderByDesc?: Maybe<NodeSchemaPropertiesEnum>;
};


export type QueryNodeSchemasArgs = {
  id?: Maybe<Scalars['ID']>;
  schema?: Maybe<Scalars['FormSchemaJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  schemaEq?: Maybe<Scalars['FormSchemaJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  schemaIn?: Maybe<Array<Maybe<Scalars['FormSchemaJsonType']>>>;
  orderBy?: Maybe<NodeSchemaPropertiesEnum>;
  orderByDesc?: Maybe<NodeSchemaPropertiesEnum>;
};


export type QueryParticipantArgs = {
  id?: Maybe<Scalars['ID']>;
  idInConference?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  oneTimeToken?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['ParticipantRolesJsonType']>;
  superReviewer?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['SlateInput1JsonType']>;
  settings?: Maybe<Scalars['ParticipantSettings1JsonType']>;
  data?: Maybe<Scalars['RegistrationData1JsonType']>;
  dataOverrides?: Maybe<Scalars['PartialRegistrationDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conferenceId?: Maybe<Scalars['ID']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  due?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  idInConferenceEq?: Maybe<Scalars['Float']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  passwordHashEq?: Maybe<Scalars['String']>;
  oneTimeTokenEq?: Maybe<Scalars['String']>;
  rolesEq?: Maybe<Scalars['ParticipantRolesJsonType']>;
  superReviewerEq?: Maybe<Scalars['Boolean']>;
  notesEq?: Maybe<Scalars['SlateInput1JsonType']>;
  settingsEq?: Maybe<Scalars['ParticipantSettings1JsonType']>;
  dataEq?: Maybe<Scalars['RegistrationData1JsonType']>;
  dataOverridesEq?: Maybe<Scalars['PartialRegistrationDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  hasPasswordEq?: Maybe<Scalars['Boolean']>;
  dueEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idInConferenceIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  passwordHashIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  oneTimeTokenIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  rolesIn?: Maybe<Array<Maybe<Scalars['ParticipantRolesJsonType']>>>;
  superReviewerIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notesIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ParticipantSettings1JsonType']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['RegistrationData1JsonType']>>>;
  dataOverridesIn?: Maybe<Array<Maybe<Scalars['PartialRegistrationDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hasPasswordIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryParticipantCountArgs = {
  id?: Maybe<Scalars['ID']>;
  submittedAbstracts?: Maybe<Scalars['Float']>;
  reviewsAssigned?: Maybe<Scalars['Float']>;
  reviewsCompleted?: Maybe<Scalars['Float']>;
  totalCharges?: Maybe<Scalars['Float']>;
  totalParticipantCharges?: Maybe<Scalars['Float']>;
  totalPayed?: Maybe<Scalars['Float']>;
  totalDue?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  submittedAbstractsEq?: Maybe<Scalars['Float']>;
  reviewsAssignedEq?: Maybe<Scalars['Float']>;
  reviewsCompletedEq?: Maybe<Scalars['Float']>;
  totalChargesEq?: Maybe<Scalars['Float']>;
  totalParticipantChargesEq?: Maybe<Scalars['Float']>;
  totalPayedEq?: Maybe<Scalars['Float']>;
  totalDueEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submittedAbstractsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  reviewsAssignedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  reviewsCompletedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalChargesIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalParticipantChargesIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalPayedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalDueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantCountPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantCountPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryParticipantCountsArgs = {
  id?: Maybe<Scalars['ID']>;
  submittedAbstracts?: Maybe<Scalars['Float']>;
  reviewsAssigned?: Maybe<Scalars['Float']>;
  reviewsCompleted?: Maybe<Scalars['Float']>;
  totalCharges?: Maybe<Scalars['Float']>;
  totalParticipantCharges?: Maybe<Scalars['Float']>;
  totalPayed?: Maybe<Scalars['Float']>;
  totalDue?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  submittedAbstractsEq?: Maybe<Scalars['Float']>;
  reviewsAssignedEq?: Maybe<Scalars['Float']>;
  reviewsCompletedEq?: Maybe<Scalars['Float']>;
  totalChargesEq?: Maybe<Scalars['Float']>;
  totalParticipantChargesEq?: Maybe<Scalars['Float']>;
  totalPayedEq?: Maybe<Scalars['Float']>;
  totalDueEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  submittedAbstractsIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  reviewsAssignedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  reviewsCompletedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalChargesIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalParticipantChargesIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalPayedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalDueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantCountPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantCountPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryParticipantTagArgs = {
  participantId?: Maybe<Scalars['ID']>;
  tagId?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  tagIdEq?: Maybe<Scalars['ID']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tagIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ParticipantTagPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantTagPropertiesEnum>;
};


export type QueryParticipantTagsArgs = {
  participantId?: Maybe<Scalars['ID']>;
  tagId?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  tagIdEq?: Maybe<Scalars['ID']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tagIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ParticipantTagPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantTagPropertiesEnum>;
};


export type QueryParticipantsArgs = {
  id?: Maybe<Scalars['ID']>;
  idInConference?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  oneTimeToken?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['ParticipantRolesJsonType']>;
  superReviewer?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['SlateInput1JsonType']>;
  settings?: Maybe<Scalars['ParticipantSettings1JsonType']>;
  data?: Maybe<Scalars['RegistrationData1JsonType']>;
  dataOverrides?: Maybe<Scalars['PartialRegistrationDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  conferenceId?: Maybe<Scalars['ID']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  due?: Maybe<Scalars['Float']>;
  idEq?: Maybe<Scalars['ID']>;
  idInConferenceEq?: Maybe<Scalars['Float']>;
  nameEq?: Maybe<Scalars['String']>;
  emailEq?: Maybe<Scalars['String']>;
  passwordHashEq?: Maybe<Scalars['String']>;
  oneTimeTokenEq?: Maybe<Scalars['String']>;
  rolesEq?: Maybe<Scalars['ParticipantRolesJsonType']>;
  superReviewerEq?: Maybe<Scalars['Boolean']>;
  notesEq?: Maybe<Scalars['SlateInput1JsonType']>;
  settingsEq?: Maybe<Scalars['ParticipantSettings1JsonType']>;
  dataEq?: Maybe<Scalars['RegistrationData1JsonType']>;
  dataOverridesEq?: Maybe<Scalars['PartialRegistrationDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  hasPasswordEq?: Maybe<Scalars['Boolean']>;
  dueEq?: Maybe<Scalars['Float']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idInConferenceIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  passwordHashIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  oneTimeTokenIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  rolesIn?: Maybe<Array<Maybe<Scalars['ParticipantRolesJsonType']>>>;
  superReviewerIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notesIn?: Maybe<Array<Maybe<Scalars['SlateInput1JsonType']>>>;
  settingsIn?: Maybe<Array<Maybe<Scalars['ParticipantSettings1JsonType']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['RegistrationData1JsonType']>>>;
  dataOverridesIn?: Maybe<Array<Maybe<Scalars['PartialRegistrationDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hasPasswordIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantPropertiesEnum>;
  orderByDesc?: Maybe<ParticipantPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryParticipantsDueArgs = {
  participantId?: Maybe<Scalars['ID']>;
  payed?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  payedEq?: Maybe<Scalars['Float']>;
  dueEq?: Maybe<Scalars['Float']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  payedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantsDuePropertiesEnum>;
  orderByDesc?: Maybe<ParticipantsDuePropertiesEnum>;
};


export type QueryParticipantsDuesArgs = {
  participantId?: Maybe<Scalars['ID']>;
  payed?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  payedEq?: Maybe<Scalars['Float']>;
  dueEq?: Maybe<Scalars['Float']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  payedIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dueIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<ParticipantsDuePropertiesEnum>;
  orderByDesc?: Maybe<ParticipantsDuePropertiesEnum>;
};


export type QueryPaymentArgs = {
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  paymentType?: Maybe<PaymentType>;
  paymentState?: Maybe<PaymentState>;
  data?: Maybe<Scalars['PaymentDataJsonType']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  paymentTypeEq?: Maybe<PaymentType>;
  paymentStateEq?: Maybe<PaymentState>;
  dataEq?: Maybe<Scalars['PaymentDataJsonType']>;
  amountEq?: Maybe<Scalars['Float']>;
  currencyEq?: Maybe<Currency>;
  dateEq?: Maybe<Scalars['DateTime']>;
  paymentChargeAmountEq?: Maybe<Scalars['Float']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paymentTypeIn?: Maybe<Array<Maybe<PaymentType>>>;
  paymentStateIn?: Maybe<Array<Maybe<PaymentState>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['PaymentDataJsonType']>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  paymentChargeAmountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<PaymentPropertiesEnum>;
  orderByDesc?: Maybe<PaymentPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryPaymentsArgs = {
  participantId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  paymentType?: Maybe<PaymentType>;
  paymentState?: Maybe<PaymentState>;
  data?: Maybe<Scalars['PaymentDataJsonType']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  paymentChargeAmount?: Maybe<Scalars['Float']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  clientIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  paymentTypeEq?: Maybe<PaymentType>;
  paymentStateEq?: Maybe<PaymentState>;
  dataEq?: Maybe<Scalars['PaymentDataJsonType']>;
  amountEq?: Maybe<Scalars['Float']>;
  currencyEq?: Maybe<Currency>;
  dateEq?: Maybe<Scalars['DateTime']>;
  paymentChargeAmountEq?: Maybe<Scalars['Float']>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paymentTypeIn?: Maybe<Array<Maybe<PaymentType>>>;
  paymentStateIn?: Maybe<Array<Maybe<PaymentState>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['PaymentDataJsonType']>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currencyIn?: Maybe<Array<Maybe<Currency>>>;
  dateIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  paymentChargeAmountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderBy?: Maybe<PaymentPropertiesEnum>;
  orderByDesc?: Maybe<PaymentPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryPersonArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  personType?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['PersonData1JsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  personTypeEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['PersonData1JsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  personTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['PersonData1JsonType']>>>;
  orderBy?: Maybe<PersonPropertiesEnum>;
  orderByDesc?: Maybe<PersonPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryPersonsArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  personType?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['PersonData1JsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  personTypeEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['PersonData1JsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  personTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['PersonData1JsonType']>>>;
  orderBy?: Maybe<PersonPropertiesEnum>;
  orderByDesc?: Maybe<PersonPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryPrintTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  custom?: Maybe<Scalars['Boolean']>;
  regularData?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  customData?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  templateType?: Maybe<PrintTemplateType>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  customEq?: Maybe<Scalars['Boolean']>;
  regularDataEq?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  customDataEq?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  templateTypeEq?: Maybe<PrintTemplateType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  regularDataIn?: Maybe<Array<Maybe<Scalars['RegularPrintFormDataJsonType']>>>;
  customDataIn?: Maybe<Array<Maybe<Scalars['PrintTemplateCustomDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  templateTypeIn?: Maybe<Array<Maybe<PrintTemplateType>>>;
  orderBy?: Maybe<PrintTemplatePropertiesEnum>;
  orderByDesc?: Maybe<PrintTemplatePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryPrintTemplatesArgs = {
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  custom?: Maybe<Scalars['Boolean']>;
  regularData?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  customData?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  templateType?: Maybe<PrintTemplateType>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  customEq?: Maybe<Scalars['Boolean']>;
  regularDataEq?: Maybe<Scalars['RegularPrintFormDataJsonType']>;
  customDataEq?: Maybe<Scalars['PrintTemplateCustomDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  templateTypeEq?: Maybe<PrintTemplateType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  regularDataIn?: Maybe<Array<Maybe<Scalars['RegularPrintFormDataJsonType']>>>;
  customDataIn?: Maybe<Array<Maybe<Scalars['PrintTemplateCustomDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  templateTypeIn?: Maybe<Array<Maybe<PrintTemplateType>>>;
  orderBy?: Maybe<PrintTemplatePropertiesEnum>;
  orderByDesc?: Maybe<PrintTemplatePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryProductArgs = {
  available?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  productType?: Maybe<ProductType>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  count?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['ProductDataJsonType']>;
  hotelId?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['MetaJsonType']>;
  availableEq?: Maybe<Scalars['Boolean']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  productTypeEq?: Maybe<ProductType>;
  titleEq?: Maybe<Scalars['String']>;
  startDateEq?: Maybe<Scalars['String']>;
  startTimeEq?: Maybe<Scalars['String']>;
  endDateEq?: Maybe<Scalars['String']>;
  endTimeEq?: Maybe<Scalars['String']>;
  descriptionEq?: Maybe<Scalars['String']>;
  parentIdEq?: Maybe<Scalars['ID']>;
  countEq?: Maybe<Scalars['Float']>;
  orderEq?: Maybe<Scalars['Float']>;
  updatedEq?: Maybe<Scalars['DateTime']>;
  dataEq?: Maybe<Scalars['ProductDataJsonType']>;
  hotelIdEq?: Maybe<Scalars['ID']>;
  metaEq?: Maybe<Scalars['MetaJsonType']>;
  availableIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productTypeIn?: Maybe<Array<Maybe<ProductType>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTimeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  endDateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  endTimeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  updatedIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ProductDataJsonType']>>>;
  hotelIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  metaIn?: Maybe<Array<Maybe<Scalars['MetaJsonType']>>>;
  orderBy?: Maybe<ProductPropertiesEnum>;
  orderByDesc?: Maybe<ProductPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryProductRegistrationArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['ProductRegistrationDataJsonType']>;
  parentId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  selectedProductId?: Maybe<Scalars['ID']>;
  shareGroupId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  productIdEq?: Maybe<Scalars['ID']>;
  amountEq?: Maybe<Scalars['Float']>;
  dataEq?: Maybe<Scalars['ProductRegistrationDataJsonType']>;
  parentIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  notesEq?: Maybe<Scalars['String']>;
  selectedProductIdEq?: Maybe<Scalars['ID']>;
  shareGroupIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ProductRegistrationDataJsonType']>>>;
  parentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notesIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shareGroupIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
  orderByDesc?: Maybe<ProductRegistrationPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryProductRegistrationsArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['ProductRegistrationDataJsonType']>;
  parentId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  selectedProductId?: Maybe<Scalars['ID']>;
  shareGroupId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  productIdEq?: Maybe<Scalars['ID']>;
  amountEq?: Maybe<Scalars['Float']>;
  dataEq?: Maybe<Scalars['ProductRegistrationDataJsonType']>;
  parentIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  notesEq?: Maybe<Scalars['String']>;
  selectedProductIdEq?: Maybe<Scalars['ID']>;
  shareGroupIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amountIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ProductRegistrationDataJsonType']>>>;
  parentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notesIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shareGroupIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
  orderByDesc?: Maybe<ProductRegistrationPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  available?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  productType?: Maybe<ProductType>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  count?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['ProductDataJsonType']>;
  hotelId?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['MetaJsonType']>;
  availableEq?: Maybe<Scalars['Boolean']>;
  idEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  productTypeEq?: Maybe<ProductType>;
  titleEq?: Maybe<Scalars['String']>;
  startDateEq?: Maybe<Scalars['String']>;
  startTimeEq?: Maybe<Scalars['String']>;
  endDateEq?: Maybe<Scalars['String']>;
  endTimeEq?: Maybe<Scalars['String']>;
  descriptionEq?: Maybe<Scalars['String']>;
  parentIdEq?: Maybe<Scalars['ID']>;
  countEq?: Maybe<Scalars['Float']>;
  orderEq?: Maybe<Scalars['Float']>;
  updatedEq?: Maybe<Scalars['DateTime']>;
  dataEq?: Maybe<Scalars['ProductDataJsonType']>;
  hotelIdEq?: Maybe<Scalars['ID']>;
  metaEq?: Maybe<Scalars['MetaJsonType']>;
  availableIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productTypeIn?: Maybe<Array<Maybe<ProductType>>>;
  titleIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTimeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  endDateIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  endTimeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  orderIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  updatedIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ProductDataJsonType']>>>;
  hotelIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  metaIn?: Maybe<Array<Maybe<Scalars['MetaJsonType']>>>;
  orderBy?: Maybe<ProductPropertiesEnum>;
  orderByDesc?: Maybe<ProductPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryRegistrationArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['Partial2JsonType']>;
  formId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['Partial2JsonType']>;
  formIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['Partial2JsonType']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<RegistrationPropertiesEnum>;
  orderByDesc?: Maybe<RegistrationPropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryRegistrationsArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['Partial2JsonType']>;
  formId?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['Partial2JsonType']>;
  formIdEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['Partial2JsonType']>>>;
  formIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<RegistrationPropertiesEnum>;
  orderByDesc?: Maybe<RegistrationPropertiesEnum>;
  formName?: Maybe<Scalars['String']>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryRenderEmailArgs = {
  content: Scalars['String'];
  language: Language;
  participantId: Scalars['ID'];
};


export type QueryReportArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['ReportDataJsonType']>;
  type?: Maybe<ReportType>;
  userDefined?: Maybe<Scalars['Boolean']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['ReportDataJsonType']>;
  typeEq?: Maybe<ReportType>;
  userDefinedEq?: Maybe<Scalars['Boolean']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ReportDataJsonType']>>>;
  typeIn?: Maybe<Array<Maybe<ReportType>>>;
  userDefinedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  orderBy?: Maybe<ReportPropertiesEnum>;
  orderByDesc?: Maybe<ReportPropertiesEnum>;
};


export type QueryReportsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['ReportDataJsonType']>;
  type?: Maybe<ReportType>;
  userDefined?: Maybe<Scalars['Boolean']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  dataEq?: Maybe<Scalars['ReportDataJsonType']>;
  typeEq?: Maybe<ReportType>;
  userDefinedEq?: Maybe<Scalars['Boolean']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ReportDataJsonType']>>>;
  typeIn?: Maybe<Array<Maybe<ReportType>>>;
  userDefinedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  orderBy?: Maybe<ReportPropertiesEnum>;
  orderByDesc?: Maybe<ReportPropertiesEnum>;
};


export type QueryReviewArgs = {
  id?: Maybe<Scalars['ID']>;
  abstractId?: Maybe<Scalars['ID']>;
  reviewerId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['ReviewDataJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  abstractIdEq?: Maybe<Scalars['ID']>;
  reviewerIdEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['ReviewDataJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  abstractIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reviewerIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ReviewDataJsonType']>>>;
  orderBy?: Maybe<ReviewPropertiesEnum>;
  orderByDesc?: Maybe<ReviewPropertiesEnum>;
};


export type QueryReviewerArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ReviewerPropertiesEnum>;
  orderByDesc?: Maybe<ReviewerPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryReviewersArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<ReviewerPropertiesEnum>;
  orderByDesc?: Maybe<ReviewerPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryReviewsArgs = {
  id?: Maybe<Scalars['ID']>;
  abstractId?: Maybe<Scalars['ID']>;
  reviewerId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['ReviewDataJsonType']>;
  idEq?: Maybe<Scalars['ID']>;
  abstractIdEq?: Maybe<Scalars['ID']>;
  reviewerIdEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['ReviewDataJsonType']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  abstractIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reviewerIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['ReviewDataJsonType']>>>;
  orderBy?: Maybe<ReviewPropertiesEnum>;
  orderByDesc?: Maybe<ReviewPropertiesEnum>;
};


export type QuerySelectedProductArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<SelectedProductPropertiesEnum>;
  orderByDesc?: Maybe<SelectedProductPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QuerySelectedProductsArgs = {
  id?: Maybe<Scalars['ID']>;
  participantId?: Maybe<Scalars['ID']>;
  conferenceId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  participantIdEq?: Maybe<Scalars['ID']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  formProductIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participantIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  formProductIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<SelectedProductPropertiesEnum>;
  orderByDesc?: Maybe<SelectedProductPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  conferenceId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  colorEq?: Maybe<Scalars['String']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<TagPropertiesEnum>;
  orderByDesc?: Maybe<TagPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryTagsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  conferenceId?: Maybe<Scalars['ID']>;
  idEq?: Maybe<Scalars['ID']>;
  nameEq?: Maybe<Scalars['String']>;
  colorEq?: Maybe<Scalars['String']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<TagPropertiesEnum>;
  orderByDesc?: Maybe<TagPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['SlateInputJsonType']>;
  conferenceId?: Maybe<Scalars['ID']>;
  templateType?: Maybe<TemplateType>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['SlateInputJsonType']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  templateTypeEq?: Maybe<TemplateType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  templateTypeIn?: Maybe<Array<Maybe<TemplateType>>>;
  orderBy?: Maybe<TemplatePropertiesEnum>;
  orderByDesc?: Maybe<TemplatePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryTemplatesArgs = {
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['SlateInputJsonType']>;
  conferenceId?: Maybe<Scalars['ID']>;
  templateType?: Maybe<TemplateType>;
  idEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['SlateInputJsonType']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  templateTypeEq?: Maybe<TemplateType>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['SlateInputJsonType']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  templateTypeIn?: Maybe<Array<Maybe<TemplateType>>>;
  orderBy?: Maybe<TemplatePropertiesEnum>;
  orderByDesc?: Maybe<TemplatePropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryUploadUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['ID']>;
  blocked?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Scalars['UserPermissionsJsonType']>;
  data?: Maybe<Scalars['UserDataJsonType']>;
  userType?: Maybe<UserType>;
  lastAccessed?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  emailEq?: Maybe<Scalars['String']>;
  nameEq?: Maybe<Scalars['String']>;
  googleIdEq?: Maybe<Scalars['ID']>;
  blockedEq?: Maybe<Scalars['Boolean']>;
  permissionsEq?: Maybe<Scalars['UserPermissionsJsonType']>;
  dataEq?: Maybe<Scalars['UserDataJsonType']>;
  userTypeEq?: Maybe<UserType>;
  lastAccessedEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  googleIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  blockedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  permissionsIn?: Maybe<Array<Maybe<Scalars['UserPermissionsJsonType']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['UserDataJsonType']>>>;
  userTypeIn?: Maybe<Array<Maybe<UserType>>>;
  lastAccessedIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<UserPropertiesEnum>;
  orderByDesc?: Maybe<UserPropertiesEnum>;
};


export type QueryUsersArgs = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['ID']>;
  blocked?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Scalars['UserPermissionsJsonType']>;
  data?: Maybe<Scalars['UserDataJsonType']>;
  userType?: Maybe<UserType>;
  lastAccessed?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  emailEq?: Maybe<Scalars['String']>;
  nameEq?: Maybe<Scalars['String']>;
  googleIdEq?: Maybe<Scalars['ID']>;
  blockedEq?: Maybe<Scalars['Boolean']>;
  permissionsEq?: Maybe<Scalars['UserPermissionsJsonType']>;
  dataEq?: Maybe<Scalars['UserDataJsonType']>;
  userTypeEq?: Maybe<UserType>;
  lastAccessedEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  googleIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  blockedIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  permissionsIn?: Maybe<Array<Maybe<Scalars['UserPermissionsJsonType']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['UserDataJsonType']>>>;
  userTypeIn?: Maybe<Array<Maybe<UserType>>>;
  lastAccessedIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<UserPropertiesEnum>;
  orderByDesc?: Maybe<UserPropertiesEnum>;
};


export type QueryVoucherArgs = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  conferenceId?: Maybe<Scalars['ID']>;
  createdById?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['VoucherDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  numberEq?: Maybe<Scalars['String']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  createdByIdEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['VoucherDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  numberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdByIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['VoucherDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<VoucherPropertiesEnum>;
  orderByDesc?: Maybe<VoucherPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};


export type QueryVouchersArgs = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  conferenceId?: Maybe<Scalars['ID']>;
  createdById?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['VoucherDataJsonType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  numberEq?: Maybe<Scalars['String']>;
  conferenceIdEq?: Maybe<Scalars['ID']>;
  createdByIdEq?: Maybe<Scalars['ID']>;
  dataEq?: Maybe<Scalars['VoucherDataJsonType']>;
  createdAtEq?: Maybe<Scalars['DateTime']>;
  idIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  numberIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  conferenceIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdByIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataIn?: Maybe<Array<Maybe<Scalars['VoucherDataJsonType']>>>;
  createdAtIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  orderBy?: Maybe<VoucherPropertiesEnum>;
  orderByDesc?: Maybe<VoucherPropertiesEnum>;
  conferenceName?: Maybe<Scalars['String']>;
};

export type RegProductInput = {
  participantId: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  productType: ProductType;
  data: Scalars['JSONObject'];
  peopleIds?: Maybe<Array<Scalars['ID']>>;
  formId?: Maybe<Scalars['ID']>;
  formProductId?: Maybe<Scalars['ID']>;
  shareWithProdRegIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amount?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

export type RegisterPaymentDetails = {
  paymentType: PaymentType;
  amountCents: Scalars['Int'];
  currency: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
};

export type RegisterResult = {
  __typename?: 'RegisterResult';
  id: Scalars['String'];
};

export type Registration = {
  __typename?: 'Registration';
  charges: Array<Charge>;
  createdAt: Scalars['DateTime'];
  data: Scalars['Partial2JsonType'];
  form?: Maybe<Form>;
  formId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  participant: Participant;
  participantId: Scalars['ID'];
};


export type RegistrationChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type RegistrationFormArgs = {
  orderBy?: Maybe<FormPropertiesEnum>;
};


export type RegistrationParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};

export type RegistrationForm = {
  __typename?: 'RegistrationForm';
  id: Scalars['ID'];
  formSchema: Scalars['JSONObject'];
  settings: Scalars['JSONObject'];
  products: Array<Product>;
};

export enum RegistrationPropertiesEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  FormId = 'formId',
  Id = 'id',
  ParticipantId = 'participantId'
}

export type Report = {
  __typename?: 'Report';
  data: Scalars['ReportDataJsonType'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ReportType;
  userDefined: Scalars['Boolean'];
};

export type ReportInput = {
  reportType: ReportType;
  data: Scalars['ReportDataJsonType'];
  name: Scalars['String'];
};

export enum ReportPropertiesEnum {
  Data = 'data',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UserDefined = 'userDefined'
}

export { ReportType };

export type Review = {
  __typename?: 'Review';
  abstract: Abstract;
  abstractId: Scalars['ID'];
  data?: Maybe<Scalars['ReviewDataJsonType']>;
  id: Scalars['ID'];
  reviewer: Reviewer;
  reviewerId: Scalars['ID'];
};


export type ReviewAbstractArgs = {
  orderBy?: Maybe<AbstractPropertiesEnum>;
};


export type ReviewReviewerArgs = {
  orderBy?: Maybe<ReviewerPropertiesEnum>;
};

export enum ReviewPropertiesEnum {
  AbstractId = 'abstractId',
  Data = 'data',
  Id = 'id',
  ReviewerId = 'reviewerId'
}

export type Reviewer = {
  __typename?: 'Reviewer';
  conference: Conference;
  conferenceId: Scalars['ID'];
  id: Scalars['ID'];
  participant: Participant;
  participantId: Scalars['ID'];
  reviews: Array<Review>;
};


export type ReviewerConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type ReviewerParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type ReviewerReviewsArgs = {
  orderBy?: Maybe<ReviewPropertiesEnum>;
};

export enum ReviewerPropertiesEnum {
  ConferenceId = 'conferenceId',
  Id = 'id',
  ParticipantId = 'participantId'
}

export type RoomBlockInput = {
  hotel: Scalars['ID'];
  roomType?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  counts: Array<RoomDateCount>;
};

export type RoomDateCount = {
  date: Scalars['String'];
  count: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
};

export type SelectedProduct = {
  __typename?: 'SelectedProduct';
  charges: Array<Charge>;
  conferenceId: Scalars['ID'];
  formProduct?: Maybe<FormProduct>;
  formProductId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  participant: Participant;
  participantId: Scalars['ID'];
  productRegistrations: Array<ProductRegistration>;
};


export type SelectedProductChargesArgs = {
  orderBy?: Maybe<ChargePropertiesEnum>;
};


export type SelectedProductFormProductArgs = {
  orderBy?: Maybe<FormProductPropertiesEnum>;
};


export type SelectedProductParticipantArgs = {
  orderBy?: Maybe<ParticipantPropertiesEnum>;
};


export type SelectedProductProductRegistrationsArgs = {
  orderBy?: Maybe<ProductRegistrationPropertiesEnum>;
};

export enum SelectedProductPropertiesEnum {
  ConferenceId = 'conferenceId',
  FormProductId = 'formProductId',
  Id = 'id',
  ParticipantId = 'participantId'
}

export type SendEmailInput = {
  title: Scalars['String'];
  html: Scalars['String'];
  fromEmail: Scalars['String'];
  fromName: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  conference: Conference;
  conferenceId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type TagConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};

export enum TagPropertiesEnum {
  Color = 'color',
  ConferenceId = 'conferenceId',
  Id = 'id',
  Name = 'name'
}

export type Template = {
  __typename?: 'Template';
  conference: Conference;
  conferenceId: Scalars['ID'];
  data: Scalars['SlateInputJsonType'];
  id: Scalars['ID'];
  templateType: TemplateType;
};


export type TemplateConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};

export enum TemplatePropertiesEnum {
  ConferenceId = 'conferenceId',
  Data = 'data',
  Id = 'id',
  TemplateType = 'templateType'
}

export enum TemplateType {
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

export type UpdateFormEventInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type UploadUrlResponse = {
  __typename?: 'UploadUrlResponse';
  url: Scalars['String'];
  fileName: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  blocked?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['UserDataJsonType']>;
  email: Scalars['String'];
  googleId: Scalars['ID'];
  id: Scalars['ID'];
  lastAccessed?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  permissions?: Maybe<Scalars['UserPermissionsJsonType']>;
  userType: UserType;
};

export enum UserPropertiesEnum {
  Blocked = 'blocked',
  Data = 'data',
  Email = 'email',
  GoogleId = 'googleId',
  Id = 'id',
  LastAccessed = 'lastAccessed',
  Name = 'name',
  Permissions = 'permissions',
  UserType = 'userType'
}

export enum UserType {
  Google = 'GOOGLE',
  Manual = 'MANUAL'
}

export type Voucher = {
  __typename?: 'Voucher';
  conference: Conference;
  conferenceId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdById: Scalars['ID'];
  data: Scalars['VoucherDataJsonType'];
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
};


export type VoucherConferenceArgs = {
  orderBy?: Maybe<ConferencePropertiesEnum>;
};


export type VoucherCreatedByArgs = {
  orderBy?: Maybe<UserPropertiesEnum>;
};

export enum VoucherPropertiesEnum {
  ConferenceId = 'conferenceId',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Data = 'data',
  Id = 'id',
  Number = 'number'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Abstract: ResolverTypeWrapper<AbstractModel>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AbstractCompletePageJsonType: ResolverTypeWrapper<Scalars['AbstractCompletePageJsonType']>;
  AbstractData1JsonType: ResolverTypeWrapper<Scalars['AbstractData1JsonType']>;
  AbstractDataJsonType: ResolverTypeWrapper<Scalars['AbstractDataJsonType']>;
  AbstractGroup: ResolverTypeWrapper<AbstractGroupModel>;
  AbstractGroupInput: AbstractGroupInput;
  AbstractGroupPropertiesEnum: AbstractGroupPropertiesEnum;
  AbstractPropertiesEnum: AbstractPropertiesEnum;
  AbstractState: AbstractState;
  AccountBookClientJsonType: ResolverTypeWrapper<Scalars['AccountBookClientJsonType']>;
  CategoryInput: CategoryInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Charge: ResolverTypeWrapper<ChargeModel>;
  ChargeInput: ChargeInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ChargePropertiesEnum: ChargePropertiesEnum;
  ChargeType: ChargeType;
  Client: ResolverTypeWrapper<Omit<Client, 'charges' | 'clientCharges' | 'invoices' | 'payments'> & { charges: Array<ResolversTypes['Charge']>, clientCharges: Array<ResolversTypes['ClientCharge']>, invoices: Array<ResolversTypes['Invoice']>, payments: Array<ResolversTypes['Payment']> }>;
  ClientCharge: ResolverTypeWrapper<ClientChargeModel>;
  ClientChargePropertiesEnum: ClientChargePropertiesEnum;
  ClientPropertiesEnum: ClientPropertiesEnum;
  Conference: ResolverTypeWrapper<ConferenceModel>;
  ConferencePropertiesEnum: ConferencePropertiesEnum;
  ConferenceSettingsJsonType: ResolverTypeWrapper<Scalars['ConferenceSettingsJsonType']>;
  ConferenceUser: ResolverTypeWrapper<ConferenceUser>;
  ConversionRates: ResolverTypeWrapper<Scalars['ConversionRates']>;
  CreateCreditGuardUrlInput: CreateCreditGuardUrlInput;
  CreateFormCopyAlsoOptions: CreateFormCopyAlsoOptions;
  CreateFormTemplateInput: CreateFormTemplateInput;
  CreateInvoiceInput: CreateInvoiceInput;
  Currency: Currency;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Email: ResolverTypeWrapper<EmailModel>;
  EmailExistence: EmailExistence;
  EmailPropertiesEnum: EmailPropertiesEnum;
  EmailTemplate: ResolverTypeWrapper<EmailTemplateModel>;
  EmailTemplatePropertiesEnum: EmailTemplatePropertiesEnum;
  EmailType: EmailType;
  EventInput: EventInput;
  ExtraChargeJsonType: ResolverTypeWrapper<Scalars['ExtraChargeJsonType']>;
  FailureType: FailureType;
  FieldDisplayType: FieldDisplayType;
  FieldSource: FieldSource;
  Form: ResolverTypeWrapper<FormModel>;
  FormField: ResolverTypeWrapper<FormFieldModel>;
  FormFieldFieldType: FormFieldFieldType;
  FormFieldInput: FormFieldInput;
  FormFieldPropertiesEnum: FormFieldPropertiesEnum;
  FormHotelInput: FormHotelInput;
  FormProduct: ResolverTypeWrapper<FormProductModel>;
  FormProductDataJsonType: ResolverTypeWrapper<Scalars['FormProductDataJsonType']>;
  FormProductFieldsJsonType: ResolverTypeWrapper<Scalars['FormProductFieldsJsonType']>;
  FormProductProduct: ResolverTypeWrapper<FormProductProductModel>;
  FormProductProductPropertiesEnum: FormProductProductPropertiesEnum;
  FormProductPropertiesEnum: FormProductPropertiesEnum;
  FormProductState: FormProductState;
  FormPropertiesEnum: FormPropertiesEnum;
  FormSchemaJsonType: ResolverTypeWrapper<Scalars['FormSchemaJsonType']>;
  FormSettingsJsonType: ResolverTypeWrapper<Scalars['FormSettingsJsonType']>;
  GetRegistrationResult: ResolverTypeWrapper<Omit<GetRegistrationResult, 'productRegs'> & { productRegs: Array<ResolversTypes['ProductRegistration']> }>;
  Hotel: ResolverTypeWrapper<HotelModel>;
  HotelPropertiesEnum: HotelPropertiesEnum;
  ImportParticipantInput: ImportParticipantInput;
  InputAbstractDataJsonType: ResolverTypeWrapper<Scalars['InputAbstractDataJsonType']>;
  Invoice: ResolverTypeWrapper<InvoiceModel>;
  InvoicePayment: InvoicePayment;
  InvoiceProduct: InvoiceProduct;
  InvoiceProductJsonType: ResolverTypeWrapper<Scalars['InvoiceProductJsonType']>;
  InvoicePropertiesEnum: InvoicePropertiesEnum;
  InvoiceType: InvoiceType;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  Language: Language;
  Lead: ResolverTypeWrapper<LeadModel>;
  LeadPayment: ResolverTypeWrapper<LeadPaymentModel>;
  LeadPaymentPropertiesEnum: LeadPaymentPropertiesEnum;
  LeadPropertiesEnum: LeadPropertiesEnum;
  LegacyAbstract: ResolverTypeWrapper<LegacyAbstract>;
  LegacyConference: ResolverTypeWrapper<LegacyConference>;
  LegacyParticipant: ResolverTypeWrapper<LegacyParticipant>;
  LoginParticipantResult: ResolverTypeWrapper<Omit<LoginParticipantResult, 'participant'> & { participant: ResolversTypes['Participant'] }>;
  MetaJsonType: ResolverTypeWrapper<Scalars['MetaJsonType']>;
  Mutation: ResolverTypeWrapper<{}>;
  NodeSchema: ResolverTypeWrapper<NodeSchemaModel>;
  NodeSchemaPropertiesEnum: NodeSchemaPropertiesEnum;
  Partial2JsonType: ResolverTypeWrapper<Scalars['Partial2JsonType']>;
  PartialCharge: ResolverTypeWrapper<PartialCharge>;
  PartialForm: ResolverTypeWrapper<PartialForm>;
  PartialPayment: ResolverTypeWrapper<PartialPayment>;
  PartialRegistrationDataJsonType: ResolverTypeWrapper<Scalars['PartialRegistrationDataJsonType']>;
  Participant: ResolverTypeWrapper<ParticipantModel>;
  ParticipantCount: ResolverTypeWrapper<Omit<ParticipantCount, 'conference'> & { conference: ResolversTypes['Participant'] }>;
  ParticipantCountPropertiesEnum: ParticipantCountPropertiesEnum;
  ParticipantPropertiesEnum: ParticipantPropertiesEnum;
  ParticipantRolesJsonType: ResolverTypeWrapper<Scalars['ParticipantRolesJsonType']>;
  ParticipantSettings1JsonType: ResolverTypeWrapper<Scalars['ParticipantSettings1JsonType']>;
  ParticipantSettingsJsonType: ResolverTypeWrapper<Scalars['ParticipantSettingsJsonType']>;
  ParticipantTag: ResolverTypeWrapper<ParticipantTagModel>;
  ParticipantTagPropertiesEnum: ParticipantTagPropertiesEnum;
  ParticipantsDue: ResolverTypeWrapper<ParticipantsDue>;
  ParticipantsDuePropertiesEnum: ParticipantsDuePropertiesEnum;
  Payment: ResolverTypeWrapper<PaymentModel>;
  PaymentDataJsonType: ResolverTypeWrapper<Scalars['PaymentDataJsonType']>;
  PaymentPropertiesEnum: PaymentPropertiesEnum;
  PaymentState: PaymentState;
  PaymentType: PaymentType;
  Person: ResolverTypeWrapper<PersonModel>;
  PersonData1JsonType: ResolverTypeWrapper<Scalars['PersonData1JsonType']>;
  PersonInput: PersonInput;
  PersonPropertiesEnum: PersonPropertiesEnum;
  PricesJsonType: ResolverTypeWrapper<Scalars['PricesJsonType']>;
  PricingPeriodsId: PricingPeriodsId;
  PrintTemplate: ResolverTypeWrapper<PrintTemplateModel>;
  PrintTemplateCustomDataJsonType: ResolverTypeWrapper<Scalars['PrintTemplateCustomDataJsonType']>;
  PrintTemplatePropertiesEnum: PrintTemplatePropertiesEnum;
  PrintTemplateType: PrintTemplateType;
  Product: ResolverTypeWrapper<ProductModel>;
  ProductDataJsonType: ResolverTypeWrapper<Scalars['ProductDataJsonType']>;
  ProductModifierInput: ProductModifierInput;
  ProductPropertiesEnum: ProductPropertiesEnum;
  ProductRegistration: ResolverTypeWrapper<ProductRegistrationModel>;
  ProductRegistrationDataJsonType: ResolverTypeWrapper<Scalars['ProductRegistrationDataJsonType']>;
  ProductRegistrationPropertiesEnum: ProductRegistrationPropertiesEnum;
  ProductType: ProductType;
  Query: ResolverTypeWrapper<{}>;
  Record8JsonType: ResolverTypeWrapper<Scalars['Record8JsonType']>;
  RecordStringUnknownJsonType: ResolverTypeWrapper<Scalars['RecordStringUnknownJsonType']>;
  RegProductInput: RegProductInput;
  RegisterPaymentDetails: RegisterPaymentDetails;
  RegisterResult: ResolverTypeWrapper<RegisterResult>;
  Registration: ResolverTypeWrapper<RegistrationModel>;
  RegistrationData1JsonType: ResolverTypeWrapper<Scalars['RegistrationData1JsonType']>;
  RegistrationDataJsonType: ResolverTypeWrapper<Scalars['RegistrationDataJsonType']>;
  RegistrationForm: ResolverTypeWrapper<Omit<RegistrationForm, 'products'> & { products: Array<ResolversTypes['Product']> }>;
  RegistrationPropertiesEnum: RegistrationPropertiesEnum;
  RegularPrintFormDataJsonType: ResolverTypeWrapper<Scalars['RegularPrintFormDataJsonType']>;
  Report: ResolverTypeWrapper<ReportModel>;
  ReportDataJsonType: ResolverTypeWrapper<Scalars['ReportDataJsonType']>;
  ReportInput: ReportInput;
  ReportPropertiesEnum: ReportPropertiesEnum;
  ReportType: ReportType;
  Review: ResolverTypeWrapper<ReviewModel>;
  ReviewDataJsonType: ResolverTypeWrapper<Scalars['ReviewDataJsonType']>;
  ReviewPropertiesEnum: ReviewPropertiesEnum;
  Reviewer: ResolverTypeWrapper<ReviewerModel>;
  ReviewerPropertiesEnum: ReviewerPropertiesEnum;
  RoomBlockInput: RoomBlockInput;
  RoomDateCount: RoomDateCount;
  ScienceSettingsJsonType: ResolverTypeWrapper<Scalars['ScienceSettingsJsonType']>;
  SelectedProduct: ResolverTypeWrapper<SelectedProductModel>;
  SelectedProductPropertiesEnum: SelectedProductPropertiesEnum;
  SendEmailInput: SendEmailInput;
  ShowIfJsonType: ResolverTypeWrapper<Scalars['ShowIfJsonType']>;
  SlateInput1JsonType: ResolverTypeWrapper<Scalars['SlateInput1JsonType']>;
  SlateInputJsonType: ResolverTypeWrapper<Scalars['SlateInputJsonType']>;
  SubFieldsJsonType: ResolverTypeWrapper<Scalars['SubFieldsJsonType']>;
  SubmitterDataJsonType: ResolverTypeWrapper<Scalars['SubmitterDataJsonType']>;
  Tag: ResolverTypeWrapper<TagModel>;
  TagPropertiesEnum: TagPropertiesEnum;
  Template: ResolverTypeWrapper<TemplateModel>;
  TemplateMetaJsonType: ResolverTypeWrapper<Scalars['TemplateMetaJsonType']>;
  TemplatePropertiesEnum: TemplatePropertiesEnum;
  TemplateType: TemplateType;
  UpdateFormEventInput: UpdateFormEventInput;
  UploadUrlResponse: ResolverTypeWrapper<UploadUrlResponse>;
  User: ResolverTypeWrapper<UserModel>;
  UserDataJsonType: ResolverTypeWrapper<Scalars['UserDataJsonType']>;
  UserPermissionsJsonType: ResolverTypeWrapper<Scalars['UserPermissionsJsonType']>;
  UserPropertiesEnum: UserPropertiesEnum;
  UserType: UserType;
  Voucher: ResolverTypeWrapper<VoucherModel>;
  VoucherDataJsonType: ResolverTypeWrapper<Scalars['VoucherDataJsonType']>;
  VoucherPropertiesEnum: VoucherPropertiesEnum;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Abstract: AbstractModel;
  ID: Scalars['ID'];
  String: Scalars['String'];
  Float: Scalars['Float'];
  AbstractCompletePageJsonType: Scalars['AbstractCompletePageJsonType'];
  AbstractData1JsonType: Scalars['AbstractData1JsonType'];
  AbstractDataJsonType: Scalars['AbstractDataJsonType'];
  AbstractGroup: AbstractGroupModel;
  AbstractGroupInput: AbstractGroupInput;
  AccountBookClientJsonType: Scalars['AccountBookClientJsonType'];
  CategoryInput: CategoryInput;
  Boolean: Scalars['Boolean'];
  Charge: ChargeModel;
  ChargeInput: ChargeInput;
  Int: Scalars['Int'];
  Client: Omit<Client, 'charges' | 'clientCharges' | 'invoices' | 'payments'> & { charges: Array<ResolversParentTypes['Charge']>, clientCharges: Array<ResolversParentTypes['ClientCharge']>, invoices: Array<ResolversParentTypes['Invoice']>, payments: Array<ResolversParentTypes['Payment']> };
  ClientCharge: ClientChargeModel;
  Conference: ConferenceModel;
  ConferenceSettingsJsonType: Scalars['ConferenceSettingsJsonType'];
  ConferenceUser: ConferenceUser;
  ConversionRates: Scalars['ConversionRates'];
  CreateCreditGuardUrlInput: CreateCreditGuardUrlInput;
  CreateFormCopyAlsoOptions: CreateFormCopyAlsoOptions;
  CreateInvoiceInput: CreateInvoiceInput;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  Email: EmailModel;
  EmailTemplate: EmailTemplateModel;
  EventInput: EventInput;
  ExtraChargeJsonType: Scalars['ExtraChargeJsonType'];
  Form: FormModel;
  FormField: FormFieldModel;
  FormFieldInput: FormFieldInput;
  FormHotelInput: FormHotelInput;
  FormProduct: FormProductModel;
  FormProductDataJsonType: Scalars['FormProductDataJsonType'];
  FormProductFieldsJsonType: Scalars['FormProductFieldsJsonType'];
  FormProductProduct: FormProductProductModel;
  FormSchemaJsonType: Scalars['FormSchemaJsonType'];
  FormSettingsJsonType: Scalars['FormSettingsJsonType'];
  GetRegistrationResult: Omit<GetRegistrationResult, 'productRegs'> & { productRegs: Array<ResolversParentTypes['ProductRegistration']> };
  Hotel: HotelModel;
  ImportParticipantInput: ImportParticipantInput;
  InputAbstractDataJsonType: Scalars['InputAbstractDataJsonType'];
  Invoice: InvoiceModel;
  InvoicePayment: InvoicePayment;
  InvoiceProduct: InvoiceProduct;
  InvoiceProductJsonType: Scalars['InvoiceProductJsonType'];
  JSON: Scalars['JSON'];
  JSONObject: Scalars['JSONObject'];
  Lead: LeadModel;
  LeadPayment: LeadPaymentModel;
  LegacyAbstract: LegacyAbstract;
  LegacyConference: LegacyConference;
  LegacyParticipant: LegacyParticipant;
  LoginParticipantResult: Omit<LoginParticipantResult, 'participant'> & { participant: ResolversParentTypes['Participant'] };
  MetaJsonType: Scalars['MetaJsonType'];
  Mutation: {};
  NodeSchema: NodeSchemaModel;
  Partial2JsonType: Scalars['Partial2JsonType'];
  PartialCharge: PartialCharge;
  PartialForm: PartialForm;
  PartialPayment: PartialPayment;
  PartialRegistrationDataJsonType: Scalars['PartialRegistrationDataJsonType'];
  Participant: ParticipantModel;
  ParticipantCount: Omit<ParticipantCount, 'conference'> & { conference: ResolversParentTypes['Participant'] };
  ParticipantRolesJsonType: Scalars['ParticipantRolesJsonType'];
  ParticipantSettings1JsonType: Scalars['ParticipantSettings1JsonType'];
  ParticipantSettingsJsonType: Scalars['ParticipantSettingsJsonType'];
  ParticipantTag: ParticipantTagModel;
  ParticipantsDue: ParticipantsDue;
  Payment: PaymentModel;
  PaymentDataJsonType: Scalars['PaymentDataJsonType'];
  Person: PersonModel;
  PersonData1JsonType: Scalars['PersonData1JsonType'];
  PersonInput: PersonInput;
  PricesJsonType: Scalars['PricesJsonType'];
  PrintTemplate: PrintTemplateModel;
  PrintTemplateCustomDataJsonType: Scalars['PrintTemplateCustomDataJsonType'];
  Product: ProductModel;
  ProductDataJsonType: Scalars['ProductDataJsonType'];
  ProductModifierInput: ProductModifierInput;
  ProductRegistration: ProductRegistrationModel;
  ProductRegistrationDataJsonType: Scalars['ProductRegistrationDataJsonType'];
  Query: {};
  Record8JsonType: Scalars['Record8JsonType'];
  RecordStringUnknownJsonType: Scalars['RecordStringUnknownJsonType'];
  RegProductInput: RegProductInput;
  RegisterPaymentDetails: RegisterPaymentDetails;
  RegisterResult: RegisterResult;
  Registration: RegistrationModel;
  RegistrationData1JsonType: Scalars['RegistrationData1JsonType'];
  RegistrationDataJsonType: Scalars['RegistrationDataJsonType'];
  RegistrationForm: Omit<RegistrationForm, 'products'> & { products: Array<ResolversParentTypes['Product']> };
  RegularPrintFormDataJsonType: Scalars['RegularPrintFormDataJsonType'];
  Report: ReportModel;
  ReportDataJsonType: Scalars['ReportDataJsonType'];
  ReportInput: ReportInput;
  Review: ReviewModel;
  ReviewDataJsonType: Scalars['ReviewDataJsonType'];
  Reviewer: ReviewerModel;
  RoomBlockInput: RoomBlockInput;
  RoomDateCount: RoomDateCount;
  ScienceSettingsJsonType: Scalars['ScienceSettingsJsonType'];
  SelectedProduct: SelectedProductModel;
  SendEmailInput: SendEmailInput;
  ShowIfJsonType: Scalars['ShowIfJsonType'];
  SlateInput1JsonType: Scalars['SlateInput1JsonType'];
  SlateInputJsonType: Scalars['SlateInputJsonType'];
  SubFieldsJsonType: Scalars['SubFieldsJsonType'];
  SubmitterDataJsonType: Scalars['SubmitterDataJsonType'];
  Tag: TagModel;
  Template: TemplateModel;
  TemplateMetaJsonType: Scalars['TemplateMetaJsonType'];
  UpdateFormEventInput: UpdateFormEventInput;
  UploadUrlResponse: UploadUrlResponse;
  User: UserModel;
  UserDataJsonType: Scalars['UserDataJsonType'];
  UserPermissionsJsonType: Scalars['UserPermissionsJsonType'];
  Voucher: VoucherModel;
  VoucherDataJsonType: Scalars['VoucherDataJsonType'];
};

export type AbstractResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Abstract'] = ResolversParentTypes['Abstract']> = {
  abstractGroup?: Resolver<Maybe<ResolversTypes['AbstractGroup']>, ParentType, ContextType, RequireFields<AbstractAbstractGroupArgs, never>>;
  abstractGroupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  acceptedFor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  body?: Resolver<ResolversTypes['SlateInputJsonType'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<AbstractConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['AbstractDataJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idInConference?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  reviewers?: Resolver<Array<ResolversTypes['Reviewer']>, ParentType, ContextType, RequireFields<AbstractReviewersArgs, never>>;
  reviews?: Resolver<Array<ResolversTypes['Review']>, ParentType, ContextType, RequireFields<AbstractReviewsArgs, never>>;
  state?: Resolver<Maybe<ResolversTypes['AbstractState']>, ParentType, ContextType>;
  submitter?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<AbstractSubmitterArgs, never>>;
  submitterData?: Resolver<Maybe<ResolversTypes['SubmitterDataJsonType']>, ParentType, ContextType>;
  submitterId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AbstractCompletePageJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AbstractCompletePageJsonType'], any> {
  name: 'AbstractCompletePageJsonType';
}

export interface AbstractData1JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AbstractData1JsonType'], any> {
  name: 'AbstractData1JsonType';
}

export interface AbstractDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AbstractDataJsonType'], any> {
  name: 'AbstractDataJsonType';
}

export type AbstractGroupResolvers<ContextType = Context, ParentType extends ResolversParentTypes['AbstractGroup'] = ResolversParentTypes['AbstractGroup']> = {
  abstracts?: Resolver<Array<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<AbstractGroupAbstractsArgs, never>>;
  body?: Resolver<Maybe<ResolversTypes['SlateInput1JsonType']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<AbstractGroupConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['AbstractData1JsonType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  submitter?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<AbstractGroupSubmitterArgs, never>>;
  submitterId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AccountBookClientJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AccountBookClientJsonType'], any> {
  name: 'AccountBookClientJsonType';
}

export type ChargeResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Charge'] = ResolversParentTypes['Charge']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<ChargeClientArgs, never>>;
  clientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  creditCents?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  due?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  formProduct?: Resolver<Maybe<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<ChargeFormProductArgs, never>>;
  formProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<ChargeParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  payed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentChargeAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<ChargePaymentsArgs, never>>;
  productRegistrations?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ChargeProductRegistrationsArgs, never>>;
  productType?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  regPeriod?: Resolver<Maybe<ResolversTypes['PricingPeriodsId']>, ParentType, ContextType>;
  registrationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  selectedProduct?: Resolver<ResolversTypes['SelectedProduct'], ParentType, ContextType, RequireFields<ChargeSelectedProductArgs, never>>;
  selectedProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ChargeType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeResolvers = EnumResolverSignature<{ CLIENT?: any, PARTICIPANT?: any, TARGET?: any }, ResolversTypes['ChargeType']>;

export type ClientResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  accountBookData?: Resolver<ResolversTypes['AccountBookClientJsonType'], ParentType, ContextType>;
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<ClientChargesArgs, never>>;
  clientCharges?: Resolver<Array<ResolversTypes['ClientCharge']>, ParentType, ContextType, RequireFields<ClientClientChargesArgs, never>>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<ClientInvoicesArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<ClientPaymentsArgs, never>>;
  settings?: Resolver<Maybe<ResolversTypes['ParticipantSettingsJsonType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientChargeResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ClientCharge'] = ResolversParentTypes['ClientCharge']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  clientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Conference'] = ResolversParentTypes['Conference']> = {
  abstractCompletePage?: Resolver<Maybe<ResolversTypes['AbstractCompletePageJsonType']>, ParentType, ContextType>;
  abstractGroups?: Resolver<Array<ResolversTypes['AbstractGroup']>, ParentType, ContextType, RequireFields<ConferenceAbstractGroupsArgs, never>>;
  abstractSchema?: Resolver<Maybe<ResolversTypes['NodeSchema']>, ParentType, ContextType, RequireFields<ConferenceAbstractSchemaArgs, never>>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  clients?: Resolver<Array<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<ConferenceClientsArgs, never>>;
  formFields?: Resolver<Array<ResolversTypes['FormField']>, ParentType, ContextType, RequireFields<ConferenceFormFieldsArgs, never>>;
  forms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<ConferenceFormsArgs, never>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  participants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<ConferenceParticipantsArgs, never>>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<ConferenceProductsArgs, never>>;
  registered?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<ConferenceRegisteredArgs, never>>;
  registrations?: Resolver<Array<ResolversTypes['Registration']>, ParentType, ContextType, RequireFields<ConferenceRegistrationsArgs, never>>;
  reviewers?: Resolver<Array<ResolversTypes['Reviewer']>, ParentType, ContextType, RequireFields<ConferenceReviewersArgs, never>>;
  scienceSettings?: Resolver<ResolversTypes['ScienceSettingsJsonType'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['ConferenceSettingsJsonType'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  superReviewers?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<ConferenceSuperReviewersArgs, never>>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<ConferenceTagsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ConferenceSettingsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ConferenceSettingsJsonType'], any> {
  name: 'ConferenceSettingsJsonType';
}

export type ConferenceUserResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ConferenceUser'] = ResolversParentTypes['ConferenceUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  participantSettings?: Resolver<Maybe<ResolversTypes['ParticipantSettingsJsonType']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reviewerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  registrationData?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  scienceData?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  forms?: Resolver<Array<ResolversTypes['PartialForm']>, ParentType, ContextType>;
  hasDebt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['PartialPayment']>, ParentType, ContextType>;
  charges?: Resolver<Array<ResolversTypes['PartialCharge']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ConversionRatesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ConversionRates'], any> {
  name: 'ConversionRates';
}

export type CurrencyResolvers = EnumResolverSignature<{ EUR?: any, ILS?: any, USD?: any }, ResolversTypes['Currency']>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EmailResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Email'] = ResolversParentTypes['Email']> = {
  conference?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<EmailConferenceArgs, never>>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  emailTemplateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  html?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<EmailParticipantArgs, never>>;
  participantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailTemplateResolvers<ContextType = Context, ParentType extends ResolversParentTypes['EmailTemplate'] = ResolversParentTypes['EmailTemplate']> = {
  conference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<EmailTemplateConferenceArgs, never>>;
  conferenceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  emailType?: Resolver<ResolversTypes['EmailType'], ParentType, ContextType>;
  emails?: Resolver<Array<ResolversTypes['Email']>, ParentType, ContextType, RequireFields<EmailTemplateEmailsArgs, never>>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<EmailTemplateFormArgs, never>>;
  formId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  html?: Resolver<ResolversTypes['SlateInputJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['TemplateMetaJsonType']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ExtraChargeJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ExtraChargeJsonType'], any> {
  name: 'ExtraChargeJsonType';
}

export type FormResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Form'] = ResolversParentTypes['Form']> = {
  cancelPolicy?: Resolver<Maybe<ResolversTypes['SlateInput1JsonType']>, ParentType, ContextType>;
  completePage?: Resolver<Maybe<ResolversTypes['SlateInput1JsonType']>, ParentType, ContextType>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<FormConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  emailTemplates?: Resolver<Array<ResolversTypes['EmailTemplate']>, ParentType, ContextType, RequireFields<FormEmailTemplatesArgs, never>>;
  formCategories?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<FormFormCategoriesArgs, never>>;
  formEvents?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<FormFormEventsArgs, never>>;
  formHotels?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<FormFormHotelsArgs, never>>;
  formProductModifiers?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<FormFormProductModifiersArgs, never>>;
  formProducts?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<FormFormProductsArgs, never>>;
  formSchema?: Resolver<ResolversTypes['FormSchemaJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<FormPaymentsArgs, never>>;
  registrations?: Resolver<Array<ResolversTypes['Registration']>, ParentType, ContextType, RequireFields<FormRegistrationsArgs, never>>;
  settings?: Resolver<ResolversTypes['FormSettingsJsonType'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormFieldResolvers<ContextType = Context, ParentType extends ResolversParentTypes['FormField'] = ResolversParentTypes['FormField']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<FormFieldConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  displayType?: Resolver<ResolversTypes['FieldDisplayType'], ParentType, ContextType>;
  fieldSource?: Resolver<ResolversTypes['FieldSource'], ParentType, ContextType>;
  fieldType?: Resolver<ResolversTypes['FormFieldFieldType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subFields?: Resolver<Array<ResolversTypes['SubFieldsJsonType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormFieldFieldTypeResolvers = EnumResolverSignature<{ ABSTRACT?: any, FORM?: any, REGISTRATION?: any }, ResolversTypes['FormFieldFieldType']>;

export type FormProductResolvers<ContextType = Context, ParentType extends ResolversParentTypes['FormProduct'] = ResolversParentTypes['FormProduct']> = {
  data?: Resolver<Maybe<ResolversTypes['FormProductDataJsonType']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extraCharges?: Resolver<Maybe<Array<ResolversTypes['ExtraChargeJsonType']>>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['FormProductFieldsJsonType']>, ParentType, ContextType>;
  form?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<FormProductFormArgs, never>>;
  formId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  formProductProducts?: Resolver<Array<ResolversTypes['FormProductProduct']>, ParentType, ContextType, RequireFields<FormProductFormProductProductsArgs, never>>;
  hasPartner?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  modifierOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ord?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  participantCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  participants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<FormProductParticipantsArgs, never>>;
  prices?: Resolver<ResolversTypes['PricesJsonType'], ParentType, ContextType>;
  showIf?: Resolver<Maybe<Array<ResolversTypes['ShowIfJsonType']>>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['FormProductState'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FormProductDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FormProductDataJsonType'], any> {
  name: 'FormProductDataJsonType';
}

export interface FormProductFieldsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FormProductFieldsJsonType'], any> {
  name: 'FormProductFieldsJsonType';
}

export type FormProductProductResolvers<ContextType = Context, ParentType extends ResolversParentTypes['FormProductProduct'] = ResolversParentTypes['FormProductProduct']> = {
  formProduct?: Resolver<ResolversTypes['FormProduct'], ParentType, ContextType, RequireFields<FormProductProductFormProductArgs, never>>;
  formProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<FormProductProductProductArgs, never>>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  registrationData?: Resolver<Maybe<ResolversTypes['RecordStringUnknownJsonType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormProductStateResolvers = EnumResolverSignature<{ DRAFT?: any, ENDED?: any, LIVE?: any }, ResolversTypes['FormProductState']>;

export interface FormSchemaJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FormSchemaJsonType'], any> {
  name: 'FormSchemaJsonType';
}

export interface FormSettingsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FormSettingsJsonType'], any> {
  name: 'FormSettingsJsonType';
}

export type GetRegistrationResultResolvers<ContextType = Context, ParentType extends ResolversParentTypes['GetRegistrationResult'] = ResolversParentTypes['GetRegistrationResult']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  formId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  paymentDue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  formSettings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  productRegs?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Hotel'] = ResolversParentTypes['Hotel']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface InputAbstractDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['InputAbstractDataJsonType'], any> {
  name: 'InputAbstractDataJsonType';
}

export type InvoiceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  amountCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<InvoiceClientArgs, never>>;
  clientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  conference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<InvoiceConferenceArgs, never>>;
  conferenceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  htmlUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceType?: Resolver<ResolversTypes['InvoiceType'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<InvoiceParticipantArgs, never>>;
  participantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  pdfUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<Maybe<Array<ResolversTypes['InvoiceProductJsonType']>>, ParentType, ContextType>;
  response?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface InvoiceProductJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['InvoiceProductJsonType'], any> {
  name: 'InvoiceProductJsonType';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type LeadResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Lead'] = ResolversParentTypes['Lead']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<LeadConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['RegistrationDataJsonType']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  leadPayments?: Resolver<Array<ResolversTypes['LeadPayment']>, ParentType, ContextType, RequireFields<LeadLeadPaymentsArgs, never>>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadPaymentResolvers<ContextType = Context, ParentType extends ResolversParentTypes['LeadPayment'] = ResolversParentTypes['LeadPayment']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Record8JsonType'], ParentType, ContextType>;
  failureType?: Resolver<ResolversTypes['FailureType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  leadId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyAbstractResolvers<ContextType = Context, ParentType extends ResolversParentTypes['LegacyAbstract'] = ResolversParentTypes['LegacyAbstract']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyConferenceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['LegacyConference'] = ResolversParentTypes['LegacyConference']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyParticipantResolvers<ContextType = Context, ParentType extends ResolversParentTypes['LegacyParticipant'] = ResolversParentTypes['LegacyParticipant']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginParticipantResultResolvers<ContextType = Context, ParentType extends ResolversParentTypes['LoginParticipantResult'] = ResolversParentTypes['LoginParticipantResult']> = {
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType>;
  returnTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MetaJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['MetaJsonType'], any> {
  name: 'MetaJsonType';
}

export type MutationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addClientCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddClientCreditCardArgs, 'clientId' | 'conferenceId' | 'amountCents' | 'currency'>>;
  addConferenceClient?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddConferenceClientArgs, 'conferenceName' | 'clientId'>>;
  addLeadPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddLeadPaymentArgs, 'conferenceName' | 'email'>>;
  addMultipleRegistrationProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddMultipleRegistrationProductArgs, 'participantId' | 'formProductId' | 'products'>>;
  addParticipantPerson?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationAddParticipantPersonArgs, 'participantId' | 'person'>>;
  addRegistrationProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddRegistrationProductArgs, 'product'>>;
  archiveConference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<MutationArchiveConferenceArgs, 'id'>>;
  assignParticipantRole?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationAssignParticipantRoleArgs, 'participantId' | 'role'>>;
  assignReviewers?: Resolver<Array<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<MutationAssignReviewersArgs, 'abstractIds' | 'reviewerIds' | 'reviewsPerAbstract'>>;
  blockUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationBlockUserArgs, 'email'>>;
  bulkEditParticipants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<MutationBulkEditParticipantsArgs, 'conferenceId' | 'data'>>;
  changePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'participantId' | 'newPassword'>>;
  changePasswordToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangePasswordTokenArgs, 'token' | 'newPassword'>>;
  chargeCreditGuard?: Resolver<ResolversTypes['Payment'], ParentType, ContextType, RequireFields<MutationChargeCreditGuardArgs, 'paymentId' | 'amountCents'>>;
  checkParticipant?: Resolver<ResolversTypes['EmailExistence'], ParentType, ContextType, RequireFields<MutationCheckParticipantArgs, 'email' | 'conferenceName'>>;
  createCategory?: Resolver<ResolversTypes['FormProduct'], ParentType, ContextType, RequireFields<MutationCreateCategoryArgs, 'formId' | 'input'>>;
  createCharge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateChargeArgs, 'input'>>;
  createConference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<MutationCreateConferenceArgs, 'name' | 'shortName' | 'settings'>>;
  createEmailTemplate?: Resolver<ResolversTypes['EmailTemplate'], ParentType, ContextType, RequireFields<MutationCreateEmailTemplateArgs, 'name' | 'conferenceId' | 'formId' | 'emailType'>>;
  createEvent?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationCreateEventArgs, 'conferenceId' | 'data'>>;
  createForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<MutationCreateFormArgs, 'name' | 'shortName' | 'conferenceId'>>;
  createHotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType, RequireFields<MutationCreateHotelArgs, 'name'>>;
  createInvoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<MutationCreateInvoiceArgs, 'input'>>;
  createOrUpdateFormHotel?: Resolver<ResolversTypes['FormProduct'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateFormHotelArgs, 'formId' | 'input'>>;
  createParticipant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationCreateParticipantArgs, 'conferenceName' | 'data'>>;
  createPayment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<MutationCreatePaymentArgs, 'conferenceId' | 'paymentType' | 'amountCents' | 'paymentState' | 'currency'>>;
  createPrintTemplate?: Resolver<ResolversTypes['PrintTemplate'], ParentType, ContextType, RequireFields<MutationCreatePrintTemplateArgs, 'conferenceName' | 'custom' | 'templateType'>>;
  createProductModifier?: Resolver<ResolversTypes['FormProduct'], ParentType, ContextType, RequireFields<MutationCreateProductModifierArgs, 'input'>>;
  createRegistration?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<MutationCreateRegistrationArgs, 'participantId'>>;
  createReviewer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateReviewerArgs, 'conferenceName' | 'email' | 'sendEmail'>>;
  createRoomBlock?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationCreateRoomBlockArgs, 'conferenceId' | 'roomBlock'>>;
  createVoucher?: Resolver<ResolversTypes['Voucher'], ParentType, ContextType, RequireFields<MutationCreateVoucherArgs, 'conferenceName' | 'data'>>;
  deleteAbstract?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAbstractArgs, 'abstractId'>>;
  deleteCharge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteChargeArgs, 'chargeId'>>;
  deleteEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteEventArgs, 'id'>>;
  deleteForm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteFormArgs, 'id'>>;
  deleteFormEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFormEventArgs, 'id'>>;
  deleteLead?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteLeadArgs, 'leadId'>>;
  deleteMultipleParticipants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<MutationDeleteMultipleParticipantsArgs, 'participantIds'>>;
  deleteParticipant?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteParticipantArgs, 'participantId'>>;
  deletePrintTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePrintTemplateArgs, 'id'>>;
  deleteProductModifier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProductModifierArgs, 'id'>>;
  deleteRegistration?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteRegistrationArgs, 'id'>>;
  deleteReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteReportArgs, 'id'>>;
  deleteReview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteReviewArgs, 'reviewId'>>;
  deleteReviewer?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationDeleteReviewerArgs, 'participantId'>>;
  deleteSelectedProduct?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationDeleteSelectedProductArgs, 'selectedProductId'>>;
  deleteTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTagArgs, 'tagId'>>;
  editReview?: Resolver<ResolversTypes['Review'], ParentType, ContextType, RequireFields<MutationEditReviewArgs, 'reviewId' | 'data'>>;
  importLegacyAbstracts?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationImportLegacyAbstractsArgs, 'conferenceName' | 'legacyConferenceId'>>;
  importLegacyParticipants?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationImportLegacyParticipantsArgs, 'conferenceName' | 'legacyConferenceId'>>;
  importParticipants?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationImportParticipantsArgs, 'data'>>;
  loginAdminParticipant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLoginAdminParticipantArgs, 'id'>>;
  loginParticipant?: Resolver<Maybe<ResolversTypes['LoginParticipantResult']>, ParentType, ContextType, RequireFields<MutationLoginParticipantArgs, 'token' | 'conferenceName'>>;
  loginParticipantPassword?: Resolver<Maybe<ResolversTypes['ConferenceUser']>, ParentType, ContextType, RequireFields<MutationLoginParticipantPasswordArgs, 'conferenceName' | 'email' | 'password'>>;
  loginRegistration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLoginRegistrationArgs, 'email' | 'conferenceName' | 'secretCode'>>;
  logoutParticipant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogoutParticipantArgs, 'conferenceName'>>;
  manualEvent?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationManualEventArgs, 'eventType' | 'payload'>>;
  moveAbstract?: Resolver<ResolversTypes['Abstract'], ParentType, ContextType, RequireFields<MutationMoveAbstractArgs, 'abstractId' | 'participantId'>>;
  moveSelectedProduct?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationMoveSelectedProductArgs, 'selectedProductId' | 'participantId'>>;
  redoEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRedoEventArgs, 'id'>>;
  refreshClientList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  register?: Resolver<ResolversTypes['RegisterResult'], ParentType, ContextType, RequireFields<MutationRegisterArgs, 'formId'>>;
  registerPay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRegisterPayArgs, 'participantId' | 'paymentType' | 'amountCents' | 'currency'>>;
  removeConferenceClient?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRemoveConferenceClientArgs, 'conferenceName' | 'clientId'>>;
  removeRegistrationProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveRegistrationProductArgs, 'productRegistrationId'>>;
  reorderFormEvents?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReorderFormEventsArgs, 'order'>>;
  reorderForms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<MutationReorderFormsArgs, 'ids'>>;
  reorderProductModifiers?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<MutationReorderProductModifiersArgs, 'order'>>;
  requestToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestTokenArgs, 'email' | 'conferenceName'>>;
  saveCustomEmailTemplate?: Resolver<ResolversTypes['EmailTemplate'], ParentType, ContextType, RequireFields<MutationSaveCustomEmailTemplateArgs, 'conferenceId' | 'title' | 'html'>>;
  sendParticipantEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSendParticipantEmailArgs, 'participantIds' | 'email'>>;
  signup?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSignupArgs, 'email' | 'password' | 'secretCode'>>;
  submitAbstract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSubmitAbstractArgs, 'conferenceName' | 'data'>>;
  tagParticipant?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<MutationTagParticipantArgs, 'tagId' | 'participantIds'>>;
  unarchiveConference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<MutationUnarchiveConferenceArgs, 'id'>>;
  unblockUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUnblockUserArgs, 'email'>>;
  updateAbstract?: Resolver<ResolversTypes['Abstract'], ParentType, ContextType, RequireFields<MutationUpdateAbstractArgs, 'abstractId' | 'data'>>;
  updateAbstractGroup?: Resolver<ResolversTypes['AbstractGroup'], ParentType, ContextType, RequireFields<MutationUpdateAbstractGroupArgs, 'input'>>;
  updateAbstractsState?: Resolver<Array<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<MutationUpdateAbstractsStateArgs, 'abstractIds' | 'state'>>;
  updateClient?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationUpdateClientArgs, 'conferenceId' | 'name'>>;
  updateConference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<MutationUpdateConferenceArgs, 'id'>>;
  updateConferenceClient?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationUpdateConferenceClientArgs, 'conferenceName' | 'clientId' | 'settings'>>;
  updateConferenceCompletePage?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<MutationUpdateConferenceCompletePageArgs, 'id'>>;
  updateConferenceFields?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<MutationUpdateConferenceFieldsArgs, 'id' | 'formFields'>>;
  updateEmailTemplate?: Resolver<ResolversTypes['EmailTemplate'], ParentType, ContextType, RequireFields<MutationUpdateEmailTemplateArgs, 'id'>>;
  updateEvent?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationUpdateEventArgs, 'id' | 'data'>>;
  updateForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<MutationUpdateFormArgs, 'id'>>;
  updateFormEmailTemplate?: Resolver<ResolversTypes['EmailTemplate'], ParentType, ContextType, RequireFields<MutationUpdateFormEmailTemplateArgs, 'formId' | 'emailType' | 'title' | 'html' | 'enabled'>>;
  updateHotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType, RequireFields<MutationUpdateHotelArgs, 'id' | 'name'>>;
  updateLead?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateLeadArgs, 'formId' | 'data'>>;
  updateParticipantContact?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationUpdateParticipantContactArgs, 'participantId' | 'data'>>;
  updateParticipantNotes?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationUpdateParticipantNotesArgs, 'participantId' | 'notes'>>;
  updateParticipantPeople?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationUpdateParticipantPeopleArgs, 'participantId' | 'people'>>;
  updateParticipantSettings?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationUpdateParticipantSettingsArgs, 'participantId' | 'settings'>>;
  updatePayment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType, RequireFields<MutationUpdatePaymentArgs, 'paymentId' | 'paymentState'>>;
  updatePermissions?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdatePermissionsArgs, 'id' | 'permissions'>>;
  updateProductRegistration?: Resolver<ResolversTypes['ProductRegistration'], ParentType, ContextType, RequireFields<MutationUpdateProductRegistrationArgs, 'id' | 'data'>>;
  updateReport?: Resolver<ResolversTypes['Report'], ParentType, ContextType, RequireFields<MutationUpdateReportArgs, 'input'>>;
  updateRoomBlock?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationUpdateRoomBlockArgs, 'id' | 'roomBlock'>>;
  updateSciEmail?: Resolver<ResolversTypes['EmailTemplate'], ParentType, ContextType, RequireFields<MutationUpdateSciEmailArgs, 'conferenceName' | 'emailType' | 'title' | 'html'>>;
  updateScienceSettings?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<MutationUpdateScienceSettingsArgs, 'conferenceName' | 'sciSettings'>>;
  updateScienceSubmitForm?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<MutationUpdateScienceSubmitFormArgs, 'conferenceName' | 'schema'>>;
  updateTag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<MutationUpdateTagArgs, 'conferenceName' | 'name'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'conferenceName' | 'templateType' | 'data'>>;
  updateVoucher?: Resolver<ResolversTypes['Voucher'], ParentType, ContextType, RequireFields<MutationUpdateVoucherArgs, 'id' | 'data'>>;
  updatedCharge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatedChargeArgs, 'chargeId' | 'input'>>;
};

export type NodeSchemaResolvers<ContextType = Context, ParentType extends ResolversParentTypes['NodeSchema'] = ResolversParentTypes['NodeSchema']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['FormSchemaJsonType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Partial2JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Partial2JsonType'], any> {
  name: 'Partial2JsonType';
}

export type PartialChargeResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartialCharge'] = ResolversParentTypes['PartialCharge']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creditCents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productType?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ChargeType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialFormResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartialForm'] = ResolversParentTypes['PartialForm']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['FormSettingsJsonType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialPaymentResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PartialPayment'] = ResolversParentTypes['PartialPayment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  paymentState?: Resolver<ResolversTypes['PaymentState'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PartialRegistrationDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PartialRegistrationDataJsonType'], any> {
  name: 'PartialRegistrationDataJsonType';
}

export type ParticipantResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Participant'] = ResolversParentTypes['Participant']> = {
  abstractGroups?: Resolver<Array<ResolversTypes['AbstractGroup']>, ParentType, ContextType, RequireFields<ParticipantAbstractGroupsArgs, never>>;
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<ParticipantChargesArgs, never>>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<ParticipantConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  counts?: Resolver<Maybe<ResolversTypes['ParticipantCount']>, ParentType, ContextType, RequireFields<ParticipantCountsArgs, never>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['RegistrationData1JsonType'], ParentType, ContextType>;
  dataOverrides?: Resolver<Maybe<ResolversTypes['PartialRegistrationDataJsonType']>, ParentType, ContextType>;
  due?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emails?: Resolver<Array<ResolversTypes['Email']>, ParentType, ContextType, RequireFields<ParticipantEmailsArgs, never>>;
  hasPassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idInConference?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<ParticipantInvoicesArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['SlateInput1JsonType']>, ParentType, ContextType>;
  oneTimeToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantDue?: Resolver<Maybe<ResolversTypes['ParticipantsDue']>, ParentType, ContextType, RequireFields<ParticipantParticipantDueArgs, never>>;
  passwordHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<ParticipantPaymentsArgs, never>>;
  people?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<ParticipantPeopleArgs, never>>;
  productRegs?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ParticipantProductRegsArgs, never>>;
  regCats?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  registrations?: Resolver<Array<ResolversTypes['Registration']>, ParentType, ContextType, RequireFields<ParticipantRegistrationsArgs, never>>;
  reviewer?: Resolver<Maybe<ResolversTypes['Reviewer']>, ParentType, ContextType, RequireFields<ParticipantReviewerArgs, never>>;
  roles?: Resolver<Maybe<ResolversTypes['ParticipantRolesJsonType']>, ParentType, ContextType>;
  selectedProducts?: Resolver<Array<ResolversTypes['SelectedProduct']>, ParentType, ContextType, RequireFields<ParticipantSelectedProductsArgs, never>>;
  settings?: Resolver<ResolversTypes['ParticipantSettings1JsonType'], ParentType, ContextType>;
  submittedAbstracts?: Resolver<Array<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<ParticipantSubmittedAbstractsArgs, never>>;
  superReviewer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<ParticipantTagsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ParticipantCount'] = ResolversParentTypes['ParticipantCount']> = {
  conference?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<ParticipantCountConferenceArgs, never>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reviewsAssigned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  reviewsCompleted?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  submittedAbstracts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCharges?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalDue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalParticipantCharges?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPayed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ParticipantRolesJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ParticipantRolesJsonType'], any> {
  name: 'ParticipantRolesJsonType';
}

export interface ParticipantSettings1JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ParticipantSettings1JsonType'], any> {
  name: 'ParticipantSettings1JsonType';
}

export interface ParticipantSettingsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ParticipantSettingsJsonType'], any> {
  name: 'ParticipantSettingsJsonType';
}

export type ParticipantTagResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ParticipantTag'] = ResolversParentTypes['ParticipantTag']> = {
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantsDueResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ParticipantsDue'] = ResolversParentTypes['ParticipantsDue']> = {
  due?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  payed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<PaymentChargesArgs, never>>;
  clientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<PaymentConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['PaymentDataJsonType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<PaymentParticipantArgs, never>>;
  participantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentChargeAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentState?: Resolver<ResolversTypes['PaymentState'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PaymentDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PaymentDataJsonType'], any> {
  name: 'PaymentDataJsonType';
}

export type PaymentStateResolvers = EnumResolverSignature<{ CANCELLED?: any, CG_AUTHORIZED?: any, CG_PAID?: any, PENDING?: any }, ResolversTypes['PaymentState']>;

export type PaymentTypeResolvers = EnumResolverSignature<{ CASH?: any, CHECK?: any, CREDIT_GUARD?: any, CREDIT_GUARD_CREDIT?: any, INVOICE?: any, SPONSOR?: any, WIRE?: any }, ResolversTypes['PaymentType']>;

export type PersonResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = {
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['PersonData1JsonType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<PersonParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  personType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PersonData1JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PersonData1JsonType'], any> {
  name: 'PersonData1JsonType';
}

export interface PricesJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PricesJsonType'], any> {
  name: 'PricesJsonType';
}

export type PricingPeriodsIdResolvers = EnumResolverSignature<{ early?: any, onsite?: any, regular?: any }, ResolversTypes['PricingPeriodsId']>;

export type PrintTemplateResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrintTemplate'] = ResolversParentTypes['PrintTemplate']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<PrintTemplateConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  custom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  customData?: Resolver<Maybe<ResolversTypes['PrintTemplateCustomDataJsonType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  regularData?: Resolver<Maybe<ResolversTypes['RegularPrintFormDataJsonType']>, ParentType, ContextType>;
  templateType?: Resolver<ResolversTypes['PrintTemplateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PrintTemplateCustomDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PrintTemplateCustomDataJsonType'], any> {
  name: 'PrintTemplateCustomDataJsonType';
}

export type PrintTemplateTypeResolvers = EnumResolverSignature<{ A4?: any, STICKER?: any, TAG?: any }, ResolversTypes['PrintTemplateType']>;

export type ProductResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  available?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  children?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<ProductChildrenArgs, never>>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<ProductConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['ProductDataJsonType']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formProductProducts?: Resolver<Array<ResolversTypes['FormProductProduct']>, ParentType, ContextType, RequireFields<ProductFormProductProductsArgs, never>>;
  hotel?: Resolver<Maybe<ResolversTypes['Hotel']>, ParentType, ContextType, RequireFields<ProductHotelArgs, never>>;
  hotelId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['MetaJsonType']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<ProductParentArgs, never>>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  productRegs?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ProductProductRegsArgs, never>>;
  productType?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ProductDataJsonType'], any> {
  name: 'ProductDataJsonType';
}

export type ProductRegistrationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['ProductRegistration'] = ResolversParentTypes['ProductRegistration']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<ProductRegistrationChargesArgs, never>>;
  children?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ProductRegistrationChildrenArgs, never>>;
  data?: Resolver<Maybe<ResolversTypes['ProductRegistrationDataJsonType']>, ParentType, ContextType>;
  formProduct?: Resolver<Maybe<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<ProductRegistrationFormProductArgs, never>>;
  formProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ProductRegistrationParentArgs, never>>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<ProductRegistrationParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  people?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<ProductRegistrationPeopleArgs, never>>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<ProductRegistrationProductArgs, never>>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  selectedProduct?: Resolver<ResolversTypes['SelectedProduct'], ParentType, ContextType, RequireFields<ProductRegistrationSelectedProductArgs, never>>;
  selectedProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shareGroup?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<ProductRegistrationShareGroupArgs, never>>;
  shareGroupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductRegistrationDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ProductRegistrationDataJsonType'], any> {
  name: 'ProductRegistrationDataJsonType';
}

export type ProductTypeResolvers = EnumResolverSignature<{ ACCOMPANYING?: any, ATTENDANCE?: any, ATTENDANCE_DAY?: any, CONFERENCE_EVENT?: any, EXTRA?: any, FLIGHT?: any, HOTEL?: any, HOTEL_NIGHT?: any, MODIFIER?: any, OTHER?: any, TOUR_EVENT?: any, TRANSPORT?: any }, ResolversTypes['ProductType']>;

export type QueryResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  abstract?: Resolver<Maybe<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<QueryAbstractArgs, never>>;
  abstractGroup?: Resolver<Maybe<ResolversTypes['AbstractGroup']>, ParentType, ContextType, RequireFields<QueryAbstractGroupArgs, never>>;
  abstractGroups?: Resolver<Array<ResolversTypes['AbstractGroup']>, ParentType, ContextType, RequireFields<QueryAbstractGroupsArgs, never>>;
  abstracts?: Resolver<Array<ResolversTypes['Abstract']>, ParentType, ContextType, RequireFields<QueryAbstractsArgs, never>>;
  calcRegistrationCharges?: Resolver<Array<ResolversTypes['PartialCharge']>, ParentType, ContextType, RequireFields<QueryCalcRegistrationChargesArgs, 'formId' | 'data'>>;
  charge?: Resolver<Maybe<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<QueryChargeArgs, never>>;
  chargeTitleOptions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryChargeTitleOptionsArgs, 'conferenceName'>>;
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<QueryChargesArgs, never>>;
  checkEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckEmailArgs, 'email' | 'conferenceName'>>;
  checkToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryCheckTokenArgs, 'token'>>;
  client?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<QueryClientArgs, never>>;
  clientCharge?: Resolver<Maybe<ResolversTypes['ClientCharge']>, ParentType, ContextType, RequireFields<QueryClientChargeArgs, never>>;
  clientCharges?: Resolver<Array<ResolversTypes['ClientCharge']>, ParentType, ContextType, RequireFields<QueryClientChargesArgs, never>>;
  clientCreditCardLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryClientCreditCardLinkArgs, 'clientId' | 'clientName' | 'conferenceId' | 'amountCents' | 'currency'>>;
  clients?: Resolver<Array<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<QueryClientsArgs, never>>;
  conference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<QueryConferenceArgs, never>>;
  conferenceUser?: Resolver<Maybe<ResolversTypes['ConferenceUser']>, ParentType, ContextType, RequireFields<QueryConferenceUserArgs, 'conferenceName'>>;
  conferences?: Resolver<Array<ResolversTypes['Conference']>, ParentType, ContextType, RequireFields<QueryConferencesArgs, never>>;
  createCreditGuardUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryCreateCreditGuardUrlArgs, 'params'>>;
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType, RequireFields<QueryEmailArgs, never>>;
  emailTemplate?: Resolver<Maybe<ResolversTypes['EmailTemplate']>, ParentType, ContextType, RequireFields<QueryEmailTemplateArgs, never>>;
  emailTemplates?: Resolver<Array<ResolversTypes['EmailTemplate']>, ParentType, ContextType, RequireFields<QueryEmailTemplatesArgs, never>>;
  emails?: Resolver<Array<ResolversTypes['Email']>, ParentType, ContextType, RequireFields<QueryEmailsArgs, never>>;
  exchangeRates?: Resolver<ResolversTypes['ConversionRates'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<QueryFormArgs, never>>;
  formField?: Resolver<Maybe<ResolversTypes['FormField']>, ParentType, ContextType, RequireFields<QueryFormFieldArgs, never>>;
  formFields?: Resolver<Array<ResolversTypes['FormField']>, ParentType, ContextType, RequireFields<QueryFormFieldsArgs, never>>;
  formProduct?: Resolver<Maybe<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<QueryFormProductArgs, never>>;
  formProductProduct?: Resolver<Maybe<ResolversTypes['FormProductProduct']>, ParentType, ContextType, RequireFields<QueryFormProductProductArgs, never>>;
  formProductProducts?: Resolver<Array<ResolversTypes['FormProductProduct']>, ParentType, ContextType, RequireFields<QueryFormProductProductsArgs, never>>;
  formProducts?: Resolver<Array<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<QueryFormProductsArgs, never>>;
  forms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<QueryFormsArgs, never>>;
  hotel?: Resolver<Maybe<ResolversTypes['Hotel']>, ParentType, ContextType, RequireFields<QueryHotelArgs, never>>;
  hotels?: Resolver<Array<ResolversTypes['Hotel']>, ParentType, ContextType, RequireFields<QueryHotelsArgs, never>>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoiceArgs, never>>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoicesArgs, never>>;
  lead?: Resolver<Maybe<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<QueryLeadArgs, never>>;
  leadPayment?: Resolver<Maybe<ResolversTypes['LeadPayment']>, ParentType, ContextType, RequireFields<QueryLeadPaymentArgs, never>>;
  leadPayments?: Resolver<Array<ResolversTypes['LeadPayment']>, ParentType, ContextType, RequireFields<QueryLeadPaymentsArgs, never>>;
  leads?: Resolver<Array<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<QueryLeadsArgs, never>>;
  legacyAbstracts?: Resolver<Array<ResolversTypes['LegacyAbstract']>, ParentType, ContextType, RequireFields<QueryLegacyAbstractsArgs, 'conferenceId'>>;
  legacyConferenceParticipants?: Resolver<Array<ResolversTypes['LegacyParticipant']>, ParentType, ContextType, RequireFields<QueryLegacyConferenceParticipantsArgs, 'conferenceId'>>;
  legacyConferences?: Resolver<Array<ResolversTypes['LegacyConference']>, ParentType, ContextType>;
  myParticipant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<QueryMyParticipantArgs, 'conferenceName'>>;
  myUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  nodeSchema?: Resolver<Maybe<ResolversTypes['NodeSchema']>, ParentType, ContextType, RequireFields<QueryNodeSchemaArgs, never>>;
  nodeSchemas?: Resolver<Array<ResolversTypes['NodeSchema']>, ParentType, ContextType, RequireFields<QueryNodeSchemasArgs, never>>;
  participant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<QueryParticipantArgs, never>>;
  participantCount?: Resolver<Maybe<ResolversTypes['ParticipantCount']>, ParentType, ContextType, RequireFields<QueryParticipantCountArgs, never>>;
  participantCounts?: Resolver<Array<ResolversTypes['ParticipantCount']>, ParentType, ContextType, RequireFields<QueryParticipantCountsArgs, never>>;
  participantTag?: Resolver<Maybe<ResolversTypes['ParticipantTag']>, ParentType, ContextType, RequireFields<QueryParticipantTagArgs, never>>;
  participantTags?: Resolver<Array<ResolversTypes['ParticipantTag']>, ParentType, ContextType, RequireFields<QueryParticipantTagsArgs, never>>;
  participants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType, RequireFields<QueryParticipantsArgs, never>>;
  participantsDue?: Resolver<Maybe<ResolversTypes['ParticipantsDue']>, ParentType, ContextType, RequireFields<QueryParticipantsDueArgs, never>>;
  participantsDues?: Resolver<Array<ResolversTypes['ParticipantsDue']>, ParentType, ContextType, RequireFields<QueryParticipantsDuesArgs, never>>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<QueryPaymentArgs, never>>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<QueryPaymentsArgs, never>>;
  person?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonArgs, never>>;
  persons?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonsArgs, never>>;
  printTemplate?: Resolver<Maybe<ResolversTypes['PrintTemplate']>, ParentType, ContextType, RequireFields<QueryPrintTemplateArgs, never>>;
  printTemplates?: Resolver<Array<ResolversTypes['PrintTemplate']>, ParentType, ContextType, RequireFields<QueryPrintTemplatesArgs, never>>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductArgs, never>>;
  productRegistration?: Resolver<Maybe<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<QueryProductRegistrationArgs, never>>;
  productRegistrations?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<QueryProductRegistrationsArgs, never>>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductsArgs, never>>;
  registration?: Resolver<Maybe<ResolversTypes['Registration']>, ParentType, ContextType, RequireFields<QueryRegistrationArgs, never>>;
  registrations?: Resolver<Array<ResolversTypes['Registration']>, ParentType, ContextType, RequireFields<QueryRegistrationsArgs, never>>;
  renderEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryRenderEmailArgs, 'content' | 'language' | 'participantId'>>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType, RequireFields<QueryReportArgs, never>>;
  reports?: Resolver<Array<ResolversTypes['Report']>, ParentType, ContextType, RequireFields<QueryReportsArgs, never>>;
  review?: Resolver<Maybe<ResolversTypes['Review']>, ParentType, ContextType, RequireFields<QueryReviewArgs, never>>;
  reviewer?: Resolver<Maybe<ResolversTypes['Reviewer']>, ParentType, ContextType, RequireFields<QueryReviewerArgs, never>>;
  reviewers?: Resolver<Array<ResolversTypes['Reviewer']>, ParentType, ContextType, RequireFields<QueryReviewersArgs, never>>;
  reviews?: Resolver<Array<ResolversTypes['Review']>, ParentType, ContextType, RequireFields<QueryReviewsArgs, never>>;
  selectedProduct?: Resolver<Maybe<ResolversTypes['SelectedProduct']>, ParentType, ContextType, RequireFields<QuerySelectedProductArgs, never>>;
  selectedProducts?: Resolver<Array<ResolversTypes['SelectedProduct']>, ParentType, ContextType, RequireFields<QuerySelectedProductsArgs, never>>;
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<QueryTagArgs, never>>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<QueryTagsArgs, never>>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryTemplateArgs, never>>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryTemplatesArgs, never>>;
  uploadUrl?: Resolver<ResolversTypes['UploadUrlResponse'], ParentType, ContextType, RequireFields<QueryUploadUrlArgs, 'contentType' | 'fileName'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, never>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUsersArgs, never>>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<QueryVoucherArgs, never>>;
  vouchers?: Resolver<Array<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<QueryVouchersArgs, never>>;
};

export interface Record8JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Record8JsonType'], any> {
  name: 'Record8JsonType';
}

export interface RecordStringUnknownJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RecordStringUnknownJsonType'], any> {
  name: 'RecordStringUnknownJsonType';
}

export type RegisterResultResolvers<ContextType = Context, ParentType extends ResolversParentTypes['RegisterResult'] = ResolversParentTypes['RegisterResult']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistrationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Registration'] = ResolversParentTypes['Registration']> = {
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<RegistrationChargesArgs, never>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Partial2JsonType'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType, RequireFields<RegistrationFormArgs, never>>;
  formId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<RegistrationParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RegistrationData1JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RegistrationData1JsonType'], any> {
  name: 'RegistrationData1JsonType';
}

export interface RegistrationDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RegistrationDataJsonType'], any> {
  name: 'RegistrationDataJsonType';
}

export type RegistrationFormResolvers<ContextType = Context, ParentType extends ResolversParentTypes['RegistrationForm'] = ResolversParentTypes['RegistrationForm']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  formSchema?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RegularPrintFormDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RegularPrintFormDataJsonType'], any> {
  name: 'RegularPrintFormDataJsonType';
}

export type ReportResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  data?: Resolver<ResolversTypes['ReportDataJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReportType'], ParentType, ContextType>;
  userDefined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ReportDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ReportDataJsonType'], any> {
  name: 'ReportDataJsonType';
}

export type ReportTypeResolvers = EnumResolverSignature<{ PARTICIPANTS?: any, SCIENCE?: any }, ResolversTypes['ReportType']>;

export type ReviewResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review']> = {
  abstract?: Resolver<ResolversTypes['Abstract'], ParentType, ContextType, RequireFields<ReviewAbstractArgs, never>>;
  abstractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['ReviewDataJsonType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reviewer?: Resolver<ResolversTypes['Reviewer'], ParentType, ContextType, RequireFields<ReviewReviewerArgs, never>>;
  reviewerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ReviewDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ReviewDataJsonType'], any> {
  name: 'ReviewDataJsonType';
}

export type ReviewerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Reviewer'] = ResolversParentTypes['Reviewer']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<ReviewerConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<ReviewerParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reviews?: Resolver<Array<ResolversTypes['Review']>, ParentType, ContextType, RequireFields<ReviewerReviewsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ScienceSettingsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ScienceSettingsJsonType'], any> {
  name: 'ScienceSettingsJsonType';
}

export type SelectedProductResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SelectedProduct'] = ResolversParentTypes['SelectedProduct']> = {
  charges?: Resolver<Array<ResolversTypes['Charge']>, ParentType, ContextType, RequireFields<SelectedProductChargesArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  formProduct?: Resolver<Maybe<ResolversTypes['FormProduct']>, ParentType, ContextType, RequireFields<SelectedProductFormProductArgs, never>>;
  formProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<SelectedProductParticipantArgs, never>>;
  participantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productRegistrations?: Resolver<Array<ResolversTypes['ProductRegistration']>, ParentType, ContextType, RequireFields<SelectedProductProductRegistrationsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ShowIfJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ShowIfJsonType'], any> {
  name: 'ShowIfJsonType';
}

export interface SlateInput1JsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SlateInput1JsonType'], any> {
  name: 'SlateInput1JsonType';
}

export interface SlateInputJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SlateInputJsonType'], any> {
  name: 'SlateInputJsonType';
}

export interface SubFieldsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SubFieldsJsonType'], any> {
  name: 'SubFieldsJsonType';
}

export interface SubmitterDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SubmitterDataJsonType'], any> {
  name: 'SubmitterDataJsonType';
}

export type TagResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<TagConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<TemplateConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['SlateInputJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  templateType?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TemplateMetaJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TemplateMetaJsonType'], any> {
  name: 'TemplateMetaJsonType';
}

export type UploadUrlResponseResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UploadUrlResponse'] = ResolversParentTypes['UploadUrlResponse']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = Context, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['UserDataJsonType']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  googleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastAccessed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['UserPermissionsJsonType']>, ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UserDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UserDataJsonType'], any> {
  name: 'UserDataJsonType';
}

export interface UserPermissionsJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UserPermissionsJsonType'], any> {
  name: 'UserPermissionsJsonType';
}

export type VoucherResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Voucher'] = ResolversParentTypes['Voucher']> = {
  conference?: Resolver<ResolversTypes['Conference'], ParentType, ContextType, RequireFields<VoucherConferenceArgs, never>>;
  conferenceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<VoucherCreatedByArgs, never>>;
  createdById?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['VoucherDataJsonType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoucherDataJsonTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['VoucherDataJsonType'], any> {
  name: 'VoucherDataJsonType';
}

export type Resolvers<ContextType = Context> = {
  Abstract?: AbstractResolvers<ContextType>;
  AbstractCompletePageJsonType?: GraphQLScalarType;
  AbstractData1JsonType?: GraphQLScalarType;
  AbstractDataJsonType?: GraphQLScalarType;
  AbstractGroup?: AbstractGroupResolvers<ContextType>;
  AccountBookClientJsonType?: GraphQLScalarType;
  Charge?: ChargeResolvers<ContextType>;
  ChargeType?: ChargeTypeResolvers;
  Client?: ClientResolvers<ContextType>;
  ClientCharge?: ClientChargeResolvers<ContextType>;
  Conference?: ConferenceResolvers<ContextType>;
  ConferenceSettingsJsonType?: GraphQLScalarType;
  ConferenceUser?: ConferenceUserResolvers<ContextType>;
  ConversionRates?: GraphQLScalarType;
  Currency?: CurrencyResolvers;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Email?: EmailResolvers<ContextType>;
  EmailTemplate?: EmailTemplateResolvers<ContextType>;
  ExtraChargeJsonType?: GraphQLScalarType;
  Form?: FormResolvers<ContextType>;
  FormField?: FormFieldResolvers<ContextType>;
  FormFieldFieldType?: FormFieldFieldTypeResolvers;
  FormProduct?: FormProductResolvers<ContextType>;
  FormProductDataJsonType?: GraphQLScalarType;
  FormProductFieldsJsonType?: GraphQLScalarType;
  FormProductProduct?: FormProductProductResolvers<ContextType>;
  FormProductState?: FormProductStateResolvers;
  FormSchemaJsonType?: GraphQLScalarType;
  FormSettingsJsonType?: GraphQLScalarType;
  GetRegistrationResult?: GetRegistrationResultResolvers<ContextType>;
  Hotel?: HotelResolvers<ContextType>;
  InputAbstractDataJsonType?: GraphQLScalarType;
  Invoice?: InvoiceResolvers<ContextType>;
  InvoiceProductJsonType?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  Lead?: LeadResolvers<ContextType>;
  LeadPayment?: LeadPaymentResolvers<ContextType>;
  LegacyAbstract?: LegacyAbstractResolvers<ContextType>;
  LegacyConference?: LegacyConferenceResolvers<ContextType>;
  LegacyParticipant?: LegacyParticipantResolvers<ContextType>;
  LoginParticipantResult?: LoginParticipantResultResolvers<ContextType>;
  MetaJsonType?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  NodeSchema?: NodeSchemaResolvers<ContextType>;
  Partial2JsonType?: GraphQLScalarType;
  PartialCharge?: PartialChargeResolvers<ContextType>;
  PartialForm?: PartialFormResolvers<ContextType>;
  PartialPayment?: PartialPaymentResolvers<ContextType>;
  PartialRegistrationDataJsonType?: GraphQLScalarType;
  Participant?: ParticipantResolvers<ContextType>;
  ParticipantCount?: ParticipantCountResolvers<ContextType>;
  ParticipantRolesJsonType?: GraphQLScalarType;
  ParticipantSettings1JsonType?: GraphQLScalarType;
  ParticipantSettingsJsonType?: GraphQLScalarType;
  ParticipantTag?: ParticipantTagResolvers<ContextType>;
  ParticipantsDue?: ParticipantsDueResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentDataJsonType?: GraphQLScalarType;
  PaymentState?: PaymentStateResolvers;
  PaymentType?: PaymentTypeResolvers;
  Person?: PersonResolvers<ContextType>;
  PersonData1JsonType?: GraphQLScalarType;
  PricesJsonType?: GraphQLScalarType;
  PricingPeriodsId?: PricingPeriodsIdResolvers;
  PrintTemplate?: PrintTemplateResolvers<ContextType>;
  PrintTemplateCustomDataJsonType?: GraphQLScalarType;
  PrintTemplateType?: PrintTemplateTypeResolvers;
  Product?: ProductResolvers<ContextType>;
  ProductDataJsonType?: GraphQLScalarType;
  ProductRegistration?: ProductRegistrationResolvers<ContextType>;
  ProductRegistrationDataJsonType?: GraphQLScalarType;
  ProductType?: ProductTypeResolvers;
  Query?: QueryResolvers<ContextType>;
  Record8JsonType?: GraphQLScalarType;
  RecordStringUnknownJsonType?: GraphQLScalarType;
  RegisterResult?: RegisterResultResolvers<ContextType>;
  Registration?: RegistrationResolvers<ContextType>;
  RegistrationData1JsonType?: GraphQLScalarType;
  RegistrationDataJsonType?: GraphQLScalarType;
  RegistrationForm?: RegistrationFormResolvers<ContextType>;
  RegularPrintFormDataJsonType?: GraphQLScalarType;
  Report?: ReportResolvers<ContextType>;
  ReportDataJsonType?: GraphQLScalarType;
  ReportType?: ReportTypeResolvers;
  Review?: ReviewResolvers<ContextType>;
  ReviewDataJsonType?: GraphQLScalarType;
  Reviewer?: ReviewerResolvers<ContextType>;
  ScienceSettingsJsonType?: GraphQLScalarType;
  SelectedProduct?: SelectedProductResolvers<ContextType>;
  ShowIfJsonType?: GraphQLScalarType;
  SlateInput1JsonType?: GraphQLScalarType;
  SlateInputJsonType?: GraphQLScalarType;
  SubFieldsJsonType?: GraphQLScalarType;
  SubmitterDataJsonType?: GraphQLScalarType;
  Tag?: TagResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateMetaJsonType?: GraphQLScalarType;
  UploadUrlResponse?: UploadUrlResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDataJsonType?: GraphQLScalarType;
  UserPermissionsJsonType?: GraphQLScalarType;
  Voucher?: VoucherResolvers<ContextType>;
  VoucherDataJsonType?: GraphQLScalarType;
};

