import { useCurrentConferenceName } from '../../common/useCurrent'
import { BannerExtra } from '../../../data/interfaces/ConferenceSettings'
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useIsServer } from '../../common/useIsServer'
import Link from 'next/link'
import { routes } from '../../../routes'
import { Fade } from '@material-ui/core'
import Image from 'next/image'
import { fileInBucket } from '../../../data/fileInBucket'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '100%',
    maxHeight: '800px',
    objectFit: 'contain',
    display: 'block',
    position: 'relative',
  },
}))

export type BannerProps = {
  banner?: string | null
  bannerAlt?: string
  bannerExtra?: BannerExtra
}

export function Banner({ banner, bannerAlt, bannerExtra }: BannerProps) {
  const classes = useStyles({})
  const conferenceName = useCurrentConferenceName()

  const [timedOut, setTimedOut] = useState(false)
  const ssrMode = useIsServer()
  useEffect(() => {
    const timeoutId = setTimeout(() => setTimedOut(true), 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const style: CSSProperties | undefined = useMemo(
    () =>
      bannerExtra?.height && bannerExtra?.width
        ? {
            minHeight: (bannerExtra.height / bannerExtra.width) * 99 + 'vw',
            minWidth: '100%',
            // background: `linear-gradient(0, ${bannerExtra?.colors?.[0]}, ${bannerExtra?.colors?.[1]})`,
            cursor: 'pointer',
            position: 'relative',
          }
        : undefined,
    [bannerExtra]
  )
  const [loading, setLoading] = useState(true)
  const onLoad = useCallback(() => setLoading(false), [setLoading])

  if (!banner) {
    return null
  }

  return (
    <Link {...routes.register.userHome(conferenceName)} passHref>
      <a>
        <Fade in={!ssrMode && !loading} timeout={timedOut ? 300 : 0}>
          <div style={style}>
            <Image
              layout={'fill'}
              src={fileInBucket(banner)}
              alt={bannerAlt}
              className={classes.banner}
              objectFit={'unset'}
              onLoadingComplete={onLoad}
              priority={true}
            />
          </div>
        </Fade>
      </a>
    </Link>
  )
}
